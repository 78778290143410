function DAMAGE_REPORTS() {
    // declarations
    var self = this;
    var counter = 2;
    var document_counter = 1;

    function HideTheElementAfterAnimation(div) {
        $(div).slideUp(100);
    }

    function GetThisDisplayed(div) {
        $(div).slideDown().removeClass('fadeOut').addClass('flipInY').unbind("webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend");
        smoothScroll.animateScroll(null, div, { "offset": 50 });
    }

    // listeners
    this.listeners = function () {
        $('input:radio[name="same_contractor_insured_person"]').change(function () {
            if ($(this).is(':checked') && $(this).val() == 'yes') {
                $('#damage-reports-insured-person-form').removeClass('flipInY').addClass('animated fadeOut').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                    HideTheElementAfterAnimation('#damage-reports-insured-person-form');
                });
            }
            else if ($(this).is(':checked') && $(this).val() == 'no') {
                GetThisDisplayed('#damage-reports-insured-person-form');

            }
        });
        $('#add_new_refund_cost-dzo').click(function (e) {
            // prevent default behaviour
            e.preventDefault();
            var html = '<div class="form-group animated jello">' +
                '<div class="col-md-9 col-xs-8 control">' +
                '<input class="form-control input-text " name="refund_cost_name_' + counter + '" type="text" value="">' +
                '</div>' +
                '<div class="col-md-3 col-xs-4 control">' +
                '<input class="form-control input-text" name="refund_cost_price_' + counter + '" type="text" value="">' +
                '</div>' +
                '</div>';
            $("#refund-items").append(html);
            counter++;
        })

        $('#add_new_refund_cost-oskssk').click(function (e) {
            // prevent default behaviour
            e.preventDefault();
            var html = '<div class="form-group animated jello">' +
                '<div class="col-md-6 col-xs-6 control">' +
                '<input class="form-control input-text " name="refund_cost_name_' + counter + '" type="text" value="">' +
                '</div>' +
                '<div class="col-md-2 col-xs-2 control">' +
                '<input class="form-control input-text" name="refund_cost_buy_price_' + counter + '" type="text" value="">' +
                '</div>' +
                '<div class="col-md-2 col-xs-2 control">' +
                '<input class="form-control input-text" name="refund_cost_repair_price_' + counter + '" type="text" value="">' +
                '</div>' +
                '<div class="col-md-2 col-xs-2 control">' +
                '<input class="form-control input-text" name="refund_cost_age_' + counter + '" type="text" value="">' +
                '</div>' +
                '</div>';
            $("#refund-items").append(html);
            counter++;
        })

        $('input[name="official_record_helper"]').change(function () {

            if ($(this).val() === 'not_in_period') {
                $('input[name="official_record_helper_addon"]').slideDown();
                $('#official_record_helper_addon_sprite').slideDown();
            }
            else {
                $('input[name="official_record_helper_addon"]').slideUp();
                $('#official_record_helper_addon_sprite').slideUp();

            }
        })

        $('#add_new_document_file').on('click', function (e) {
            e.preventDefault();
            document_counter++;
            $('#show_document_' + document_counter).slideDown('slow');
        })

        /* $('input:radio[name="individual_company_contractor"]').on('change', function () {
             if ($(this).is(':checked') && $(this).val() == 'company') {
                 $("#last_name_wrapper_contractor").slideUp('slow');
                 $("#first_name_wrapper_contractor").slideUp('slow', function () {
                     $('#first_name_label_contractor').html('Naziv firme');
                     $('#first_name_wrapper_contractor').slideDown('slow');
                 })
             }
             else {
                 $("#first_name_wrapper_contractor").slideUp('slow', function () {
                     $('#first_name_label_contractor').html('Ime');
                     $('#first_name_wrapper_contractor').slideDown('slow');
                     $("#last_name_wrapper_contractor").slideDown('slow');
                 })
             }
         })*/

        //sta se pojavljuje kad kliknemo drugo lice

        $('input:radio[name="is_applicant"]').on('change', function () {
            // get name and value
            var $name = $('.payment-select-citizenship').data('common-name');
            var $value = $('input:radio[name=' + $name + ']:checked').val();
            // find parent jmbg
            var $jmbg_container = $('.payment-select-citizenship').parent().parent().parent().parent().parent().find('.jmbg-container');
            // show/hide

            if ('domestic' == $value) {
                $("#payment_jmbg_wrapper_payment").slideDown('slow');
                // show
                $($jmbg_container).slideDown();
                // hide birthdate and gender
                $('.payment-select-citizenship').parent().parent().parent().parent().parent().parent().find('.foreign-show').slideUp();
            } else {
                // hide
                $($jmbg_container).slideUp();
                $("#payment_jmbg_wrapper_payment").slideUp();
                // show birthdate and gender
                $('.payment-select-citizenship').parent().parent().parent().parent().parent().parent().find('.foreign-show').slideDown();
            }

            if ($(this).is(':checked') && $(this).val() == 'applicant') {
                $("#payment_jmbg_wrapper_payment").slideUp('slow');
                $("#first_name_wrapper_payment").slideUp('slow');
                $(".citizenship-form").slideUp('slow');
            }
            else {
                $("#payment_jmbg_wrapper_payment").slideDown('slow');
                $(".citizenship-form").slideDown('slow');
                $("#first_name_wrapper_payment").slideUp('slow', function () {
                    $('#first_name_wrapper_payment').slideDown('slow');
                })
            }
        })

        $("#wants-to-send").click(function () {
            // call ajax method
            // set ajax url and make ajax call

            if ($("#phone_number").val() == '+387') {
                $('#damage-reports-error-container').html('Morate unijeti broj telefona');
            } else {
                $('#damage-reports-error-container').html('');
                

                //ask is user sure that he wants to send an email
                
                if (!$('#damage_report_video_button').hasClass('disabled')) {
                    //var confirmation = confirm("Da li zaista želite poslati email?");
                    var ajaxURL = URL + "/ajax/damage_reports/send-email";
                        var formData = new FormData($('#damage_report_form')[0]);
                        formData.append('g_recaptcha_token', TCApp.InvisibleRecaptcha.token);
                        // set ajax url and make ajax call
                        var $_token = $("input[name=_token]").val();
                        if (!$('#damage_report_video_button').hasClass('disabled')) {
                            $.ajax({
                                type: "POST",
                                data: formData,
                                dataType: 'json',
                                url: ajaxURL,
                                encode: true,
                                contentType: false,
                                processData: false,
                                headers: {
                                    'X-XSRF-TOKEN': $_token
                                },
                                success: function (data) {
                                    if (data) {
                                        $("#emailSentModal").modal("show");
                                        setTimeout(function () {
                                            $("#emailSentModal").modal("hide");
                                      }, 2000);
                                        $('#damage_report_video_button').addClass('disabled').prop('disabled', true);
                                        return false;
                                    }
                                }
                            });
                        }
                }
            }
        });

        $("#dont-wants-to-send").click(function () {
            $("#confirmModal").modal("hide");
        });

        /* $('input:radio[name="individual_company_insured"]').on('change', function () {
             if ($(this).is(':checked') && $(this).val() == 'company') {
                 $("#last_name_wrapper_insured").slideUp('slow');
                 $("#first_name_wrapper_insured").slideUp('slow', function () {
                     $('#first_name_label_insured').html('Naziv firme');
                     $('#first_name_wrapper_insured').slideDown('slow');
                 })
             }
             else {
 
                 $("#first_name_wrapper_insured").slideUp('slow', function () {
                     $('#first_name_label_insured').html('Ime');
                     $('#first_name_wrapper_insured').slideDown('slow');
                     $("#last_name_wrapper_insured").slideDown('slow');
                 })
             }
         })*/

        /* $('input:radio[name="individual_company_payment"]').on('change', function () {
             if ($(this).is(':checked') && $(this).val() == 'company') {
                 $("#last_name_wrapper_payment").slideUp('slow');
                 $("#first_name_wrapper_payment").slideUp('slow', function () {
                     $('#first_name_label_payment').html('Naziv firme');
                     $('#first_name_wrapper_payment').slideDown('slow');
                 })
             }
             else {
 
                 $("#first_name_wrapper_payment").slideUp('slow', function () {
                     $('#first_name_label_payment').html('Ime');
                     $('#first_name_wrapper_payment').slideDown('slow');
                     $("#last_name_wrapper_payment").slideDown('slow');
                 })
             }
         })*/

        // catch submit button click
        $('#damage_report_submit_button').on('click', function (e) {

            // prevent default button action
            if ($("#damage_report_type").val() == 'pzto' && $('input:radio[name=insured_citizenship_1]:checked').val() == 'foreign') {
                $("#jmbg").val('');
            }
            if ($("#damage_report_type").val() == 'pzto' && $('input:radio[name=insured_citizenship_1]:checked').val() == 'domestic') {
                $("#insured_gender_1").val('');
                $("input[name=insured_birthdate_1]").val('');
            }

            if ($("#damage_report_type").val() == 'pzto' && $('input:radio[name=insured_citizenship_2]:checked').val() == 'foreign') {
                $("#insured_jmbg").val('');
            }
            if ($("#damage_report_type").val() == 'pzto' && $('input:radio[name=insured_citizenship_2]:checked').val() == 'domestic') {
                $("#insured_gender_2").val('');
                $("input[name=insured_birthdate_2]").val('');
            }

            if ($("#damage_report_type").val() == 'pzto' && $('input:radio[name=is_applicant]:checked').val() == 'another_person'
                && $('input:radio[name=insured_citizenship_3]:checked').val() == 'domestic') {
                $("input[name=insured_birthdate_3]").val('');
            }

            if ($("#damage_report_type").val() == 'pzto' && $('input:radio[name=is_applicant]:checked').val() == 'another_person'
                && $('input:radio[name=insured_citizenship_3]:checked').val() == 'foreign') {
                $("#payment_jmbg").val('');
            }

            if ($("#damage_report_type").val() == 'pzto' && $('input:radio[name=is_applicant]:checked').val() == 'applicant') {
                $("#payment_first_name").val('');
                $("#payment_jmbg").val('');
                $("input[name=insured_birthdate_3]").val('');
                $("#insured_gender_3").val('');
            }

            e.preventDefault();
            if ($(this).hasClass('disabled')) {
                return false;
            }
            if (!TCApp.InvisibleRecaptcha.verify(self.processing)) {
                console.log('failed verify');
                // enable submits
                $(this).prop('disabled', false);
                // trigger validation
                window.grecaptcha.execute(TCApp.InvisibleRecaptcha.recaptchaID);
                return;
            } else {
                // ajax call
                self.submitLead();
            }
        })

        // catch object type changed
        $(document).on('blur', '*[data-validate-field="true"]', function () {

            // set new object type value
            if ($(this).hasClass('datepicker') || $(this).hasClass('datepicker-birthdate')) {
                var ref = this;
                setTimeout(function () {
                    self.validateField(ref, 'text');
                }, 600);
            } else {
                // otherwise validate simple text input
                self.validateField(this, 'text');
            }
        });
        // ajax single field validation
        $(document).on('change', 'select[data-validate-field="true"]', function () {
            self.validateField(this, 'dropdown');
        });

    }

    // hide jmbg if foreign user
    // catch citizenship change
    $(document).on('click', '.select-citizenship, .insured-select-citizenship, .payment-select-citizenship', function () {
        // get name and value
        var $name = $(this).data('common-name');
        var $value = $('input:radio[name=' + $name + ']:checked').val();
        // find parent jmbg
        var $jmbg_container = $(this).parent().parent().parent().parent().parent().find('.jmbg-container');
        // show/hide
        if ('domestic' == $value) {
            // show

            $($jmbg_container).slideDown();
            // hide birthdate and gender
            $(this).parent().parent().parent().parent().parent().parent().find('.foreign-show').slideUp();
        } else {
            // hide
            $($jmbg_container).slideUp();
            // show birthdate and gender
            $(this).parent().parent().parent().parent().parent().parent().find('.foreign-show').slideDown();
        }
    });


    // get policy types structure by current object id
    this.processing = function () {
        var formData = new FormData($('#damage_report_form')[0]);
        formData.append('g_recaptcha_token', TCApp.InvisibleRecaptcha.token);
        self.lockNext();
        // set ajax url and make ajax call
        var ajaxURL = URL + "/ajax/damage_reports/processing";
        var $_token = $("input[name=_token]").val();
        $.ajax({
            type: "POST",
            data: formData,
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            contentType: false,
            processData: false,
            headers: {
                'X-XSRF-TOKEN': $_token
            },
            success: function (data) {
                self.unlockNext();
                if (true === data.unexpected_error) {
                    $('#damage-reports-error-container').html(data.errors.unexpected_error);
                    return false;
                }
                if (null != data.redirect_url) {
                    window.location.href = data.redirect_url;
                }
                if (data.success == false) {
                    var $counter = 0;
                    $('*[data-validate-field=true]').each(function (id, selector) {
                        $.each(data.errors, function (index, error) {
                            if ($counter === 0) {
                                smoothScroll.animateScroll(null, '#' + index, { "offset": 50 });
                                // console.log('#'+index);
                            }
                            $counter++;
                            if ($(selector).attr('name') != index) {
                                if ($(selector).data('file') === 'yes') {
                                    $(selector).parent().parent().parent().siblings('.status').children('i').removeClass('error').addClass('ok');
                                    $(selector).removeClass('has-error').addClass('has-success');
                                    $(selector).parent().parent().parent().siblings('.errors').html('');
                                }
                                else {
                                    $(selector).parent().siblings('.status').children('i').removeClass('error').addClass('ok');
                                    $(selector).removeClass('has-error').addClass('has-success');
                                    $(selector).parent().siblings('.errors').html('');
                                }
                            }
                            else {
                                if ($(selector).data('file') === 'yes') {
                                    $(selector).parent().parent().parent().siblings('.status').children('i').removeClass('ok').addClass('error');
                                    $(selector).removeClass('has-success').addClass('has-error');
                                    $(selector).parent().parent().parent().siblings('.errors').html(error);
                                    return false;
                                }
                                else {
                                    $(selector).parent().siblings('.status').children('i').removeClass('ok').addClass('error');
                                    $(selector).removeClass('has-success').addClass('has-error');
                                    $(selector).parent().siblings('.errors').html(error);
                                    return false;
                                }
                            }
                        });
                    });

                    if ('insured_case' in data.errors) {
                        $('#insured_case_sprite').removeClass('ok').addClass('error');
                    }
                    else {
                        $('#insured_case_sprite').removeClass('error').addClass('ok');
                    }

                    if ('insured_gender_1' in data.errors) {
                        $('#status-insured_gender_1').removeClass('ok').addClass('error');
                        $('.errors_insured_gender_1').html('Polje je obavezno');
                    }
                    else {
                        $('#status-insured_gender_1').removeClass('error').addClass('ok');
                        $('.errors_insured_gender_1').html('');
                    }

                    if ('insured_gender_2' in data.errors) {
                        $('#status-insured_gender_2').removeClass('ok').addClass('error');
                        $('.errors_insured_gender_2').html('Polje je obavezno');
                    }
                    else {
                        $('#status-insured_gender_2').removeClass('error').addClass('ok');
                        $('.errors_insured_gender_2').html('');
                    }

                    if ('insured_gender_3' in data.errors) {
                        $('#status-insured_gender_3').removeClass('ok').addClass('error');
                        $('.errors_insured_gender_3').html('Polje je obavezno');
                    }
                    else {
                        $('#status-insured_gender_2').removeClass('error').addClass('ok');
                        $('.errors_insured_gender_3').html('');
                    }

                }
            }
        }
        );
    }


    // validate single field
    this.validateField = function (ref, type) {
        // inits
        var name = null;
        var value = null;
        // determine type and get name and value
        if ('text' == type || 'dropdown' == type) {
            // get values
            name = $(ref).attr('name');
            value = $(ref).val();
        }
        // call ajax method
        // set ajax url and make ajax call
        var ajaxURL = URL + "/ajax/damage_reports/validate-field";
        var $_token = $("input[name=_token]").val();
        var report_type = $('#damage_report_type').val();
        var report_type_id = $('#damage_report_type_code').val();
        $.ajax({
            type: "POST",
            data: {
                'name': name,
                'value': value,
                '_token': $_token,
                'report_type': report_type,
                'report_type_id': report_type_id
            },
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            headers: { 'X-XSRF-TOKEN': $_token },
            success: function (data) {
                if (true === data.unexpected_error) {
                    $('#damage-reports-error-container').html(data.errors.unexpected_error);
                    return false;
                }
                if (null != data.redirect_url) {
                    window.location.href = data.redirect_url;
                }
                // if success
                if (data.success === true) {
                    // show success
                    self.statusSuccess(data.field_name);

                } else {
                    // set error sign and message
                    // show error
                    self.statusError(data.field_name, data.error);
                }
            }
        });
    }

    // status success
    this.statusSuccess = function (id) {
        // set status to success for passed id
        if ($('*[name=' + id + ']').data('file') === 'yes') {
            $('*[name=' + id + ']').parent().parent().parent().siblings('.status').children('i').removeClass('error').addClass('ok');
            $('*[name=' + id + ']').removeClass('has-error').addClass('has-success');
            $('*[name=' + id + ']').parent().parent().parent().siblings('.errors').html('');
        }
        else {
            $('*[name=' + id + ']').parent().siblings('.status').children('i').removeClass('error').addClass('ok');
            $('*[name=' + id + ']').removeClass('has-error').addClass('has-success');
            $('*[name=' + id + ']').parent().siblings('.errors').html('');
        }
    }
    // status error
    this.statusError = function (id, message) {
        // set status to error for passed id
        if ($('*[name=' + id + ']').data('file') === 'yes') {
            $('*[name=' + id + ']').parent().parent().parent().siblings('.status').children('i').removeClass('ok').addClass('error');
            $('*[name=' + id + ']').removeClass('has-success').addClass('has-error');
            $('*[name=' + id + ']').parent().parent().parent().siblings('.errors').html(message);
        }
        else {
            $('*[name=' + id + ']').parent().siblings('.status').children('i').removeClass('ok').addClass('error');
            $('*[name=' + id + ']').removeClass('has-success').addClass('has-error');
            $('*[name=' + id + ']').parent().siblings('.errors').html(message);
        }
    }

    // unlock next step button
    this.unlockNext = function () {
        // console.log('unlock')
        $('#damage_report_submit_button').removeClass('disabled').prop('disabled', false);;
        $('.damage-reports-next-loader').addClass('hide');
    }
    // lock next step button
    this.lockNext = function () {
        $('#damage_report_submit_button').addClass('disabled').prop('disabled', true);;
        $('.damage-reports-next-loader').removeClass('hide');
    }
}
