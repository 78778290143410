function KZO_Animated() {
    // declarations
    var self = this;
    this.step = 1;
    this.max_reached_step = 1;
    this.validation_step = 7;
    this.labels = [];
    this.number_of_years = 18;
    this.gender = 0;
    this.partner = 0;
    this.partner_age = 0;
    this.min_number_of_children = 0;
    this.max_number_of_children = 4;
    this.number_of_chidren = 0;
    this.prev_number_of_children = 0;
    this.monthly_price = 0;
    this.animation = [];
    this.strokAnimation = [];
    this.calculated = false;

    var cake_holder = $('.life-insurance-animations-cake-holder'),
        people_chopper_holder = $('.life-insurance-animations-people-chopper-holder'),
        man_holder = $('.life-insurance-animations-man-holder'),
        man = man_holder.find(".life-insurance-animations-man"),
        man_rope = man_holder.find(".life-insurance-animations-chopper-rope"),
        woman_holder = $('.life-insurance-animations-woman-holder'),
        woman = woman_holder.find(".life-insurance-animations-woman"),
        woman_rope = woman_holder.find(".life-insurance-animations-chopper-rope"),
        chopper_holder = $('.life-insurance-animations-chopper-holder'),
        man_clothes = $('.life-insurance-animations-man-clothes'),
        man_no_clothes = $('.life-insurance-animations-man-no-clothes'),
        woman_clothes = $('.life-insurance-animations-woman-clothes'),
        woman_no_clothes = $('.life-insurance-animations-woman-no-clothes'),
        stork_holder = $('.life-insurance-animations-stork-holder'),
        stork_bag = $('.life-insurance-animations-stork-bag'),
        child_holder = $('.life-insurance-animations-child-holder'),
        money_bag_holder = $('.life-insurance-animations-money-bag-holder'),
        extra_money_bags_holder = $('.life-insurance-animations-money-bag-extra-holder'),
        banch_people = $('.life-insurance-animations-banch-people'),
        animations_wrapper = $('#animation-wrapper'),
        rain_holder = $('.life-insurance-animations-bg-rain'),
        clouds_holder = $('.life-insurance-animations-cloud'),
        storm_cloud = $('.life-insurance-animations-cloud-3'),
        umbrella_holder = $('.life-insurance-animations-umbrella'),
        woman_insurance = $('.life-insurance-animations-woman-insurance-yes'),
        man_insurance = $('.life-insurance-animations-man-insurance-yes'),
        start_calc = $('.start-calculator-msg')

    // listeners
    this.listeners = function () {
        // initial calls
        $(window).load(function () {
            self.CanvasSizeCheck();
            self.showStep(1);
            setTimeout(function () {
                start_calc.addClass('show');
            }, 2000)
            self.animateListener();
            self.triggerAnimationsPhone($('.step-position'))
        });
        $(window).resize(function () {
            self.CanvasSizeCheck();
        });
    }

    // trigger next step button
    this.next = function () {
        // check if validation step
        if (self.step == self.validation_step) {
            // revert current step to last possible form step
            self.step = self.validation_step;
            // call ajax validation and calculation
            self.calculate();
        }
        else {
            // increment step counter
            self.step++;
            // set max reached step
            if (self.step > self.max_reached_step) {
                self.max_reached_step = self.step;
            }
            // check if linked steps
            if ($('#step-' + self.step).hasClass('linked-step') && $('#step-' + self.step).data('linked-step') == (self.step - 1)) {
                if ((self.step + 1 ) > self.max_reached_step) {
                    // set max reached step
                    self.max_reached_step = (self.step + 1);
                }
                // animate current step
                self.switchStep(self.step + 1);
            }
            else {
                // animate current step
                self.switchStep(self.step);
            }
            if ($(document).width() < 800) {
                // scroll to upper section
                smoothScroll.animateScroll(null, '#step-' + self.step, {"offset": 50});
            }
        }
    }

    // trigger previous step button
    this.prev = function () {
        // check if possible
        if (self.step > 1) {
            // check if linked steps
            if ($('#step-' + self.step).hasClass('linked-step') && $('#step-' + self.step).data('linked-step') == (self.step - 1)) {
                self.switchStep((self.step - 2));

            }
            else {
                self.switchStep((self.step - 1));
            }
            if ($(document).width() < 800) {
                // scroll to upper section
                smoothScroll.animateScroll(null, '#step-' + self.step, {"offset": 50});
            }
        }
    }

    // animate listener is main animation event listener
    this.animateListener = function () {
        // trigger next step button
        $('.life-insurance-step-next').on('click', function (e) {
            e.preventDefault();
            // check if next available
            if ($(this).hasClass('disabled')) {
                return false;
            }
            self.next();
        });
        // trigger previous step button
        $('.kzo-prev').on('click', function () {
            // if calculated check window size before switching step
            if (self.calculated === true) {
                self.CanvasSizeCheck();
                self.calculated = false;
                self.toggleInsuranceHoldingAnimation();
                self.animationWrapperSize();
                self.toggleOfferBox();
                self.toggleOfferPackages();
            }
            self.prev();
        });

        // trigger animation reload
        $('.kzo-reload').on('click', function () {
            location.reload();
        });
        // step indicator change
        $('.life-insurance-step-num').on('click', function () {
            // change step
            self.switchStep($(this).data('step'));
        });
        // age slider change
        $('#ageSlider').on('input', function () {
            start_calc.removeClass('show');
            self.number_of_years = $(this).val();
            self.displayProperNumberOfCandles();
            self.validateStep(self.step)
        })
        // gender change
        $('.input-radio[name="gender"]').on('change', function () {
            start_calc.removeClass('show');
            self.gender = $(this).val();
            self.genderChange(self.gender);
            self.validateStep(self.step)
        })
        // partner change
        $('.input-radio[name="partner"]').on('change', function () {
            self.partner = $(this).val();
            self.animateChopper();
            self.validateStep(self.step)
        })

        // partner age change
        $('input[name="partner-age"],#partner-ageSlider').on('input blur change', function () {
            self.partner_age = this.value;
            self.validateStep(self.step)
        })

        // number of children
        $('.input-number[name="no-kids"]').on('input blur change', function () {
            if($(this).val() > 4){
                $(this).val('4');
            }
            self.prev_number_of_children = self.number_of_chidren;
            self.number_of_chidren = $(this).val();
            self.showChildAgeInput();
        })

        // children age change
        $('.input-range-child-wrapper .input-range').on('input', function () {
            var $age = $(this).val(),
                $child = $(this).data('child');
            self.manageChildrenState($age, $child);
        })

        // monthly price slider change

        $('input[name="monthly-price"], #monthly-price-slider').on('input blur change', function () {
            self.monthly_price = $('.input-range-value[name="monthly-price"]').val();
            self.displayProperSizeMoneyBag();
            self.validateStep(self.step)
        })

        // disease radio button change
        $('.input-radio[name="disease"]').on('change', function () {
            self.validateStep(self.step)
        })

        // insurance purpose radio button change
        $('.input-radio[name="insurance-purpose"]').on('change', function () {
            self.validateStep(self.step)
        })
    }

    // animate is main method for actual animations
    this.showStep = function (step) {
        // check if explicit step selected
        if (typeof step === 'undefined') {
            // use current step
            step = self.step;
        }
        // switch steps
        self.switchStep(step);
    }

    // switch step. this is triggered by step indicator click
    // or by returned validation error
    this.switchStep = function (step) {
        // check if step switching allowed
        if (step > self.max_reached_step) {
            return;
        }
        // lock next button
        self.lockNext();
        // set step
        self.step = step;
        // validate current step
        self.validateStep();
        // change indicator
        if ($('#step-' + step).hasClass('linked-step')) {
            var linked_step = $('#step-' + step).data('linked-step');
            $('#step-' + step).addClass('active').removeClass('inactive');
            $('#step-' + linked_step).addClass('active').removeClass('inactive').removeClass('answered');
            $('#step-' + step).nextAll('.life-insurance-step').not('#step-' + linked_step).addClass('inactive').removeClass('active');
            $('#step-' + step).prevAll('.life-insurance-step').not('#step-' + linked_step).addClass('answered').removeClass('inactive').removeClass('active');

        }
        else {
            $('#step-' + step).addClass('active').removeClass('inactive');
            $('#step-' + step).nextAll('.life-insurance-step').addClass('inactive').removeClass('active');
            $('#step-' + step).prevAll('.life-insurance-step').addClass('answered').removeClass('inactive').removeClass('active');
        }

        // show back button
        if (1 !== self.step && 2 !== self.step) {
            $('.kzo-prev').fadeIn();
        }
        else {
            $('.kzo-prev').fadeOut();
        }
        // show step
        if (1 == self.step || 2 == self.step) {
            cake_holder.css('opacity', '1');
            people_chopper_holder.css('opacity', '1');
            money_bag_holder.css('opacity', '0');
            extra_money_bags_holder.css('opacity', '0');
            banch_people.css('opacity', '0');
            child_holder.css('opacity', '0');
            man_holder.css('opacity', '1')
            self.animationReset();
            self.purposeChange('reset');


        }
        // show step
        if (3 == self.step || 4 == self.step) {
            cake_holder.css('opacity', '0');
            people_chopper_holder.css('opacity', '1');
            money_bag_holder.css('opacity', '0');
            extra_money_bags_holder.css('opacity', '0');
            banch_people.css('opacity', '0');
            child_holder.css('opacity', '1');
            man_holder.css('opacity', '1');
            if (self.partner === 'Y' || self.gender === 'f') {
                woman_holder.css({'opacity': '1', 'right': 'auto', 'left': '44.5%'});
            }
            self.purposeChange('reset');

        }
        // show step
        if (5 == self.step) {
            cake_holder.css('opacity', '0');
            people_chopper_holder.css('opacity', '.6');
            money_bag_holder.css('opacity', '1');
            extra_money_bags_holder.css('opacity', '1');
            banch_people.css('opacity', '1');
            child_holder.css('opacity', '1');
            man_holder.css('opacity', '1')
            if (self.partner === 'Y' || self.gender === 'f') {
                woman_holder.css({'opacity': '1', 'right': 'auto', 'left': '44.5%'});
            }
            self.purposeChange('reset');
        }
        // show step
        if (6 == self.step) {
            cake_holder.css('opacity', '0');
            people_chopper_holder.css('opacity', '1');
            money_bag_holder.css('opacity', '1');
            extra_money_bags_holder.css('opacity', '1');
            banch_people.css('opacity', '1');
            child_holder.css('opacity', '.6');
            if (self.gender == 'm') {
                man_holder.css('opacity', '1')
                if (self.partner === 'Y' || self.gender === 'f') {
                    woman_holder.css({'opacity': '.6', 'right': 'auto', 'left': '44.5%'});
                }
            }
            else if (self.gender == 'f') {
                man_holder.css('opacity', '.6')
                if (self.partner === 'Y' || self.gender === 'f') {
                    woman_holder.css({'opacity': '1', 'right': 'auto', 'left': '44.5%'});
                }
            }
            self.purposeChange('reset');
        }
        // show step
        if (7 == self.step) {
            cake_holder.css('opacity', '0');
            people_chopper_holder.css('opacity', '1');
            money_bag_holder.css('opacity', '1');
            extra_money_bags_holder.css('opacity', '1');
            banch_people.css('opacity', '1');
            child_holder.css('opacity', '1');
            man_holder.css('opacity', '1');
            if (self.partner === 'Y' || self.gender === 'f') {
                woman_holder.css({'opacity': '1', 'right': ' 79%', 'left': 'auto'});
            }
            self.purposeChange();

        }
        // show step
        if (8 == self.step) {
        }
        // show step
        if (9 == self.step) {
        }
    }

    // validate steps
    this.validateStep = function (ref) {
        // step 1 and 2
        if (1 === self.step || 2 === self.step) {
            // lock next
            self.lockNext();
            if (self.number_of_years < 18 || self.number_of_years > 65) {
                return false;
            }
            if (self.gender === 0) {
                return false;
            }
            // unlocklock next
            self.unlockNext();
            return true;
        }
        // step 3 and 4
        if (3 === self.step || 4 === self.step) {
            // lock next
            self.lockNext();
            if (self.partner === 0) {
                return false;
            }
            else if (self.partner === 'Y') {
                if (self.partner_age === 0) {
                    $('.small-error-message[data-step="3"]').slideDown();
                    return false
                }
                else if (self.partner_age < 18 || self.partner_age > 65) {
                    return false;
                }
            }
            // unlocklock next
            $('.small-error-message[data-step="3"]').slideUp();
            self.unlockNext();
            return true;
        }
        // step 5
        if (5 === self.step) {
            // lock next
            self.lockNext();
            if ($('input[name="monthly-price"]').val() < 44 || $('input[name="monthly-price"]').val() > 1200 || $('input[name="monthly-price"]').val() === null) {
                $('.small-error-message[data-step="5"]').slideDown();
                return false;
            }
            // unlocklock next
            $('.small-error-message[data-step="5"]').slideUp();
            self.unlockNext();
            return true;
        }
        // step 6
        if (6 === self.step) {
            // lock next
            self.lockNext();
            if (!$('input[name="disease"]').is(':checked')) {
                return false;
            }
            // get disease status
            var disease = $('input[name="disease"]:checked').val();
            // set validation step
            self.diseaseChange(disease);
            // unlock next
            self.unlockNext();
            return true;
        }
        // step 7
        if (7 === self.step) {
            // lock next
            self.lockNext();
            if (!$('input[name="insurance-purpose"]').is(':checked')) {
                return false;
            }
            // get purpose
            var purpose = $('input[name="insurance-purpose"]:checked').val();
            // set purpose
            self.purposeChange(purpose)
            // unlock next
            self.unlockNext();
            return true;
        }
        self.unlockNext();
    }

    // ajax validation and calculation step
    this.calculate = function () {
        // serialize data
        var formData = $('#kzo-form').serialize();
        // set ajax url and make ajax call
        var ajaxURL = URL + "/ajax/kzo/processing";
        var $_token = $("input[name=_token]").val();
        $.ajax({
            type: "POST",
            data: formData,
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            headers: {'X-XSRF-TOKEN': $_token},
            success: function (data) {
                self.calculated = true;
                self.animationWrapperSize();
                if (data.unexpected_error === true) {
                    $('.offer-success').hide();
                    $('.offer-error').show();
                    $('.offer-error-text').html(data.errors.unexpected_error);
                    self.toggleOfferBox();
                    return false;
                }
                if (null != data.redirect_url) {
                    window.location.href = data.redirect_url;
                }
                $('.offer-error').hide();
                $('.offer-success').show();
                self.toggleInsuranceHoldingAnimation();
                self.toggleOfferBox(data);
                self.toggleOfferPackages(data);
            }
        });
    }


    // get data common name
    this.commonName = function (ref) {
        return $(ref).data('common-name');
    }

    // unlock next step button
    this.unlockNext = function () {
        $('.life-insurance-step-next').removeClass('disabled').addClass('show').show();
    }
    // lock next step button
    this.lockNext = function () {
        $('.life-insurance-step-next').addClass('disabled');
    }


    // display proper number of candels based on ages selected
    this.displayProperNumberOfCandles = function () {
        if (self.step == 1 || self.step == 2) {
            switch (!0) {
                case self.number_of_years >= 19 && 20 > self.number_of_years:
                    self.switchCandle(1);
                    break;
                case self.number_of_years >= 20 && 21 > self.number_of_years:
                    self.switchCandle(2);
                    break;
                case self.number_of_years >= 21 && 23 > self.number_of_years:
                    self.switchCandle(3);
                    break;
                case self.number_of_years >= 23 && 25 > self.number_of_years:
                    self.switchCandle(4);
                    break;
                case self.number_of_years >= 25 && 27 > self.number_of_years:
                    self.switchCandle(5);
                    break;
                case self.number_of_years >= 27 && 29 > self.number_of_years:
                    self.switchCandle(6);
                    break;
                case self.number_of_years >= 29 && 31 > self.number_of_years:
                    self.switchCandle(7);
                    break;
                case self.number_of_years >= 31 && 34 > self.number_of_years:
                    self.switchCandle(8);
                    break;
                case self.number_of_years >= 34 && 37 > self.number_of_years:
                    self.switchCandle(9);
                    break;
                case self.number_of_years >= 37 && 41 > self.number_of_years:
                    self.switchCandle(10);
                    break;
                case self.number_of_years >= 41 && 45 > self.number_of_years:
                    self.switchCandle(11);
                    break;
                case self.number_of_years >= 45 && 49 > self.number_of_years:
                    self.switchCandle(12);
                    break;
                case self.number_of_years >= 49 && 54 > self.number_of_years:
                    self.switchCandle(13);
                    break;
                case self.number_of_years >= 54:
                    self.switchCandle(14);
                    break;
                default:
                    self.switchCandle(0)
            }
        }
    }

    // switch candel opacity
    this.switchCandle = function (a) {
        if (a !== 0) {
            var c = $('.life-insurance-animations-cake-candle-' + a);
            c.css('opacity', '1');
            c.nextAll('.life-insurance-animations-cake-candle').css('opacity', '0');
            c.prevAll('.life-insurance-animations-cake-candle').css('opacity', '1');
        }
        else {
            $('.life-insurance-animations-cake-candle').css('opacity', '0');
        }

    }

    // gender switch animation
    this.genderChange = function (a) {
        switch (self.gender) {
            // show proper image based on gender
            case"m":
                (self.partner !== 0) ? ((self.partner === "Y") ? (woman_no_clothes.css('opacity', '0'), woman_clothes.css('opacity', '1'), man_no_clothes.css('opacity', '0'), man_clothes.css('opacity', '1')) : (woman_no_clothes.css('opacity', '0'), woman_clothes.css('opacity', '0'), man_no_clothes.css('opacity', '0'), man_clothes.css('opacity', '1'))) : (woman_clothes.css('opacity', '0'), woman_no_clothes.css('opacity', '1'), man_no_clothes.css('opacity', '0'), man_clothes.css('opacity', '1'));
                break;
            case"f":
                (self.partner !== 0) ? ((self.partner === "Y") ? (woman_no_clothes.css('opacity', '0'), woman_clothes.css('opacity', '1'), man_no_clothes.css('opacity', '0'), man_clothes.css('opacity', '1')) : (woman_no_clothes.css('opacity', '0'), woman_clothes.css('opacity', '1'), man_no_clothes.css('opacity', '0'), man_clothes.css('opacity', '0'))) : (woman_clothes.css('opacity', '1'), woman_no_clothes.css('opacity', '0'), man_no_clothes.css('opacity', '1'), man_clothes.css('opacity', '0'));
        }
    }

    // chopper animation
    this.animateChopper = function () {
        // check partner status
        if ('N' === self.partner) {
            // hide partner age input
            $('.life-insurance-partner-age-wrapper').slideUp('slow');
            switch (this.gender) {
                case"m":
                    woman_no_clothes.css("opacity", 1);
                    woman_clothes.css("opacity", 0);
                    break;
                case"f":
                    man_no_clothes.css("opacity", 1);
                    man_clothes.css("opacity", 0);
            }
            self.animation[3] = new TimelineMax;
            self.animation[3].timeScale(1.5);
            "f" === self.gender ? self.animation[3].to(man_rope, 0, {opacity: 0}, 0).to(man_holder, 0, {
                left: "13%",
                rotation: 0,
                bottom: "0"
            }, 0).to(chopper_holder, 4.5, {
                right: "-5.4%",
                width: "92.201%",
                height: "7.423vw",
                bottom: "90%",
                opacity: 1,
                delay: .5,
                ease: Power1.easeInOut,
                force3D: !0
            }, "resetEnd").to(chopper_holder, 2, {
                right: "58.4%",
                width: "92.201%",
                height: "7.423vw",
                rotation: -10,
                opacity: 1,
                delay: .5,
                ease: Sine.easeIn,
                force3D: !0
            }, "TakeOf").to(man_holder, 2, {
                left: "-36.2%",
                rotation: -10,
                bottom: "-4.5%",
                delay: .5,
                ease: Sine.easeIn,
                force3D: !0
            }, "TakeOf").to(man, 2, {
                "max-width": "66.055px",
                top: "-4%",
                delay: .5,
                ease: Sine.easeIn
            }, "TakeOf").to(man_rope, .5, {opacity: 1}, "TakeOf").to(chopper_holder, 3.5, {
                right: "408.4%",
                bottom: "114%",
                rotation: -10,
                ease: Power0.easeIn,
                force3D: !0
            }, "TakeOfAndGo").to(man_holder, 3.5, {
                left: "-390%",
                bottom: "32%",
                ease: Power0.easeIn,
                force3D: !0
            }, "TakeOfAndGo") : "m" === self.gender && self.animation[3].to(chopper_holder, 0, {
                right: "-112%",
                width: "38.532%",
                height: "41px",
                bottom: "77%",
                rotation: 0
            }).to(woman_holder, .3, {
                right: "13%",
                left: "44.5%",
                rotation: 0,
                bottom: "0"
            }, 0).to(woman_rope, 0, {opacity: 0}).to(chopper_holder, 4.5, {
                right: "-41%",
                width: "92.201%",
                height: "7.423vw",
                bottom: "88%",
                opacity: 1,
                delay: .5,
                ease: Power1.easeInOut,
                force3D: !0
            }, "resetEnd").to(chopper_holder, 2, {
                right: "58.4%",
                width: "92.201%",
                height: "7.423vw",
                rotation: -10,
                opacity: 1,
                delay: .5,
                ease: Sine.easeIn,
                force3D: !0
            }, "TakeOf").to(woman_holder, 2, {
                left: "-38%",
                rotation: -10,
                bottom: "-4.5%",
                delay: .5,
                ease: Sine.easeIn,
                force3D: !0
            }, "TakeOf").to(woman, 2, {
                "max-width": "80px",
                top: "-4%",
                delay: .5,
                ease: Sine.easeIn
            }, "TakeOf").to(woman_rope, .5, {opacity: 1}, "TakeOf").to(chopper_holder, 3.5, {
                right: "408.4%",
                bottom: "114%",
                rotation: -10,
                ease: Power0.easeIn,
                force3D: !0
            }, "TakeOfAndGo").to(woman_holder, 3.5, {
                left: "-390%",
                bottom: "32%",
                ease: Power0.easeIn,
                force3D: !0
            }, "TakeOfAndGo")
        }
        else if ('Y' === self.partner) {
            // show partner age input
            $('.life-insurance-partner-age-wrapper').slideDown('slow');
            // trigger animation reset
            self.animationReset();
        }

    }

    this.animationReset = function (a) {
        if (self.animation[3]) {
            if (self.animation[3].time() > 0) {
                // trigger proper animation reset based on gender or reset all animations
                "all" === a ? (self.animationResetMan(chopper_holder, man_holder), self.animationResetWoman(chopper_holder, woman_holder)) : "f" === self.gender ? self.animationResetMan(chopper_holder, man_holder) : "m" === self.gender && self.animationResetWoman(chopper_holder, woman_holder)
            }
            else {
                // trigger gender change/check
                self.genderChange(self.gender);
            }
        }
        else
        // trigger gender change/check
            self.genderChange(self.gender);
    }
    // reset animation for man
    this.animationResetMan = function (a, c) {
        TweenMax.to([a, c], 1, {
            opacity: 0, onComplete: function () {
                self.animation[3].tweenFromTo(0, "resetEnd"),
                    TweenMax.to(a, 1, {opacity: .3}),
                    TweenMax.to(c, 1, {opacity: 1}),
                    // trigger gender change/check
                    self.genderChange(self.gender)
            }
        })
    }
    // reset animation for woman
    this.animationResetWoman = function (a, c) {
        TweenMax.to([a, c], 1, {
            opacity: 0, onComplete: function () {
                self.animation[3].tweenFromTo(0, "resetEnd"),
                    TweenMax.to(a, 1, {opacity: .3}),
                    TweenMax.to(c, 1, {opacity: 1}),
                    // trigger gender change/check
                    self.genderChange(self.gender)
            }
        })
    }

    // get number of children selected,create right number of storks and animate
    this.animateStork = function (no_children) {
        // check if selected number of children is between min and max value
        if (self.number_of_chidren <= self.max_number_of_children && self.number_of_chidren > self.prev_number_of_children && self.number_of_chidren != self.min_number_of_children) {
            // clone stork
            var stork_clone = stork_holder.clone();
            // set style and id for cloned stork
            stork_clone.attr("style", "").attr("id", "storkHolder-" + no_children).insertAfter(stork_bag);
            // clone stork bag
            var stork_bag_clone = stork_bag.clone();
            // set style and id for cloned stork bag
            stork_bag_clone.attr("id", "storkBag-" + no_children).attr("style", "").insertAfter(stork_clone);

            // animate stork
            storkAnimation(no_children)
        } else {
            // if selected number of children is equal to min allowed value

            var m = child_holder.find(".life-insurance-animations-child-" + (parseInt(self.number_of_chidren) + 1))
            // hide all children after this
            n = m.nextAll(".life-insurance-animations-child");
            n.css("opacity", 0)
            m.css('opacity', 0)
            // reset stork animation
            storkAnimationReset(parseInt(self.number_of_chidren) + 1)
        }

        // animate stork
        function storkAnimation(a) {
            var c = "#storkHolder-" + a,
                d = "#storkBag-" + a,
                e = stork_holder.siblings(c).andSelf(c),
                f = stork_bag.siblings(d).andSelf(d);
            self.strokAnimation[a] = new TimelineMax;
            self.strokAnimation[a].timeScale(1.7);
            self.strokAnimation[a].to(e, 4, {
                left: "62%",
                bottom: "66%",
                rotation: -10,
                delay: .5 * a,
                ease: Linear.easeNone
            }, 0).to(e, 1, {opacity: 1, delay: .5 * a, ease: Linear.easeNone}, 0).to(f, 1, {
                opacity: 1,
                delay: .5 * a,
                ease: Linear.easeNone
            }, 0).to(f, 4, {
                left: "61.3%",
                bottom: "61.8%",
                rotation: "-13deg",
                delay: .5 * a,
                ease: Linear.easeNone
            }, 0).to(f, 4, {bottom: "4%", left: "6" + a + ".3%", rotation: "0deg"}).to(f, 1, {
                scaleY: .6,
                onComplete: function () {
                    child_holder.find(".life-insurance-animations-child-" + a).css("opacity", 1);
                    storkAnimationReset(a)
                }
            }, "-=1").to(e, 5.5, {left: "0%", x: "-110%", bottom: "63%", ease: Linear.easeNone}, "=-4")
        }

        // reset stork animation
        function storkAnimationReset(a) {
            var c = $("#storkHolder-" + a),
                d = $("#storkBag-" + a),
                e = animations_wrapper.find(c),
                f = animations_wrapper.find(d);
            (new TimelineMax).to(e, 1, {
                opacity: 0,
                onComplete: function () {
                    e.remove();
                    f.remove();
                    self.strokAnimation[a].kill();
                }
            }, 0).to(f, 1, {opacity: 0}, 0).to(e, 0, {
                bottom: "56%",
                left: "100%",
                x: "0%",
                rotation: 0,
                opacity: 0
            }).to(f, 0, {bottom: "51.8%", left: "99.3%", scaleY: 1, opacity: 0})
        }
    }
    // manage children state based on years selected
    this.manageChildrenState = function (a, c) {
        var d = $(".life-insurance-animations-child-" + c + "-0"),
            e = $(".life-insurance-animations-child-" + c + "-1"),
            f = $(".life-insurance-animations-child-" + c + "-2"),
            g = $(".life-insurance-animations-child-" + c + "-3"),
            h = $(".life-insurance-animations-child-" + c + "-4"),
            i = $(".life-insurance-animations-child-" + c + "-5");
        switch (!0) {
            case a >= 1 && 2 > a:
                d.removeClass("hidden").siblings().addClass("hidden");
                break;
            case a >= 2 && 4 > a:
                e.removeClass("hidden").siblings().addClass("hidden");
                break;
            case a >= 4 && 8 > a:
                f.removeClass("hidden").siblings().addClass("hidden");
                break;
            case a >= 8 && 12 > a:
                g.removeClass("hidden").siblings().addClass("hidden");
                break;
            case a >= 12 && 18 > a:
                h.removeClass("hidden").siblings().addClass("hidden");
                break;
            case a >= 18:
                i.removeClass("hidden").siblings().addClass("hidden")
        }
    }

    // show child Age input slider based on number of children selected
    this.showChildAgeInput = function () {
        if (self.number_of_chidren == 0) {
            $('.life-insurance-children-age-wrapper').slideUp('slow', function () {
                $('.input-range-child-wrapper[data-child="' + (parseInt(self.number_of_chidren) + 1) + '"]').find('.input-range').val('0').trigger('change');
            });
            self.animateStork();
        }
        else if (self.number_of_chidren <= self.max_number_of_children) {
            $('.life-insurance-children-age-wrapper').slideDown('slow');
            if (self.prev_number_of_children < self.number_of_chidren) {
                    var i = parseInt(self.prev_number_of_children) + 1;
            }
            else {
                var i = 1;
            }
            for (i; i <= self.number_of_chidren; i++) {
                $('.input-range-child-wrapper[data-child="' + i + '"]').slideDown('slow');
                self.animateStork(i);
            }
            $('.input-range-child-wrapper[data-child="' + self.number_of_chidren + '"]').nextAll('.input-range-child-wrapper').slideUp('slow', function () {
                $(this).find('.input-range').val('0').trigger('change');
            });
        }
    }

    // resize money bag based on monthly price
    this.displayProperSizeMoneyBag = function () {
        var a = $('.life-insurance-animations-money-bag-1'),
            b = $('.life-insurance-animations-money-bag-2'),
            c = $('.life-insurance-animations-money-bag-3')

        switch (!0) {
            case self.monthly_price >= 44 && 50 > self.monthly_price:
                money_bag_holder.css('transform', 'scale(1.05)');
                extra_money_bags_holder.css('opacity', '0');
                break;
            case self.monthly_price >= 50 && 150 > self.monthly_price:
                money_bag_holder.css('transform', 'scale(1.1)');
                extra_money_bags_holder.css('opacity', '0');
                break;
            case self.monthly_price >= 150 && 300 > self.monthly_price:
                money_bag_holder.css('transform', 'scale(1.15)');
                extra_money_bags_holder.css('opacity', '0');
                break;
            case self.monthly_price >= 300 && 450 > self.monthly_price:
                money_bag_holder.css('transform', 'scale(1.2)');
                extra_money_bags_holder.css('opacity', '0');
                break;
            case self.monthly_price >= 450 && 600 > self.monthly_price:
                money_bag_holder.css('transform', 'scale(1.25)');
                extra_money_bags_holder.css('opacity', '0');
                break;
            case self.monthly_price >= 600 && 750 > self.monthly_price:
                money_bag_holder.css('transform', 'scale(1.3)');
                extra_money_bags_holder.css('opacity', '1');
                a.css('opacity', '1'), b.css('opacity', '0'), c.css('opacity', '0');
                break;
            case self.monthly_price >= 750 && 900 > self.monthly_price:
                money_bag_holder.css('transform', 'scale(1.35)');
                extra_money_bags_holder.css('opacity', '1');
                a.css('opacity', '1'), b.css('opacity', '1'), c.css('opacity', '0');
                break;
            case self.monthly_price >= 900 && 1050 > self.monthly_price:
                money_bag_holder.css('transform', 'scale(1.4)');
                extra_money_bags_holder.css('opacity', '1');
                a.css('opacity', '1'), b.css('opacity', '1'), c.css('opacity', '1');
                break;
            case self.monthly_price >= 1050 && 1200 > self.monthly_price:
                money_bag_holder.css('transform', 'scale(1.45)');
                extra_money_bags_holder.css('opacity', '1');
                a.css('opacity', '1'), b.css('opacity', '1'), c.css('opacity', '1');
        }
    }

    // insurance purpose change
    this.purposeChange = function (purpose) {
        // in case purpose is protection show storm clouds and umbrella
        if (purpose === 'protection') {
            umbrella_holder.css('opacity', '1');
            rain_holder.show().css('opacity', '1');
            storm_cloud.css('opacity', '1');
            clouds_holder.addClass('active');
            // change button color and text
            $('#purpose-step').removeClass('green').addClass('mainColor');
            $('#purpose-step span').html($('#purpose-step span').html().replace('Izračunaj', 'Dalje'));
            self.validation_step = 9;

        }
        // in case purpose is savings hide storm clouds and umbrella
        else if (purpose === 'savings') {
            umbrella_holder.css('opacity', '0');
            rain_holder.show().css('opacity', '0');
            storm_cloud.css('opacity', '0');
            clouds_holder.removeClass('active');
            // change button color and text
            $('#purpose-step').removeClass('mainColor').addClass('green');
            $('#purpose-step span').html($('#purpose-step span').html().replace('Dalje', 'Izračunaj'))
            self.validation_step = 7;
        }
        // hide storm clouds and umbrella
        else if (purpose === 'reset') {
            umbrella_holder.css('opacity', '0');
            rain_holder.show().css('opacity', '0');
            storm_cloud.css('opacity', '0');
            clouds_holder.removeClass('active');
            // change button color and text
            $('#purpose-step').removeClass('green').addClass('mainColor');
            $('#purpose-step span').html($('#purpose-step span').html().replace('Izračunaj', 'Dalje'))
        }
    }

    // insurance purpose change
    this.diseaseChange = function (data) {
        if (data == 'yes') {
            // change button color and text
            $('#disease-step').removeClass('mainColor').addClass('green');
            $('#disease-step span').html($('#disease-step span').html().replace('Dalje', 'Izračunaj'));
            self.validation_step = 6;
        }
        else if (data == 'no') {
            // change button color and text
            $('#disease-step').removeClass('green').addClass('mainColor');
            $('#disease-step span').html($('#disease-step span').html().replace('Izračunaj', 'Dalje'))
            self.validation_step = 7;
        }
    }


    // calculate animated div height
    this.CanvasSizeCheck = function () {
        var newHeight = 0;

        // phone corrections
        if ($(document).width() < 800) {
            newHeight = $(window).height();
            $('.life-insurance-calc').css({'height': newHeight + 'px', 'position': 'fixed'});
            $('#question-wrapper').css('height', 'auto');
            $('#top').addClass('kzo-animated-top');
            $('#header').addClass('kzo-animated-header');
            self.animationPositionPhone($('.lower-section'));
        }
        else {
            $('.life-insurance-calc').css({'height': 'auto', 'position': 'relative'});
            $('#question-wrapper').css('height', 'auto');
            $('#top').removeClass('kzo-animated-top');
            $('#header').removeClass('kzo-animated-header');
        }

    }

    // show calculated prices
    this.toggleOfferBox = function (data) {
        if (self.calculated === true) {
            if (typeof data !== 'undefined') {
                $('#ideal-offer-name').html(data.insurances[0].name);
                $('#offer-box-lead-button').attr('data-offer', data.insurances[0].slug);

                if (data.insurances.length === 1) {
                    $('#ideal-offer-text').html('U nastavku pogledajte Vaš personalizirani obračun kreiran na osnovu podataka koje ste unijeli!');
                }
                else if (data.insurances.length === 2) {
                    $('#ideal-offer-text').html('U nastavku pogledajte i drugi personalizirani obračun kreiran na osnovu podataka koje ste unijeli!');
                }
                else if (data.insurances.length === 3) {
                    $('#ideal-offer-text').html('U nastavku pogledajte i druge personalizirane programe kreirane na osnovu podataka koje ste unijeli!');
                }
            }

            // hide questions and show calculation box
            $('.life-insurance-step-wrapper').fadeOut('slow');
            $('#offer-info').fadeIn('slow');
            $("#lead-box").fadeIn('slow');
            $("lead-box").css("display",'block');
            $('.kzo-reload').fadeIn('slow');
            //self.toggleLeadBox('hide');
        }
        else {
            // hide calculation box and show questions in case back is clicked
            $('.life-insurance-step-wrapper').fadeIn('fast');
            $('#offer-info').fadeOut('fast');
            $('.kzo-reload').fadeOut('fast');
            self.toggleLeadBox('hide');

        }
    }

    this.toggleOfferPackages = function (data) {
        if (self.calculated === true) {
            if (data.insurances.length === 1) {
                $('#offer-1').removeClass().addClass('col-md-4 col-sm-6 col-md-offset-4').html($('#offer-' + data.insurances[0].slug).html()).fadeIn('slow');
                $('#offer-1').find('#offer-price').prepend(data.insurances[0].insured_sum);
                $('#offer-1').find('.monthly-rate').prepend(data.insurances[0].monthly_rate);
                $('#offer-1').find('.yearly-rate').prepend(data.insurances[0].yearly_rate);
                $('#offer-2').fadeOut('fast');
                $('#offer-3').fadeOut('fast');
            }
            else if (data.insurances.length === 2) {
                $('#offer-1').removeClass().addClass('col-md-4 col-sm-6 col-md-offset-2').html($('#offer-' + data.insurances[0].slug).html()).fadeIn('slow');
                $('#offer-1').find('#offer-price').prepend(data.insurances[0].insured_sum);
                $('#offer-1').find('.monthly-rate').prepend(data.insurances[0].monthly_rate);
                $('#offer-1').find('.yearly-rate').prepend(data.insurances[0].yearly_rate);
                $('#offer-2').removeClass().addClass('col-md-4 col-sm-6').html($('#offer-' + data.insurances[1].slug).html()).fadeIn('slow');
                $('#offer-2').find('#offer-price').prepend(data.insurances[1].insured_sum);
                $('#offer-2').find('.monthly-rate').prepend(data.insurances[1].monthly_rate);
                $('#offer-2').find('.yearly-rate').prepend(data.insurances[1].yearly_rate);
                $('#offer-3').fadeOut('fast');
            }
            else if (data.insurances.length === 3) {
                $('#offer-1').removeClass().addClass('col-md-4 col-sm-6 ').html($('#offer-' + data.insurances[0].slug).html()).fadeIn('slow');
                $('#offer-1').find('#offer-price').prepend(data.insurances[0].insured_sum);
                $('#offer-1').find('.monthly-rate').prepend(data.insurances[0].monthly_rate);
                $('#offer-1').find('.yearly-rate').prepend(data.insurances[0].yearly_rate);
                $('#offer-2').removeClass().addClass('col-md-4 col-sm-6').html($('#offer-' + data.insurances[1].slug).html()).fadeIn('slow');
                $('#offer-2').find('#offer-price').prepend(data.insurances[1].insured_sum);
                $('#offer-2').find('.monthly-rate').prepend(data.insurances[1].monthly_rate);
                $('#offer-2').find('.yearly-rate').prepend(data.insurances[1].yearly_rate);
                $('#offer-3').removeClass().addClass('col-md-4 col-sm-6').html($('#offer-' + data.insurances[2].slug).html()).fadeIn('slow');
                $('#offer-3').find('#offer-price').prepend(data.insurances[2].insured_sum);
                $('#offer-3').find('.monthly-rate').prepend(data.insurances[2].monthly_rate);
                $('#offer-3').find('.yearly-rate').prepend(data.insurances[2].yearly_rate);
            }
            $('#do-i-need-insurance-wrapper').fadeOut('slow');
            $('#offer-boxes-wrapper').fadeIn('slow');
            $('.lead-button').unbind().on('click', function (e) {
                e.preventDefault(e);
                $('input[name="selected-offer"]').val($(this).data('offer'));
                setTimeout(function () {
                    self.toggleLeadBox('show');
                }, 500);

            })
        }
        else {
            $('#offer-1').fadeOut('slow');
            $('#offer-2').fadeOut('slow');
            $('#offer-3').fadeOut('slow');
            $('#offer-boxes-wrapper').fadeOut('slow');
            $('#do-i-need-insurance-wrapper').fadeIn('slow');
        }
    }

    this.toggleLeadBox = function (attribute) {
        if (attribute === 'show') {
            // serialize data
            var formData = $('#kzo-form').serialize();
            // set ajax url and make ajax call
            var ajaxURL = URL + "/ajax/kzo/lead-processing";
            var $_token = $("input[name=_token]").val();
            $.ajax({
                type: "POST",
                data: formData,
                dataType: 'json',
                url: ajaxURL,
                encode: true,
                headers: {'X-XSRF-TOKEN': $_token},
                success: function (data) {
                    var offer = '';
                    $.each(data.lead_data, function (index, question) {
                        if (question !== null) {
                            offer += question + '<br/>';
                        }
                    })
                    $('input[name="lead_comment"]').val(offer);

                }
            });
            $('#offer-info').fadeOut('slow', function () {
                $('#lead-box').fadeIn('slow');
                // scroll to upper section
                smoothScroll.animateScroll(null, '#lead-box', {"offset": 50});
            })
        }
        else if (attribute === 'hide') {
            $('#lead-box').fadeOut('fast');
        }
    }

    this.toggleInsuranceHoldingAnimation = function () {
        if (self.calculated === true) {
            if (self.gender === 'm') {
                man_clothes.css('opacity', '0');
                man_insurance.css('opacity', '1');
            }
            else if (self.gender === 'f') {
                woman_clothes.css('opacity', '0');
                woman_insurance.css('opacity', '1');
            }
        }
        else {
            if (self.gender === 'm') {
                man_clothes.css('opacity', '1');
                man_insurance.css('opacity', '0');
            }
            else if (self.gender === 'f') {
                woman_clothes.css('opacity', '1');
                woman_insurance.css('opacity', '0');
            }
        }
    }


    // set animation position to (fixed / absolute / relative) based on scroll value
    this.animationPositionPhone = function ($item) {
        $($item).waypoint(function (direction) {
            if ($(document).width() < 800) {
                if (self.calculated === true) {
                    $(' .life-insurance-calc').css({'position': 'relative', 'bottom': '0', 'height': 'auto'});
                }
                else if (direction === 'down') {
                    $(' .life-insurance-calc').css({'position': 'absolute', 'bottom': '0', 'height': 'auto'});
                }
                else if (direction === 'up') {
                    $(' .life-insurance-calc').css({'position': 'fixed', 'bottom': '0'});
                }
            }
        }, {offset: '100%'});
    }

    // set animation position to (fixed / absolute / relative) based on scroll value
    this.triggerAnimationsPhone = function ($item) {
        $($item).waypoint(function (direction) {
            var step = $(this.element).find('.life-insurance-step-num').data('step');
            if ($(this.element).hasClass('linked-step')) {
                var linked_step = $(this.element).data('linked-step');
            }
            if ($(document).width() < 800 && self.calculated !== true) {
                if (direction === 'down') {
                    if (self.step > step) {
                        return false;
                    }
                    if (linked_step && linked_step == step - 1) {
                        self.switchStep(step + 1)
                    }
                    else {
                        self.switchStep(step)
                    }
                }
                else if (direction === 'up') {
                    self.switchStep(step)
                }
            }
        }, {offset: '50%'});
    }

    // set animation wrapper min height and position
    this.animationWrapperSize = function () {
        if (self.calculated === true) {
            if ($(document).width() <= 800) {
                $('.life-insurance-calc-title').slideUp();
                $(' .life-insurance-calc').css({'position': 'relative', 'bottom': '0', 'height': 'auto'});
                // scroll to upper section
                smoothScroll.animateScroll(null, '#top');
            }
            else if ($(document).width() > 800) {
                $('.life-insurance-calc-title').slideUp();
                $('.life-insurance-calc').css('min-height', '480px');
            }
        }
        else {
            $('.life-insurance-calc-title').slideDown('fast');
            $('.life-insurance-calc').css('min-height', '510px');
        }
    }

    $(window).scroll(function () {
        self.animationPositionPhone($('.lower-section'));
    })


}
