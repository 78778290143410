function HOME() {
  // declarations
  var self = this;
  this.object_types = [];
  //get tommorow's date
  var date_tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  // set default datepicker date to next day
  $("#date_from").datepicker("setDate", date_tomorrow);
  // add one year to current datepicker date
  date_tomorrow.setFullYear(date_tomorrow.getFullYear() + 1);
  // set default insurance ending date one year after start date
  $("#date_to").datepicker("setDate", date_tomorrow);

  // listeners
  this.listeners = function () {
    // on insurance starting date add one year and fill ending date
    $("#date_from").on("changeDate", function (e) {
      var date_to = e.date.setFullYear(e.date.getFullYear() + 1);
      $("#date_to").datepicker("setDate", new Date(e.date));
    });

    $("#home_supplementary_accident_insurance_checkbox").on(
      "change",
      function () {
        // check if supplementary accident insurance checkbox is checked
        if (
          $("#home_supplementary_accident_insurance_checkbox").is(":checked")
        ) {
          // show supplementary accident insurance form
          $("#supplementary_accident_insurance-form").slideDown("slow");
        } else {
          // hide supplementary accident insurance form
          $("#supplementary_accident_insurance-form").slideUp("slow");
        }
      }
    );

    // on choose package button click
    $(".choose").on("click", function (e) {
      // prevent default href action
      e.preventDefault();
      // replace all blue buttons with green and replace text
      $(".choose")
        .removeClass("primary_color")
        .addClass("green")
        .html(window.translations.choose);
      // replace green button with blue and change text (for selected package)
      $(this)
        .removeClass("green")
        .addClass("primary-color")
        .html(window.translations.chosen);
      // set hidden input value to package name
      $("#home_selected_package").val($(this).data("package"));

      //show rest of the form
      $("#package-selected-section").slideDown("slow", function () {
        // scroll to the shown form
        smoothScroll.animateScroll(null, "#package-selected-section", {
          offset: 50,
        });
        $("#invoice_submit_button").show();
      });
    });

    // on object type or object size change
    $("#object-type,#home_quadrature").on("change", function () {
      // replace all blue buttons with green and replace text
      $(".choose")
        .removeClass("primary_color")
        .addClass("green")
        .html(window.translations.choose);
      // empty hidden input value
      $("#home_selected_package").val("");
    });

    $(
      'input:radio[name="contractor_insured"], #contractor-details-form input'
    ).on("change", function () {
      if (
        $('input:radio[name="contractor_insured"]').is(":checked") &&
        $('input:radio[name="contractor_insured"]:checked').val() == "yes"
      ) {
        $("#insured-details-form").slideUp("slow");
      } else if (
        $('input:radio[name="contractor_insured"]').is(":checked") &&
        $('input:radio[name="contractor_insured"]:checked').val() == "no"
      ) {
        $("#insured-details-form").slideDown("slow");
      }
    });

    $('input:radio[name="contractor_foreigner"]').on("change", function () {
      if (
        $('input:radio[name="contractor_foreigner"]').is(":checked") &&
        $('input:radio[name="contractor_foreigner"]:checked').val() == "no"
      ) {
        $("#contractor_identification_number_wrapper").slideDown("slow");
        $("#contractor_identification_number")
          .prop("disabled", false)
          .trigger("blur");
        $("#contractor_gender_wrapper").slideUp("slow");
        $("#contractor_birthdate_wrapper").slideUp("slow");
      } else if (
        $('input:radio[name="contractor_foreigner"]').is(":checked") &&
        $('input:radio[name="contractor_foreigner"]:checked').val() == "yes"
      ) {
        $("#contractor_identification_number_wrapper").slideUp("true");
        $("#contractor_identification_number").prop("disabled", true);
        $("#contractor_gender_wrapper").slideDown("slow");
        $("#contractor_birthdate_wrapper").slideDown("slow");
      }
      self.lockBirthdate();
    });

    $('input:radio[name="insured_foreigner"]').on("change", function () {
      if (
        $('input:radio[name="insured_foreigner"]').is(":checked") &&
        $('input:radio[name="insured_foreigner"]:checked').val() == "no"
      ) {
        $("#insured_identification_number_wrapper").slideDown("slow");
        $("#insured_identification_number")
          .prop("disabled", false)
          .trigger("blur");
        $("#insured_gender_wrapper").slideUp("slow");
        $("#insured_birthdate_wrapper").slideUp("slow");
      } else if (
        $('input:radio[name="insured_foreigner"]').is(":checked") &&
        $('input:radio[name="insured_foreigner"]:checked').val() == "yes"
      ) {
        $("#insured_identification_number_wrapper").slideUp("slow");
        $("#insured_identification_number").prop("disabled", true);
        $("#insured_gender_wrapper").slideDown("slow");
        $("#insured_birthdate_wrapper").slideDown("slow");
      }
      self.lockBirthdate;
    });

    // catch object type changed
    $(document).on("change", '*[data-validate="true"]', function () {
      // set new object type value
      self.invoice = false;
      self.object_id = $("#object-type").val();
      // set new quadrature value
      self.quadrature = $("#home_quadrature").val();
      // get policy types for new object id
      if (self.quadrature === "" && !self.quadrature > 0) {
        $("#home-package-selector").slideUp("slow");
      }
      if (self.quadrature >= 100) {
        $("#lead-box-wrapper").css("display", "block");
      } else {
        $("#lead-box-wrapper").css("display", "none");
      }
      self.input = $(this);
      self.calculateAndValidate();
      self.hideAptNumber();
    });

    // catch object type changed
    $(document).on("blur", '*[data-validate-field="true"]', function () {
      // check if disabled
      if ($(this).hasClass("disabled")) {
        return false;
      }
      // set new object type value
      if (
        $(this).hasClass("datepicker") ||
        $(this).hasClass("datepicker-birthdate")
      ) {
        var ref = this;
        setTimeout(function () {
          self.validateField(ref, "text");
        }, 600);
      } else {
        // otherwise validate simple text input
        self.validateField(this, "text");
      }
      self.lockBirthdate();
    });
    // ajax single field validation
    $(document).on("change", 'select[data-validate-field="true"]', function () {
      self.validateField(this, "dropdown");
    });

    // catch same living address as location address change
    $(
      "#contractor_location_living_address, #insured_location_living_address"
    ).on("change", function () {
      // call check method
      self.hideAddresses();
    });

    $("#sai_insured_sum_death").on("change", function () {
      $("#sai_insured_sum_disability")
        .val($("#sai_insured_sum_death").val())
        .trigger("change");
    });

    $("#home-see-allowances").on("click", function () {
      $("#insured_sums").slideToggle("slow");
    });

    $(
      "#home_supplementary_accident_insurance_checkbox, #sai_number_of_persons"
    ).on("change", function () {
      $("#supplementary-accident-insurance-insured-form").slideUp(
        "slow",
        function () {
          $("#sai_insured_container").html("");
          if (
            $("#home_supplementary_accident_insurance_checkbox").is(":checked")
          ) {
            $("#sai_insured_sum_death").prop("disabled", false);
            $("#sai_number_of_persons").prop("disabled", false);
            $("#supplementary-accident-insurance-insured-form").slideDown(
              "slow",
              function () {
                for (var i = 1; i <= $("#sai_number_of_persons").val(); i++) {
                  var sai_insured_form =
                    '<div class="item animated zoomIn">' +
                    '<div class="form-group">' +
                    '<div class="col-sm-4">' +
                    '<label for="sai_insured_fname_' +
                    i +
                    '" class="col-md-12 control-label">' +
                    window.translations.fname +
                    "</label>" +
                    '<div class="col-md-10 col-xs-10 control">' +
                    '<input type="text" name="sai_insured_fname_' +
                    i +
                    '" class="form-control input-text" data-validate-field="true"/>' +
                    "</div>" +
                    '<div class="col-md-2 col-xs-2 status"><i class="size35 sprite"></i></div>' +
                    '<div class="col-md-12 col-xs-12 errors"></div>' +
                    "</div>" +
                    '<div class="col-sm-4">' +
                    '<label for="sai_insured_lname_' +
                    i +
                    '" class="col-md-12 control-label">' +
                    window.translations.lname +
                    "</label>" +
                    '<div class="col-md-10 col-xs-10 control">' +
                    '<input type="text" name="sai_insured_lname_' +
                    i +
                    '" class="form-control input-text"  data-validate-field="true"/>' +
                    "</div>" +
                    '<div class="col-md-2 col-xs-2 status"><i class="size35 sprite"></i></div>' +
                    '<div class="col-md-12 col-xs-12 errors"></div>' +
                    "</div>" +
                    '<div class="col-sm-4">' +
                    '<label for="insured_identification_number_' +
                    i +
                    '" class="col-md-12 control-label">' +
                    window.translations.identification_number +
                    "</label>" +
                    '<div class="col-md-10 col-xs-10 control">' +
                    '<input type="text" name="sai_insured_identification_number_' +
                    i +
                    '" class="form-control input-text"  data-validate-field="true"/>' +
                    "</div>" +
                    '<div class="col-md-2 col-xs-2 status"><i class="size35 sprite"></i></div>' +
                    '<div class="col-md-12 col-xs-12 errors"></div>' +
                    "</div>" +
                    "</div>" +
                    "</div>";
                  $("#sai_insured_container").append(sai_insured_form);
                }
              }
            );
          } else {
            $("#supplementary-accident-insurance-insured-form").slideUp("slow");
            $("#sai_insured_sum_death").prop("disabled", true);
            $("#sai_number_of_persons").prop("disabled", true);
          }
        }
      );
    });

    $("#invoice_submit_button").on("click", function (e) {
      // prevent default button action
      e.preventDefault();
      $("#form-status").val("1");
      self.invoice = true;
      $("#home-form-errors").html("");
      self.calculateAndValidate();
    });

    $("#invoice_back_button").on("click", function (e) {
      // prevent default button action
      e.preventDefault();
      $("#form-status").val("0");
      self.invoice = false;
      $("#home-form-errors").html("");
      $("#summary-section").slideUp("slow", function () {
        $(
          "#insured_sums,#invoice,#invoice_back_button,.accepted-payment-img"
        ).hide();
        $(".form-payment").addClass("hide");
        $(".payment-message").addClass("hide");

        $("#invoice_submit_button").show();
        $("#forms-section").slideDown("slow");
      });
    });

    $(".form-payment").on("click", function (e) {
      // prevent default button action
      e.preventDefault();
      if ($(this).hasClass("disabled")) {
        return;
      }
      $("#form-status").val("2");
      self.invoice = false;
      $("#home-form-errors").html("");
      // check recaptcha
      if (
        !TCApp.InvisibleRecaptcha.verify(
          self.calculateAndValidate($(this).attr("data-payment-slug"))
        )
      ) {
        console.log("failed verify");
        // enable submits
        $(this).prop("disabled", false);
        // trigger validation
        window.grecaptcha.execute(TCApp.InvisibleRecaptcha.recaptchaID);
        return;
      } else {
        // ajax call
        self.submitLead();
      }
    });

    // $('#unlimited_duration').on('change', function () {
    //     self.calculateAndValidate();
    //     $('#invoice').slideUp('slow', function () {
    //         $('#invoice').delay(800).slideDown();
    //     });
    //
    //
    // })
    //
    // $('#unlimited_duration_button').on('click', function (e) {
    //     e.preventDefault();
    //     if ($('#unlimited_duration').is(':checked')) {
    //         $('#unlimited_duration').prop('checked', false).trigger('change');
    //         $('#unlimited_duration_button').html(window.translations.unlimited_duration_button_yes)
    //     }
    //     else {
    //         $('#unlimited_duration').prop('checked', true).trigger('change');
    //         $('#unlimited_duration_button').html(window.translations.unlimited_duration_button_no)
    //     }
    // })
  };

  // get policy types structure by current object id
  this.calculateAndValidate = function (paymentSlug) {
    var formData = new FormData($("#home_shop_form")[0]);
    formData.append("g_recaptcha_token", TCApp.InvisibleRecaptcha.token);
    formData.append("payment_slug", paymentSlug);
    if ($("#form-status").val() === "0") {
      formData.delete("location_front_door_pic");
      formData.delete("location_living_room_pic");
      formData.delete("location_kitchen_pic");
      formData.delete("location_bathroom_pic");
    }
    // set ajax url and make ajax call
    var ajaxURL = URL + "/ajax/home/policy-plans";
    var $_token = $("input[name=_token]").val();
    self.lockNext();
    $.ajax({
      type: "POST",
      data: formData,
      dataType: "json",
      url: ajaxURL,
      encode: true,
      contentType: false,
      processData: false,
      headers: {
        "X-XSRF-TOKEN": $_token,
      },
      success: function (data) {
        self.unlockNext();

          if (true === data.unexpected_error) {
             let consentCheckbox = document.getElementById("terms-conditions");
             consentCheckbox.classList.add("cb-prihvatite-uslove-up");
             document.getElementById("terms-conditions-message").style.color =
               "#005ca9";

             document.getElementById(
               "terms-conditions-message"
             ).style.fontWeight = "bold";

             setTimeout(function () {
               consentCheckbox.classList.remove("cb-prihvatite-uslove-up");
               consentCheckbox.classList.add("cb-prihvatite-uslove-down");
               setTimeout(function () {
                 consentCheckbox.classList.remove("cb-prihvatite-uslove-down");
                 document.getElementById(
                   "terms-conditions-message"
                 ).style.color = "#666";

                 document.getElementById(
                   "terms-conditions-message"
                 ).style.fontWeight = "bold";
               }, 200);
             }, 500);
          $("#home-form-errors").html(data.errors.unexpected_error);
          return false;
        }
        if (null != data.redirect_url) {
          window.location.href = data.redirect_url;
        }
        if (
          self.quadrature !== "" &&
          self.quadrature > 0 &&
          typeof data.policy_plans !== "undefined"
        ) {
          // fill home basic box
          $("#home-basic #package-description").html(
            data.policy_plans.content["home-basic"].description
          );
          $("#home-basic #content-inner").html(
            data.policy_plans.content["home-basic"].content
          );
          $("#home-basic #package-price").html(
            data.policy_plans.total_formatted["home-basic"]
          );
          $("#home-basic #old-package-price").html(
            data.policy_plans.total_formatted_prediscount["home-basic"]
          );
          $("#home-basic #package-button")
            .data(
              "package",
              data.policy_plans.content["home-basic"].policy_plan
            )
            .trigger("change");

          // fill home standard box
          $("#home-standard #package-description").html(
            data.policy_plans.content["home-standard"].description
          );
          $("#home-standard #content-inner").html(
            data.policy_plans.content["home-standard"].content
          );
          $("#home-standard #package-price").html(
            data.policy_plans.total_formatted["home-standard"]
          );
          $("#home-standard #old-package-price").html(
            data.policy_plans.total_formatted_prediscount["home-standard"]
          );
          $("#home-standard #package-button")
            .data(
              "package",
              data.policy_plans.content["home-standard"].policy_plan
            )
            .trigger("change");

          // fill home exclusive box
          $("#home-exclusive #package-description").html(
            data.policy_plans.content["home-exclusive"].description
          );
          $("#home-exclusive #content-inner").html(
            data.policy_plans.content["home-exclusive"].content
          );
          $("#home-exclusive #package-price").html(
            data.policy_plans.total_formatted["home-exclusive"]
          );
          $("#home-exclusive #old-package-price").html(
            data.policy_plans.total_formatted_prediscount["home-exclusive"]
          );
          $("#home-exclusive #package-button")
            .data(
              "package",
              data.policy_plans.content["home-exclusive"].policy_plan
            )
            .trigger("change");

          $(
            "#home_supplementary_accident_insurance_checkbox_wrapper"
          ).slideDown("fast");

          $("#lead-box-wrapper").slideUp("slow", function () {
            if (!data.sai_plans) {
              $("#home-package-selector").slideDown("slow", function () {
                smoothScroll.animateScroll(null, "#home-package-selector", {
                  offset: 50,
                });
              });
            }
          });
        }
        if (typeof data.errors.home_quadrature !== "undefined") {
          $("#home-package-selector").slideUp("slow", function () {
            $("#home_selected_package").val("");
            $(".choose")
              .removeClass("primary_color")
              .addClass("green")
              .html("Kupi online");
            $(
              "#home_supplementary_accident_insurance_checkbox_wrapper"
            ).slideUp("fast");
            $("#package-selected-section").slidUp("fast");
            $("#lead-box-wrapper").slideDown("slow");
          });
        }
        if ($("#home_quadrature").val() >= 100) {
          $("#lead-box-wrapper").css("display", "block");
        } else {
          $("#lead-box-wrapper").css("display", "none");
        }
        //supplementary accident insurance
        if (typeof data.sai_plans != "undefined") {
          $("#sai_premium").html(data.sai_plans.total_formatted);
        }

        if (self.invoice !== true) {
          if (data.success == false) {
            $.each(data.errors, function (index, error) {
              if (self.input.attr("name") != index) {
                if (self.input.data("image") === "yes") {
                  $(self.input)
                    .parent()
                    .parent()
                    .parent()
                    .siblings(".status")
                    .children("i")
                    .removeClass("error")
                    .addClass("ok");
                  $(self.input)
                    .removeClass("has-error")
                    .addClass("has-success");
                  $(self.input)
                    .parent()
                    .parent()
                    .parent()
                    .siblings(".errors")
                    .html("");
                } else {
                  $(self.input)
                    .parent()
                    .siblings(".status")
                    .children("i")
                    .removeClass("error")
                    .addClass("ok");
                  $(self.input)
                    .removeClass("has-error")
                    .addClass("has-success");
                  $(self.input).parent().siblings(".errors").html("");
                }
              } else {
                if (self.input.data("image") === "yes") {
                  $(self.input)
                    .parent()
                    .parent()
                    .parent()
                    .siblings(".status")
                    .children("i")
                    .removeClass("ok")
                    .addClass("error");
                  $(self.input)
                    .removeClass("has-success")
                    .addClass("has-error");
                  $(self.input)
                    .parent()
                    .parent()
                    .parent()
                    .siblings(".errors")
                    .html(error);
                  return false;
                } else {
                  $(self.input)
                    .parent()
                    .siblings(".status")
                    .children("i")
                    .removeClass("ok")
                    .addClass("error");
                  $(self.input)
                    .removeClass("has-success")
                    .addClass("has-error");
                  $(self.input).parent().siblings(".errors").html(error);
                  return false;
                }
              }
            });
            if (self.input.attr("name") == "object_type") {
              if (typeof data.errors.home_quadrature === "undefined") {
                $("#home_quadrature")
                  .parent()
                  .siblings(".status")
                  .children("i")
                  .removeClass("error")
                  .addClass("ok");
                $("#home_quadrature")
                  .removeClass("has-error")
                  .addClass("has-success");
                $("#home_quadrature").parent().siblings(".errors").html("");
              } else {
                $("#home_quadrature")
                  .parent()
                  .siblings(".status")
                  .children("i")
                  .removeClass("ok")
                  .addClass("error");
                $("#home_quadrature")
                  .removeClass("has-success")
                  .addClass("has-error");
                $("#home_quadrature")
                  .parent()
                  .siblings(".errors")
                  .html(data.errors.home_quadrature);
              }
            }
          } else {
            $("*[data-validate=true]").each(function (id, selector) {
              $(this)
                .parent()
                .siblings(".status")
                .children("i")
                .removeClass("error")
                .addClass("ok");
              $(this).removeClass("has-error").addClass("has-success");
              $(this).parent().siblings(".errors").html("");
            });
          }
        } else if (self.invoice === true) {
          if (data.success == false) {
            var $counter = 0;
            $("*[data-validate=true],*[data-validate-field=true]").each(
              function (id, selector) {
                $.each(data.errors, function (index, error) {
                  if ($counter === 0) {
                    // console.log('input[name=' + index + ']');
                    smoothScroll.animateScroll(null, "#" + index, {
                      offset: 50,
                    });
                  }
                  $counter++;
                  if ($(selector).attr("name") != index) {
                    if ($(selector).data("image") === "yes") {
                      $(selector)
                        .parent()
                        .parent()
                        .parent()
                        .siblings(".status")
                        .children("i")
                        .removeClass("error")
                        .addClass("ok");
                      $(selector)
                        .removeClass("has-error")
                        .addClass("has-success");
                      $(selector)
                        .parent()
                        .parent()
                        .parent()
                        .siblings(".errors")
                        .html("");
                    } else {
                      $(selector)
                        .parent()
                        .siblings(".status")
                        .children("i")
                        .removeClass("error")
                        .addClass("ok");
                      $(selector)
                        .removeClass("has-error")
                        .addClass("has-success");
                      $(selector).parent().siblings(".errors").html("");
                    }
                  } else {
                    if ($(selector).data("image") === "yes") {
                      $(selector)
                        .parent()
                        .parent()
                        .parent()
                        .siblings(".status")
                        .children("i")
                        .removeClass("ok")
                        .addClass("error");
                      $(selector)
                        .removeClass("has-success")
                        .addClass("has-error");
                      $(selector)
                        .parent()
                        .parent()
                        .parent()
                        .siblings(".errors")
                        .html(error);
                      return false;
                    } else {
                      $(selector)
                        .parent()
                        .siblings(".status")
                        .children("i")
                        .removeClass("ok")
                        .addClass("error");
                      $(selector)
                        .removeClass("has-success")
                        .addClass("has-error");
                      $(selector).parent().siblings(".errors").html(error);
                      return false;
                    }
                  }
                });
              }
            );
          } else {
            $("#sums_table").html("");
            $("#summary-sui-insured").html("");
            $("#forms-section").slideUp("slow", function () {
              $("#invoice_back_button").show();
              $(".form-payment").removeClass("hide");
              $(".payment-message").removeClass("hide");

              $("#invoice_submit_button").hide();
              $(".accepted-payment-img").show();

              $("#review-name").html(
                data.summary.contractor.first_name +
                  " " +
                  data.summary.contractor.last_name
              );
              $("#review-birthdate").html(data.summary.contractor.birthdate);
              $("#review-jmbg").html(data.summary.contractor.jmbg);
              $("#review-phone").html(data.summary.contractor.phone);
              $("#review-address").html(
                data.summary.contractor.address +
                  " " +
                  data.summary.contractor.street_number
              );
              $("#review-city").html(data.summary.contractor.city);
              $("#review-district").html(data.summary.contractor.district);
              $("#review-date-from").html(data.summary.policy.begin_date);
              $("#review-date-to").html(data.summary.policy.end_date);
              if (
                $("#home_supplementary_accident_insurance_checkbox").is(
                  ":checked"
                )
              ) {
                $.each(data.summary.supplementary_insured, function () {
                  var sui_insured =
                    '<div class="item">' +
                    '<div class="form-group">' +
                    '<label class="col-md-2 control-label">' +
                    window.translations.fname +
                    "</label>" +
                    '<div class="col-md-2 control">' +
                    '<div class="form-control input-text uppercase">' +
                    this.first_name +
                    "</div>" +
                    "</div>" +
                    '<label class="col-md-2 control-label">' +
                    window.translations.lname +
                    "</label>" +
                    '<div class="col-md-2 control">' +
                    '<div class="form-control input-text uppercase">' +
                    this.last_name +
                    "</div>" +
                    "</div>" +
                    '<label class="col-md-2 control-label">' +
                    window.translations.identification_number +
                    "</label>" +
                    '<div class="col-md-2 control">' +
                    '<div class="form-control input-text uppercase">' +
                    this.jmbg +
                    "</div>" +
                    "</div>" +
                    "</div>";
                  $("#summary-sui-insured").append(sui_insured);
                });
              }

              $("#summary-section").slideDown("slow");
            });
            $.each(data.invoice.allowances, function (index, value) {
              var policy_plan = $("#home_selected_package").val();
              policy_plan = policy_plan.substring(
                0,
                policy_plan.lastIndexOf("-")
              );
              policy_plan = policy_plan.replace("-", " ");
              policy_plan = capWords(policy_plan);
              var object_type = $("#object-type :selected").text();
              index = index.replace(/-/g, "_");
              if (index == "home_object") {
                $("#sums_table").append(
                  '<div class="item">' +
                    '<label class="col-md-7 control-label">' +
                    window.translations[index] +
                    " " +
                    policy_plan +
                    " " +
                    object_type +
                    " površine " +
                    self.quadrature +
                    "m2</label>" +
                    '<div class="col-md-2 text-right text-left-small-device">' +
                    "<h5>" +
                    value +
                    "</h5>" +
                    "</div>" +
                    "</div>"
                );
              } else {
                $("#sums_table").append(
                  '<div class="item">' +
                    '<label class="col-md-7 control-label">' +
                    window.translations[index] +
                    "</label>" +
                    '<div class="col-md-2 text-right text-left-small-device">' +
                    "<h5>" +
                    value +
                    "</h5>" +
                    "</div>" +
                    "</div>"
                );
              }
            });
            $("#discounts").html("");
            $("#total-premium").html(data.invoice.base_total);
            $("#sai_premium_invoice").html(data.invoice.sai_total);
            $("#total-premium-discounted").html(data.invoice.total_discounted);
            $("#total-premium-sai").html(data.invoice.total);
            $.each(data.invoice.discounts, function (index, value) {
              index = index.replace(/-/g, "_");
              $("#discounts").append(
                '<div class="item">' +
                  '<label class="col-md-7 control-label" style="color:green;">' +
                  window.translations[index] +
                  "</label>" +
                  '<div class="col-md-2 text-right text-left-small-device" style="color:green;">' +
                  "<h5>-" +
                  value +
                  "</h5>" +
                  "</div>" +
                  "</div>"
              );
            });
            $("#invoice").slideDown();
          }
        }
      },
    });
  };

  // validate single field
  this.validateField = function (ref, type) {
    // inits
    var name = null;
    var value = null;
    // determine type and get name and value
    if ("text" == type || "dropdown" == type) {
      // get values
      name = $(ref).attr("name");
      value = $(ref).val();
    }
    // call ajax method
    // set ajax url and make ajax call
    var ajaxURL = URL + "/ajax/home/validate-field";
    var $_token = $("input[name=_token]").val();
    $.ajax({
      type: "POST",
      data: {
        name: name,
        value: value,
        _token: $_token,
      },
      dataType: "json",
      url: ajaxURL,
      encode: true,
      headers: { "X-XSRF-TOKEN": $_token },
      success: function (data) {
        if (true === data.unexpected_error) {
          $("#home-form-errors").html(data.errors.unexpected_error);
          return false;
        }
        if (null != data.redirect_url) {
          window.location.href = data.redirect_url;
        }
        // if success
        if (data.success === true) {
          // console.log(data)
          // show success
          self.statusSuccess(data.field_name);
          // check helper
          if (null != data.helper) {
            // check helper type
            if ("date" === data.helper.type) {
              $("input[name=" + data.helper.key + "]")
                .datepicker("setDate", data.helper.value)
                .trigger("blur");
            } else {
              $("input[name=" + data.helper.key + "]")
                .val(data.helper.value)
                .trigger("blur");
            }
          }
        } else {
          // console.log(data)
          // console.log(data.error)
          // set error sign and message
          // show error
          self.statusError(data.field_name, data.error);
        }
      },
    });
  };

  // status success
  this.statusSuccess = function (id) {
    // set status to success for passed id
    if ($("*[name=" + id + "]").data("image") === "yes") {
      $("*[name=" + id + "]")
        .parent()
        .parent()
        .parent()
        .siblings(".status")
        .children("i")
        .removeClass("error")
        .addClass("ok");
      $("*[name=" + id + "]")
        .removeClass("has-error")
        .addClass("has-success");
      $("*[name=" + id + "]")
        .parent()
        .parent()
        .parent()
        .siblings(".errors")
        .html("");
    } else {
      $("*[name=" + id + "]")
        .parent()
        .siblings(".status")
        .children("i")
        .removeClass("error")
        .addClass("ok");
      $("*[name=" + id + "]")
        .removeClass("has-error")
        .addClass("has-success");
      $("*[name=" + id + "]")
        .parent()
        .siblings(".errors")
        .html("");
    }
  };
  // status error
  this.statusError = function (id, message) {
    // set status to error for passed id
    if ($("*[name=" + id + "]").data("image") === "yes") {
      $("*[name=" + id + "]")
        .parent()
        .parent()
        .parent()
        .siblings(".status")
        .children("i")
        .removeClass("ok")
        .addClass("error");
      $("*[name=" + id + "]")
        .removeClass("has-success")
        .addClass("has-error");
      $("*[name=" + id + "]")
        .parent()
        .parent()
        .parent()
        .siblings(".errors")
        .html(message);
    } else {
      $("*[name=" + id + "]")
        .parent()
        .siblings(".status")
        .children("i")
        .removeClass("ok")
        .addClass("error");
      $("*[name=" + id + "]")
        .removeClass("has-success")
        .addClass("has-error");
      $("*[name=" + id + "]")
        .parent()
        .siblings(".errors")
        .html(message);
    }
  };

  function capWords(str) {
    var words = str.split(" ");
    var capitalized = "";
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      var firstLetter = word.substr(0, 1);
      var rest = word.substr(1, word.length - 1);
      capitalized += firstLetter.toUpperCase() + rest + " ";
    }
    return capitalized;
  }

  // unlock next step button
  this.unlockNext = function () {
    $(".form-next-loader").addClass("hide").prop("disabled", true);
  };
  // lock next step button
  this.lockNext = function () {
    $(".form-next-loader").removeClass("hide").prop("disabled", false);
  };

  // lock birthdate if domestic user and jmbg set
  this.lockBirthdate = function () {
    // get handles
    var $contractorCitizenship = $("#contractor_foreigner:checked").val();
    var $insuredCitizenship = $("#insured_foreigner:checked").val();
    var $contractorJMBG = $("#contractor_identification_number");
    var $contractorBirthdate = $("#contractor_date_of_birth");
    var $insuredJMBG = $("#insured_identification_number");
    var $insuredBirthdate = $("#insured_date_of_birth");
    // change form
    if ("yes" === $contractorCitizenship) {
      $contractorBirthdate.removeClass("disabled");
      $contractorBirthdate.prop("readonly", false);
    } else {
      if ($contractorJMBG.val().length === 13) {
        $contractorBirthdate.addClass("disabled");
        $contractorBirthdate.prop("readonly", true);
      } else {
        $contractorBirthdate.removeClass("disabled");
        $contractorBirthdate.prop("readonly", false);
      }
    }
    if ("yes" === $insuredCitizenship) {
      $insuredBirthdate.removeClass("disabled");
      $insuredBirthdate.prop("readonly", false);
    } else {
      if ($insuredJMBG.val().length === 13) {
        $insuredBirthdate.addClass("disabled");
        $insuredBirthdate.prop("readonly", true);
      } else {
        $insuredBirthdate.removeClass("disabled");
        $insuredBirthdate.prop("readonly", false);
      }
    }
  };

  // hide location apt number if object type is house
  this.hideAptNumber = function () {
    // get handles
    var $object_type = $("#object-type").val();
    if ("7" === $object_type) {
      $("#location_floor_apt_wrapper").slideUp("slow");
    } else {
      $("#location_floor_apt_wrapper").slideDown("slow");
    }
  };

  // lock birthdate if domestic user and jmbg set
  this.hideAddresses = function () {
    // get handles
    var $contractorAddressWrapper = $("#contractor_address_fields_wrapper");
    var $insuredAddressWrapper = $("#insured_address_fields_wrapper");
    var $contractorAddressCheckbox = $(
      "#contractor_location_living_address:checked"
    ).val();
    var $insuredAddressCheckbox = $(
      "#insured_location_living_address:checked"
    ).val();
    // change form
    if ("yes" === $contractorAddressCheckbox) {
      $contractorAddressWrapper.slideUp("slow");
    } else {
      $contractorAddressWrapper.slideDown("slow");
    }
    if ("yes" === $insuredAddressCheckbox) {
      $insuredAddressWrapper.slideUp("slow");
    } else {
      $insuredAddressWrapper.slideDown("slow");
    }
  };
}
