function DZO() {
    // declarations
    var self = this;
    //get tommorow's date
    var date_tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    // set default datepicker date to next day
    $("#date_from").datepicker('setDate', date_tomorrow);
    // add one year to current datepicker date
    date_tomorrow.setFullYear(date_tomorrow.getFullYear() + 1);
    // set default insurance ending date one year after start date
    $("#date_to").datepicker('setDate', date_tomorrow);


    // listeners
    this.listeners = function () {

        // on insurance starting date add one year and fill ending date
        $("#date_from").on('changeDate', function (e) {
            var date_to = e.date.setFullYear(e.date.getFullYear() + 1);
            $("#date_to").datepicker('setDate', new Date(e.date));
            // on starting date change scroll down to package info
            smoothScroll.animateScroll(null, '#package-selector', {"offset": 50});
        });


        // on choose package button click
        $('.choose').on('click', function (e) {
            // prevent default href action
            e.preventDefault();
            // replace all blue buttons with green and replace text
            $('.choose').removeClass('primary_color').addClass('green').html(window.translations.choose);
            // replace green button with blue and change text (for selected package)
            $(this).removeClass('green').addClass('primary-color').html(window.translations.chosen);
            // set hidden input value to package name
            $('#dzo_selected_package').val($(this).data('package'));

            //show rest of the form
            $('#package-selected-section').slideDown('slow', function () {
                // scroll to the shown form
                smoothScroll.animateScroll(null, '#package-selected-section', {"offset": 50});
                $('#invoice_submit_button').show();

            });
        });

        // on object type or object size change
        $('#year_of_birth').on('change', function () {
            // replace all blue buttons with green and replace text
            $('.choose').removeClass('primary_color').addClass('green').html(window.translations.choose);
            // empty hidden input value
            $('#dzo_selected_package').val('');
        });

        $('input:radio[name="contractor_insured"], #contractor-details-form input').on('change', function () {
            if ($('input:radio[name="contractor_insured"]').is(':checked') && $('input:radio[name="contractor_insured"]:checked').val() == 'yes') {
                $('#insured-details-form').slideUp('slow');
            } else if ($('input:radio[name="contractor_insured"]').is(':checked') && $('input:radio[name="contractor_insured"]:checked').val() == 'no') {
                $('#insured-details-form').slideDown('slow');
            }
        });

        $('input:radio[name="contractor_foreigner"]').on('change', function () {
            if ($('input:radio[name="contractor_foreigner"]').is(':checked') && $('input:radio[name="contractor_foreigner"]:checked').val() == 'no') {
                $('#contractor_identification_number_wrapper').slideDown('slow');
                $('#contractor_identification_number').prop('disabled', false).trigger('blur');
                $('#contractor_gender_wrapper').slideUp('slow');
                $('#contractor_birthdate_wrapper').slideUp('slow');
            } else if ($('input:radio[name="contractor_foreigner"]').is(':checked') && $('input:radio[name="contractor_foreigner"]:checked').val() == 'yes') {
                $('#contractor_identification_number_wrapper').slideUp('true');
                $('#contractor_identification_number').prop('disabled', true);
                $('#contractor_gender_wrapper').slideDown('slow');
                $('#contractor_birthdate_wrapper').slideDown('slow');
            }
            self.lockBirthdate();
        });

        $('input:radio[name="insured_foreigner"]').on('change', function () {
            if ($('input:radio[name="insured_foreigner"]').is(':checked') && $('input:radio[name="insured_foreigner"]:checked').val() == 'no') {
                $('#insured_identification_number_wrapper').slideDown('slow');
                $('#insured_identification_number').prop('disabled', false).trigger('blur');
                $('#insured_gender_wrapper').slideUp('slow');
                $('#insured_birthdate_wrapper').slideUp('slow');
            } else if ($('input:radio[name="insured_foreigner"]').is(':checked') && $('input:radio[name="insured_foreigner"]:checked').val() == 'yes') {
                $('#insured_identification_number_wrapper').slideUp('slow');
                $('#insured_identification_number').prop('disabled', true);
                $('#insured_gender_wrapper').slideDown('slow');
                $('#insured_birthdate_wrapper').slideDown('slow');
            }
            self.lockBirthdate;
        });

        $('input:radio[name="disease"], #contractor-details-form input').on('change', function () {
            if ($('input:radio[name="disease"]').is(':checked') && $('input:radio[name="disease"]:checked').val() == 'yes') {
                $('.disease-error').show();
            } else if ($('input:radio[name="disease"]').is(':checked') && $('input:radio[name="disease"]:checked').val() == 'no') {
                $('.disease-error').hide();
            }
        });

        // catch change
        $(document).on('change', '*[data-validate="true"]', function () {
            // set new object type value
            self.invoice = false;
            self.disease = $('input:radio[name="disease"]:checked').val();
            // set new quadrature value
            self.year_of_birth = $('#year_of_birth').val();
            // get policy types for new object id
            if (self.year_of_birth === '' && !self.year_of_birth > 1900) {
                $('#dzo-package-selector').slideUp('slow');
            }
            self.input = $(this);
            self.processing();
        });


        // catch object type changed
        $(document).on('blur', '*[data-validate-field="true"]', function () {
            if ($(this).hasClass('datepicker') || $(this).hasClass('datepicker-birthdate')) {
                var ref = this;
                setTimeout(function () {
                    self.validateField(ref, 'text');
                }, 600);
            } else {
                // otherwise validate simple text input
                self.validateField(this, 'text');
            }
            self.lockBirthdate();

        });
        // ajax single field validation
        $(document).on('change', 'select[data-validate-field="true"]', function () {
            self.validateField(this, 'dropdown');
        });

        // catch same living address as contractor address change
        $('input:radio[name="insured_contractor_same_address"]').on('change', function () {
            // call check method
            self.hideAddresses();
        })


        $('#invoice_submit_button').on('click', function (e) {
            // prevent default button action
            e.preventDefault();
            $('#form-status').val('1');
            self.invoice = true;
            $('#dzo-form-errors').html('');
            self.processing();
        })

        $('#invoice_back_button').on('click', function (e) {
            // prevent default button action
            e.preventDefault();
            $('#form-status').val('0');
            self.invoice = false;
            $('#dzo-form-errors').html('');
            $('#summary-section').slideUp('slow', function () {
                $('#insured_sums,#invoice,#invoice_back_button,.accepted-payment-img').hide();
                $('.form-payment').addClass('hide');
                $('.payment-message').addClass('hide');

                $('#invoice_submit_button').show();
                $('#forms-section').slideDown('slow');

            })
        })

        $('.form-payment').on('click', function (e) {
            // prevent default button action
            e.preventDefault();
            if ($(this).hasClass('disabled')) {
                return;
            }
            $('#form-status').val('2');
            self.invoice = false;
            $('#dzo-form-errors').html('');
            // check recaptcha
            if (!TCApp.InvisibleRecaptcha.verify(self.processing($(this).attr('data-payment-slug')))) {
                console.log('failed verify');
                // enable submits
                $(this).prop('disabled', false);
                // trigger validation
                window.grecaptcha.execute(TCApp.InvisibleRecaptcha.recaptchaID);
                return;
            } else {
                // ajax call
                self.processing($(this).attr('data-payment-slug'));
            }
        })

        $('.choose').click(function (e) {
            e.preventDefault();
            var ajaxURL = URL + "/ajax/allowed-ip";
            var $_token = $("input[name=_token]").val();
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: ajaxURL,
                encode: true,
                headers: {'X-XSRF-TOKEN': $_token},
                success: function (data) {
                    // deactivate overlay screen
                    if (data.success == false) {
                        // redirect to error page
                        window.location.href = data.redirect_url;
                        return false;
                    } else {
                        if ($("input[name=lang]").val() === 'en') {
                            $('input:radio[name="contractor_foreigner"][value="yes"]').prop("checked", true);
                            $('input:radio[name="contractor_foreigner"][value="no"]').next('a').removeClass('checked');
                            $('input:radio[name="contractor_foreigner"][value="yes"]').next('a').addClass('checked');
                            $('input:radio[name="contractor_foreigner"]').trigger('change');
                            $('.select2-selection__placeholder').html('- Choose -');
                        }
                        $('#client_info').slideDown('slow', function () {
                            smoothScroll.animateScroll(null, '#client_info', {"offset": 50});
                            $('#invoice_submit_button').show();
                            $('#package-button-wrapper').slideUp();
                        });
                    }
                }
            });

        })
        if ($("input[name=lang]").val() === 'en') {
            $('.select2').on('select2:open', function () {
                setTimeout(function () {
                    $('.select2-results__message').html('Please enter 3 or more characters');
                }, 50);

            });
        }


    };


    // get policy types structure by current object id
    this.processing = function (paymentSlug) {
        var formData = new FormData($('#dzo_shop_form')[0]);
        formData.append('g_recaptcha_token', TCApp.InvisibleRecaptcha.token);
        formData.append('payment_slug', paymentSlug);
        // set ajax url and make ajax call
        var ajaxURL = URL + "/ajax/dzo/processing";
        var $_token = $("input[name=_token]").val();
        self.lockNext();
        $.ajax({
                type: "POST",
                data: formData,
                dataType: 'json',
                url: ajaxURL,
                encode: true,
                contentType: false,
                processData: false,
                headers: {
                    'X-XSRF-TOKEN': $_token
                },
                success: function (data) {
                    self.unlockNext();

                    if (true === data.unexpected_error) {
                         let consentCheckbox = document.getElementById(
                           "terms-conditions"
                         );
                         consentCheckbox.classList.add(
                           "cb-prihvatite-uslove-up"
                         );
                         document.getElementById(
                           "terms-conditions-message"
                         ).style.color = "#005ca9";

                         document.getElementById(
                           "terms-conditions-message"
                         ).style.fontWeight = "bold";

                         setTimeout(function () {
                           consentCheckbox.classList.remove(
                             "cb-prihvatite-uslove-up"
                           );
                           consentCheckbox.classList.add(
                             "cb-prihvatite-uslove-down"
                           );
                           setTimeout(function () {
                             consentCheckbox.classList.remove(
                               "cb-prihvatite-uslove-down"
                             );
                             document.getElementById(
                               "terms-conditions-message"
                             ).style.color = "#666";

                             document.getElementById(
                               "terms-conditions-message"
                             ).style.fontWeight = "bold";
                           }, 200);
                         }, 500);
                        $('#dzo-form-errors').html(data.errors.unexpected_error);
                        return false;
                    }
                    if (null != data.redirect_url) {
                        window.location.href = data.redirect_url;
                    }
                    // typeof data.policy_plans !== 'undefined'
                    if (self.year_of_birth !== '' && self.year_of_birth > 1900 && self.disease === 'no' && typeof data.errors.year_of_birth === 'undefined' && typeof data.packages !== 'undefined') {
                        // set prices
                        $('#vital-standard #old-package-price').html(data.packages['vital-standard'].base_total);
                        $('#vital-standard #package-price').html(data.packages['vital-standard'].total);
                        $('#vital-comfort #old-package-price').html(data.packages['vital-comfort'].base_total);
                        $('#vital-comfort #package-price').html(data.packages['vital-comfort'].total);

                        $('#dzo-lead-box-wrapper').slideUp('slow', function () {
                            $('#dzo-package-selector').slideDown('slow', function () {
                                smoothScroll.animateScroll(null, '#dzo-package-selector', {"offset": 50});
                            });
                        });

                    } else if ((typeof self.disease === 'undefined' && typeof data.errors.year_of_birth !== 'undefined') || (typeof self.disease !== 'undefined' && typeof data.errors.year_of_birth !== 'undefined') || self.disease === 'yes') {
                        $('#dzo-package-selector').slideUp('slow', function () {
                            $('#dzo_selected_package').val('');
                            $('.choose').removeClass('primary_color').addClass('green').html(window.translations.choose);
                            $('#dzo-lead-box-wrapper').slideDown('slow');
                        });
                        $('#client_info').slideUp('slow', function () {
                            smoothScroll.animateScroll(null, '#insurance-details-form', {"offset": 50});
                            $('#invoice_submit_button').hide();
                        });
                    }


                    if (self.invoice !== true) {
                        if (data.success == false) {
                            $.each(data.errors, function (index, error) {
                                if (self.input.attr('name') != index) {
                                    $(self.input).parent().siblings('.status').children('i').removeClass('error').addClass('ok');
                                    $(self.input).removeClass('has-error').addClass('has-success');
                                    $(self.input).parent().siblings('.errors').html('');
                                } else {
                                    $(self.input).parent().siblings('.status').children('i').removeClass('ok').addClass('error');
                                    $(self.input).removeClass('has-success').addClass('has-error');
                                    $(self.input).parent().siblings('.errors').html(error);
                                    return false;
                                }

                            });
                        } else {
                            $('*[data-validate=true]').each(function (id, selector) {
                                $(this).parent().siblings('.status').children('i').removeClass('error').addClass('ok');
                                $(this).removeClass('has-error').addClass('has-success');
                                $(this).parent().siblings('.errors').html('');
                            })
                        }
                    } else if (self.invoice === true) {
                        if (data.success == false) {
                            var $counter = 0;
                            $('*[data-validate=true],*[data-validate-field=true]').each(function (id, selector) {
                                $.each(data.errors, function (index, error) {
                                    if ($counter === 0) {
                                        // console.log('input[name=' + index + ']');
                                        smoothScroll.animateScroll(null, '#' + index, {"offset": 50});
                                        console.log('#' + index);
                                    }

                                    $counter++;
                                    if ($(selector).attr('name') != index) {
                                        $(selector).parent().siblings('.status').children('i').removeClass('error').addClass('ok');
                                        $(selector).removeClass('has-error').addClass('has-success');
                                        $(selector).parent().siblings('.errors').html('');
                                    } else {
                                        $(selector).parent().siblings('.status').children('i').removeClass('ok').addClass('error');
                                        $(selector).removeClass('has-success').addClass('has-error');
                                        $(selector).parent().siblings('.errors').html(error);
                                        return false;
                                    }
                                });
                            });

                        } else {
                            $('#forms-section').slideUp('slow', function () {
                                $('#invoice_back_button').show();
                                $('.form-payment').removeClass('hide');
                                $('.payment-message').removeClass('hide');

                                $('#invoice_submit_button').hide();
                                $('.accepted-payment-img').show();

                                $('#review-name').html(data.summary.contractor.first_name + ' ' + data.summary.contractor.last_name);
                                $('#review-birthdate').html(data.summary.contractor.birthdate);
                                $('#review-jmbg').html(data.summary.contractor.jmbg);
                                $('#review-phone').html(data.summary.contractor.phone);
                                $('#review-address').html(data.summary.contractor.address + ' ' + data.summary.contractor.street_number);
                                $('#review-city').html(data.summary.contractor.city);
                                $('#review-district').html(data.summary.contractor.district);
                                $('#review-date-from').html(data.summary.policy.begin_date);
                                $('#review-date-to').html(data.summary.policy.end_date);

                                $('#summary-section').slideDown('slow');

                            })
                            $('#discounts').html('');
                            $('#total-premium').html(data.invoice.base_total);
                            $('#total-premium-sai').html(data.invoice.total);
                            $.each(data.invoice.discounts, function (index, value) {
                                $('#discounts').append(
                                    '<div class="item">' +
                                    '<label class="col-md-7 control-label" style="color:green;">' + index + '</label>' +
                                    '<div class="col-md-2 text-right text-left-small-device" style="color:green;">' +
                                    '<h5>-' + value + '</h5>' +
                                    '</div>' +
                                    '</div>'
                                )
                            });
                            $('#invoice').slideDown();


                        }
                    }

                }
            }
        );
    }


    // validate single field
    this.validateField = function (ref, type) {
        // inits
        var name = null;
        var value = null;
        // determine type and get name and value
        if ('text' == type || 'dropdown' == type) {
            // get values
            name = $(ref).attr('name');
            value = $(ref).val();
        }
        // call ajax method
        // set ajax url and make ajax call
        var ajaxURL = URL + "/ajax/dzo/validate-field";
        var $_token = $("input[name=_token]").val();
        var lang = $(ref).data('lang');
        $.ajax({
            type: "POST",
            data: {
                'name': name,
                'value': value,
                'lang': lang,
                '_token': $_token
            },
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            headers: {'X-XSRF-TOKEN': $_token},
            success: function (data) {
                if (true === data.unexpected_error) {
                    $('#dzo-form-errors').html(data.errors.unexpected_error);
                    return false;
                }
                if (null != data.redirect_url) {
                    window.location.href = data.redirect_url;
                }
                // if success
                if (data.success === true) {
                    // console.log(data)
                    // show success
                    self.statusSuccess(data.field_name);
                    // check helper
                    if (null != data.helper) {
                        // check helper type
                        if ('date' === data.helper.type) {
                            $('input[name=' + data.helper.key + ']').datepicker("setDate", data.helper.value).trigger('blur');

                        } else {
                            $('input[name=' + data.helper.key + ']').val(data.helper.value).trigger('blur');
                        }
                    }
                } else {
                    // console.log(data)
                    // console.log(data.error)
                    // set error sign and message
                    // show error
                    self.statusError(data.field_name, data.error);
                }
            }
        });
    }

    // status success
    this.statusSuccess = function (id) {
        // set status to success for passed id
        if ($('*[name=' + id + ']').data('image') === 'yes') {
            $('*[name=' + id + ']').parent().parent().parent().siblings('.status').children('i').removeClass('error').addClass('ok');
            $('*[name=' + id + ']').removeClass('has-error').addClass('has-success');
            $('*[name=' + id + ']').parent().parent().parent().siblings('.errors').html('');
        } else {
            $('*[name=' + id + ']').parent().siblings('.status').children('i').removeClass('error').addClass('ok');
            $('*[name=' + id + ']').removeClass('has-error').addClass('has-success');
            $('*[name=' + id + ']').parent().siblings('.errors').html('');
        }
    }
    // status error
    this.statusError = function (id, message) {
        // set status to error for passed id
        if ($('*[name=' + id + ']').data('image') === 'yes') {
            $('*[name=' + id + ']').parent().parent().parent().siblings('.status').children('i').removeClass('ok').addClass('error');
            $('*[name=' + id + ']').removeClass('has-success').addClass('has-error');
            $('*[name=' + id + ']').parent().parent().parent().siblings('.errors').html(message);
        } else {
            $('*[name=' + id + ']').parent().siblings('.status').children('i').removeClass('ok').addClass('error');
            $('*[name=' + id + ']').removeClass('has-success').addClass('has-error');
            $('*[name=' + id + ']').parent().siblings('.errors').html(message);
        }
    }


    function capWords(str) {
        var words = str.split(" ");
        var capitalized = '';
        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            var firstLetter = word.substr(0, 1);
            var rest = word.substr(1, word.length - 1);
            capitalized += firstLetter.toUpperCase() + rest + ' ';
        }
        return capitalized
    }

    // unlock next step button
    this.unlockNext = function () {

        $('.form-next-loader').addClass('hide').prop('disabled', true);
    }
    // lock next step button
    this.lockNext = function () {
        $('.form-next-loader').removeClass('hide').prop('disabled', false);
    }


    // lock birthdate if domestic user and jmbg set
    this.lockBirthdate = function () {
        // get handles
        var $contractorCitizenship = $('#contractor_foreigner:checked').val();
        var $insuredCitizenship = $('#insured_foreigner:checked').val();
        var $contractorJMBG = $('#contractor_identification_number');
        var $contractorBirthdate = $('#contractor_date_of_birth');
        var $insuredJMBG = $('#insured_identification_number');
        var $insuredBirthdate = $('#insured_date_of_birth');
        // change form
        if ('yes' === $contractorCitizenship) {
            $contractorBirthdate.removeClass('disabled');
            $contractorBirthdate.prop('readonly', false);
        } else {
            if ($contractorJMBG.val().length === 13) {
                $contractorBirthdate.addClass('disabled');
                $contractorBirthdate.prop('readonly', true);

            } else {
                $contractorBirthdate.removeClass('disabled');
                $contractorBirthdate.prop('readonly', false);
            }
        }
        if ('yes' === $insuredCitizenship) {
            $insuredBirthdate.removeClass('disabled');
            $insuredBirthdate.prop('readonly', false);
        } else {
            if ($insuredJMBG.val().length === 13) {
                $insuredBirthdate.addClass('disabled');
                $insuredBirthdate.prop('readonly', true);
            } else {
                $insuredBirthdate.removeClass('disabled');
                $insuredBirthdate.prop('readonly', false);
            }
        }
    }


    // lock birthdate if domestic user and jmbg set
    this.hideAddresses = function () {
        // get handles
        var $insuredAddressWrapper = $('#insured_address_fields_wrapper');
        var $insuredAddressCheckbox = $('#insured_contractor_same_address:checked').val();
        // change form
        if ('yes' === $insuredAddressCheckbox) {
            $insuredAddressWrapper.slideUp('slow');
        } else {
            $insuredAddressWrapper.slideDown('slow');
        }
    }
}
