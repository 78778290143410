//Homepage statistics MORE/LESS button
$(".mp_more_btn").click(function(){
    $(".mp_more_text_second").show();
    $(".mp_hide_btn").show();
    $(".mp_more_btn").hide();
});

$(".mp_hide_btn").click(function(){
    $(".mp_more_text_second").hide();
    $(".mp_hide_btn").hide();
    $(".mp_more_btn").show();
});
//End of Homepage statistics MORE/LESS button

//Footer accordion
$('.box.span_1_of_6').click(function () {

    var status = $(this).children(".links-group").attr('data-status');

    // close everything first
    $(".links-group").attr('data-status', 'closed');
    // $("h3").css('background-image', 'url(arrow-down.png)');
    $(".links-group").css("display", "none");


    if (status == 'closed') {
        // open it
        $(this).children(".links-group").attr('data-status', 'open');
        // $(this).children("h3").css('background-image', 'url(arrow-up.png)');
        $(this).children(".links-group").css("display", "block");

    } else {
        // close it
        $(this).children(".links-group").attr('data-status', 'closed');
        // $(this).children("h3").css('background-image', 'url(arrow-down.png)');
        $(this).children(".links-group").css("display", "none");
    }
});
//End of Footer accordion

//Naša priznanja

var offset_values = ["-40", "-210", "-380", "-550", "-720", "-890"];
var currentOffsetIndex = 0;

$(".next").click(function(event){
    var offset = offset_values[(currentOffsetIndex + 1)];
    // console.log(offset);
    $('.smallUL').css({'transform': 'translate3d(' + offset + 'px, 0px, 0px)'});
    currentOffsetIndex += 1;
    // console.log("current " + currentOffsetIndex);

    if(currentOffsetIndex >= (offset_values.length - 1))
        currentOffsetIndex = offset_values.length -1;
    // console.log("if "+currentOffsetIndex);

    event.preventDefault();

});
console.log(currentOffsetIndex);
$(".prev").click(function(event){
    currentOffsetIndex = currentOffsetIndex - 1;
    var offset = offset_values[currentOffsetIndex];
    // console.log("prev" + offset);
    $(".smallUL").css({transform: 'translate3d(' + offset + 'px, 0px, 0px)'});
    // console.log("prevcurrent " + currentOffsetIndex);
    if(currentOffsetIndex <= 0)
        currentOffsetIndex = 0;
    // console.log("prev if " + currentOffsetIndex);
    event.preventDefault();
});
//End of Naša priznanja