$(document).ready(function(){
    // More/Less slider

    $(".cont-btn span").on("click", function(event){
        event.preventDefault();
        var $box=$(this).parents(".box-plain .box");
        $box.find(".newcalc-content-wrapper").toggleClass("otvoren");
        $box.find(".cont-btn span.btn-more").toggleClass("otvoren");
        $box.find(".cont-btn span.btn-less").toggleClass("otvoren");
        console.log("otvori");
    });



});