function FORM_HELPERS() {
    // declarations
    var self = this;


    // listeners
    this.listeners = function () {
        // custom radio and checkbox buttons handler
        $('.prettyradio a, .prettycheckbox a, .prettycheckbox label, .prettyradio label').on('click', function (e) {
            // prevent default behaviour
            e.preventDefault();
            // if have class disabled return imediately
            if ($(this).hasClass('disabled')) {
                return;
            }
            // get input handler
            var $input = $(this).parent().find('input');
            var $type = $input.attr('type');
            // if checkbox
            if ($type === 'checkbox') {
                var $href = $(this).parent().find('a');
                // already checked? remove checkmark and input value
                if ($($href).hasClass('checked')) {
                    $($href).removeClass('checked');
                    $input.prop('checked', false).trigger('change');
                } else { // otherwise check item and set input value to true
                    $($href).addClass('checked');
                    $input.prop('checked', true).trigger('change');
                }
            }
            // if  radio
            if ($type === 'radio') {
                // get name
                var $name = $(this).data('common-name');
                var $href = $(this).parent().find('a');
                // first remove all checkers for named radio
                // if problems appers append trigger('change') on line below
                $('input:radio[name=' + $name + ']').prop('checked', false);
                $(this).parent().parent().find('a').removeClass('checked');
                $input.prop('checked', true).trigger('change');
                // already checked? remove checkmark and input value
                if (!$href.hasClass('checked')) {
                    $href.addClass('checked');
                }
            }
        });

        // We can attach the `fileselect` event to all file inputs on the page
        $(document).on('change', ':file', function () {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });

        // We can watch for our custom `fileselect` event like this
        $(document).ready(function () {
            $(':file').on('fileselect', function (event, numFiles, label) {

                var input = $(this).parents('.input-group').find(':text'),
                    log = numFiles > 1 ? numFiles + ' files selected' : label;

                if (input.length) {
                    input.val(log);
                }

            });
        });


        $(".select-city").select2({
            placeholder: '- Izaberite -', // set placehiolder
            language: "sr", // set language
            ajax: {
                url: URL + "/ajax/district-cities",
                dataType: 'json',
                delay: 250,
                type: "POST",
                data: function (params) {
                    return {
                        keyword: params.term, // search term
                        page: params.page
                    };
                },
                processResults: function (data, params) {
                    // parse the results into the format expected by Select2
                    // since we are using custom formatting functions we do not need to
                    // alter the remote JSON data, except to indicate that infinite
                    // scrolling can be used
                    params.page = params.page || 1;

                    return {
                        results: data.cities,
                    };
                },
                cache: true
            },
            escapeMarkup: function (markup) {
                return markup;
            }, // let our custom formatter work
            minimumInputLength: 3, // set min characters for search
            // template html for selected item
            templateSelection: function (result) {
                if (result.district_name === undefined) {
                    return result.name || result.text;
                }

                return result.name
                    + " <small class='city-selector'>("
                    + result.district_name
                    + ")</small>";
            },
            // template html for result list
            templateResult: function (result) {
                if (result.district_name === undefined) {
                    return result.name;
                }

                return result.name
                    + "</br><small class='city-selector'>"
                    + result.district_name
                    + "</small>";
            }

        });

        $('.select-city, .select-brand, .select-model').next('.select2').find('.select2-selection').one('focus', select2Focus).on('blur', function () {
            $(this).one('focus', select2Focus)
        })

        function select2Focus() {
            $(this).closest('.select2').prev('select').select2('open');
        }


        // vehicle brand changed update models
        // this is common for many forms so it's here
        // there is control struct rules so be sure you implement them
        // .select-model dropdown must exist
        $('.select-brand').on('change', function () {
            // find city select
            var $select_model = $(this).parent().parent().parent().parent().find('.select-model');
            $select_model.select2('val', null);
            $select_model.select2('data', null);
            $select_model.html('');
            // if value isn't greater than 0
            if (parseInt($(this).val()) > 0) {
                // set ajax url and make ajax call
                var ajaxURL = URL + "/ajax/brand-models";
                var $_token = $("input[name=_token]").val();
                $.ajax({
                    type: "POST",
                    data: {
                        'id': parseInt($(this).val()),
                        '_token': $_token
                    },
                    dataType: 'json',
                    url: ajaxURL,
                    encode: true,
                    headers: {'X-XSRF-TOKEN': $_token},
                    success: function (data) {
                        // if success
                        if (data.success === true) {
                            // loop all possible cities and fill select2
                            $.each(data.models, function (key, value) {
                                $select_model.select2({
                                    "data": [{
                                        id: value.id,
                                        text: value.name
                                    }],
                                    minimumResultsForSearch: 1,
                                    placeholder: '- Izaberite -'
                                });
                            });
                            // trigger change
                            $select_model.trigger("change");
                        }
                    }
                });
            }
        });
        // KZO custom number input handler
        $('.input-number-btn').on('click',function(){

            var $sign = $(this).data('sign'),
                $input = $(this).parent().find('.input-number');

            if($input.val() < $input.attr('max') && $sign === "+"){
                $input.val(parseInt($input.val())+1).trigger('change');
            }
            else if($input.val() > $input.attr('min') && $sign === "-"){
                $input.val(parseInt($input.val())-1).trigger('change');
            }
        })
    }
}
