// namespace
var TCApp = TCApp || {};

// declare class
TCApp.InvisibleRecaptcha = function() {
    // inits
    var site_key = '6LdoHnMUAAAAABkli72ChPuUmPIK7F5WzpUs5_Tz';
    var token = null;
    var recaptchaID = null;
    var callback = null;

    // verify user
    function verify(callback) {
        // check if set callback
        if ('undefined' !== typeof callback) {
            // set callback
            TCApp.InvisibleRecaptcha.callback = callback;
        }
        // try to verify user
        return window.grecaptcha.getResponse(TCApp.InvisibleRecaptcha.recaptchaID);
    }

    // on user verification success
    function onUserVerified(token) {
        // set recaptcha token
        TCApp.InvisibleRecaptcha.token = token;
        // check if set callback
        if ('undefined' !== typeof TCApp.InvisibleRecaptcha.callback) {
            // execute callback
            var invisibleRecaptchaCallbackTimer = setTimeout(function() {
                TCApp.InvisibleRecaptcha.callback();
            }, 800);
        }
        // reset recaptcha
        window.grecaptcha.reset();
    }

    // prepare return values
    var oPublic = {
        site_key: site_key,
        token: token,
        recaptchaID: recaptchaID,
        verify: verify,
        onUserVerified: onUserVerified
    };

    // return
    return oPublic;
}();

// on window load
window.onScriptLoad = function () {
    // this callback will be called by recaptcah/api.js once its loaded. If we used
    // render=explicit as param in script src, then we can explicitly render reCaptcha at this point
    // element to "render" invisible captcha in
    var htmlEl = document.querySelector('.g-recaptcha');

    // check if exist container if not don't init grecaptcha
    if (null === htmlEl) {
        return;
    }

    // option to captcha
    var captchaOptions = {
        sitekey: TCApp.InvisibleRecaptcha.site_key,
        size: 'invisible',
        // tell reCaptcha which callback to notify when user is successfully verified.
        // if this value is string, then it must be name of function accessible via window['nameOfFunc'],
        // and passing string is equivalent to specifying data-callback='nameOfFunc', but it can be
        // reference to an actual function
        callback: TCApp.InvisibleRecaptcha.onUserVerified
      };

    // now render
    TCApp.InvisibleRecaptcha.recaptchaID = window.grecaptcha.render(htmlEl, captchaOptions);
};
