function PZO_Animated() {
  // declarations
  var self = this;
  this.shop_type = "pzo-individual";
  this.shop_id = 2;
  this.step = 1;
  this.validation_step = 5;
  this.max_reached_step = 1;
  this.number_of_persons = 1;
  this.travel_period_type = null;
  this.policy_types = [];
  this.continent = null;
  this.travel_destination = null;
  this.continents = {
    europe: "Evropa",
    northamerica: "Sjeverna Amerika",
    southamerica: "Južna Amerika",
    asia: "Azija",
    africa: "Afrika",
    australia: "Australija",
  };
  this.labels = [];
  this.slideDownDone = false;
  this.covidShown = false;
  this.buttonBuy = false;

  // listeners
  this.listeners = function () {
    // initial calls
    $(window).load(function () {
      self.calculateCanvasHeight();
      self.animateListener();
      // show step 1 form
     //self.showForm(750, 1);
      self.policyTypesByShopID();
      self.displayProperNumberOfPersons();
      // if family than set form #2 type to another value
      if ("pzo-family" === self.shop_type) {
        // override all to insured-child
        $(".insured-person-type").val("insured-child");
        // set proper #1 form type
        $('input[name="insured_person_type_1"]').val("contractor-insured");
        // set proper #2 form type
        $('input[name="insured_person_type_2"]').val("insured-parent");
		 // set proper #3 form type
		 if (window.location.href.indexOf("putno-osiguranje-agencije") > -1) {
				$('input[name="insured_person_type_3"]').val("insured-parent");
		 }
      }
    });
  };

  // trigger next step button
  this.next = function () {
    // hide current form
    // self.hideForm();
    // increment step counter
    self.step++;
    // set max reached step
    if (self.step > self.max_reached_step) {
      self.max_reached_step = self.step;
    }
    // check if validation step
    if (self.step == self.validation_step) {
      // revert current step to last possible form step
      self.step = self.validation_step - 1;
      // call ajax validation and calculation
      self.calculate();
    } else {
		if($('#pzo-active-view').val() == 'view-review' || $('#pzo-active-view').val() == 'view-checklist'){
			self.calculate();
		}else{
		  // animate current step
		  self.animate();
		}
		
    }
  };

  // trigger previous step button
  this.prev = function () {
    // check if possible
    if (self.step > 1) {
      self.switchStep(self.step - 1);
    }
  };

  // ajax validation and calculation step
  this.calculate = function (view = null) {
    // if family or group show number_of_persons forms
    // if family
    if ("pzo-family" === self.shop_type) {
      // get number of all persons
      var number =
        parseInt($("input[name=number_of_persons]:checked").val()) +
        parseInt($("#number-of-childs").val());
	if (window.location.href.indexOf("putno-osiguranje-agencije") > -1) {
          number = number + 1;
    }
      // loop
      for (var i = 2; i < number + 1; i++) {
        // display
        $("#pzo-form-insured-" + i)
          .removeClass("hide")
          .show();
      }
      // destroy others
      for (var i = number + 1; i < 11; i++) {
        // display
        $("#pzo-form-insured-" + i)
          .addClass("hide")
          .hide();
      }
    }
    // if group
    if ("pzo-group" === self.shop_type) {
      // loop
      for (var i = 2; i < self.number_of_persons + 1; i++) {
        // display
        $("#pzo-form-insured-" + i)
          .removeClass("hide")
          .show();
      }
      // destroy others
      for (var i = self.number_of_persons + 1; i < 100; i++) {
        // display
        $("#pzo-form-insured-" + i)
          .addClass("hide")
          .hide();
      }
    }

    // serialize data
    var formData = new FormData($("#pzo-form")[0]);
    // set ajax url and make ajax call
    if (window.location.href.indexOf("putno-osiguranje-agencije") > -1) {
            var ajaxURL = URL + "/ajax/pzo-tourist/processing";
        } else {
            var ajaxURL = URL + "/ajax/pzo/processing";
        }
    var $_token = $("input[name=_token]").val();
    $.ajax({
      type: "POST",
      data: formData,
      dataType: "json",
      url: ajaxURL,
      encode: true,
	  enctype: 'multipart/form-data',
	  contentType: false,
      processData: false,
      headers: { "X-XSRF-TOKEN": $_token },
      success: function (data) {
        // deactivate overlay screen
        if (data.success == true) {
          // everything ok
          // display form page so user can fill data about contractor and insured persons
          /* $(".step-progress").addClass("animated flipOutX");*/
          $("#pzo-animated-container").fadeOut(600);
          // set values for checklist page
          $("#checklist-policy-type").html(data.checklist.policy_type);
          $("#checklist-insured-period").html(
            data.checklist.insurance_period.name
          );
          $("#checklist-insured-days").html(
            data.checklist.insurance_period.days
          );
          $("#checklist-destination").html(data.checklist.destination);
          $("#checklist-insured-sum").html(data.checklist.insured_sum);
          $("#checklist-insurance-death").html(data.checklist.insurance_death);
          if ("-" == data.checklist.insurance_death) {
            $("#checklist-insurance-death-container").hide();
          }else{
			 $("#checklist-insurance-death-container").show();
		  }
		 
          $("#checklist-travel-intent").html(data.checklist.travel_intent);
		  $("#checklist-lost_luggage").html(data.checklist.lost_luggage);
		  $("#checklist-late_luggage").html(data.checklist.late_luggage);
		  $("#checklist-late_flight").html(data.checklist.late_flight);
		  
		  $("#checklist-canceled_travel").html(data.checklist.canceled_travel);
		  
          $("#checklist-total").html(data.checklist.total);
          
		  if($("#checklist-total").val() != '' || $("#checklist-total").html() != ''){
				$("#buy_insurance").prop('disabled','false');
				$("#buy_insurance").removeClass('disabled');
			}
			
          $("#buy_insurance").prop('disabled','false');
          self.unlockNext();
		 
          if($('#pzo-active-view').val() == 'view-review'){
			  self.submitForm('');
			  $(".form-payment").removeClass('hide');
              $("#pzo-form-container").css('display','block');
              $('#pzo-form-review-container').show().addClass('animated slideInLeft');
              $("#pzo-form-insurance-container").slideUp();
          }
		   if(view == 'view-checklist'){
			  
				$('#pzo-form-insurance-container').show().addClass('animated slideInLeft');
				$("#pzo-animated-forms-container").slideUp();
			 
		  }
          // load checklist page
          /* setTimeout(function() {
                        $('#page').removeClass('pzo-animated relative').css('height', 'auto');
                        $('#page > .inner-section .content').css('paddingTop', '20px');
                        $('#pzo-animated-container').hide();
                        $('#pzo-animated-forms-container').hide();
                        $('.step-progress').hide().removeClass('animated flipInX flipOutX');
                        // show form page
                        $('#pzo-form-container').show().addClass('animated slideInLeft');
                        // reset clasess
                        $('.form-box').hide().removeClass('animated flipInX flipOutX');
                    }, 1200);
                    // set active view to form
                    $('#pzo-active-view').val('view-checklist'); */

          // reload with mark
          // window.location.href = data.location;
		  //self.next();
        } else {
          // check if unexpected error
          if (true === data.unexpected_error) {
            // display unexpected error message
            $("#pzo-animated-unexpected-errors").html(data.errors).show();
            // switch to step with error
           // self.switchStep(data.error_step);
            return false;
          }
          // parse errors
          data.errors = $.parseJSON(data.errors);
          // proceed to common error
           $.each(data.errors, function (key, value) {
			   if(key == 'travel_value'){
				   self.statusError('travel_value');
			   }
			   if(key == 'travel_document'){
				   self.statusError('travel_document');
			   }
				if(key == 'insurance_upload'){
				   self.statusError('insurance_upload');
			   }
			   if(key == 'street_number'){
				   self.statusError('insured_street_1');
			   }
			  
                        // prepare message
                        var key_replace = key.replace('_', ' ');
                        value[0] = value[0].replace(key_replace, '');
                        // set error status to returned error fields
						self.statusError(key);
						if(self.step>2){
							self.statusError('insured_' + key + '_' + data.iteration_number, value[0]);
						}
                        
                    });
        }
      },
    });
  };

  // animate listener is main animation event listener
  this.animateListener = function () {
    // trigger next step button
    $(".pzo-next").on("click", function () {
      // check if next available
      if ($(this).hasClass("disabled")) {
        return false;
      }
      self.next();
    });
    // trigger previous step button
    $(".pzo-prev").on("click", function () {
      self.prev();
    });
    // step indicator change
    $(".step-indicator").on("click", function () {
      // change step
      self.switchStep($(this).data("step"));
    });
    // catch insured ages changed
    $(".allowance-ages").on("click", function () {
      // get clicked radio button value
      var clicked_value = $("input[name=allowance_ages]:checked").val();
      // get common name and set status to success
      self.statusSuccess(self.commonName(this));
      // unclock next button
      // self.unlockNext();
    });
    // catch travel period type changed
    $(".travel-period-type").on("click", function () {
      // get common name and set status to success
      self.statusSuccess(self.commonName(this));
      // get clicked radio button value
      var clicked_value = $("input[name=travel_period_type]:checked").val();
      // check if radio value changed
      if (clicked_value !== self.travel_period_type) {
        // check if year
        if ("year" === clicked_value) {
          // set status to neutral
          self.statusNeutral("travel_number_of_days");
        }
        // set new value
        self.travel_period_type = clicked_value;
        // activate policy travel intent dropdown with new values
        self.policyTravelIntent();
        // show proper 4th step travel intention box based on choosed period type
        $(".travel-policy-intent").hide();
		$(".select-intent").attr('disabled',true);
        $("#policy-intent-" + self.travel_period_type).show();
		$("#policy-intent-" + self.travel_period_type).find('.select2').removeAttr('disabled');
        // uncheck step 4 policy intent because
        // they are different for each period type
        //$("input[name=policy_intent]:checked").prop("checked", false);
        //self.statusNeutral("policy_intent");
        $(".policy-intent").removeClass("checked");
        // validate step
        self.validateStep(this);
        self.isCovidAllowanceShown();
      }
    });
    // catch number of persons change
    $("#number-of-persons, #number-of-persons-60, #number-of-childs").on(
      "change",
      function () {
        // validate step
        self.validateStep(this);
        // display proper number of persons
        self.displayProperNumberOfPersons();
      }
    );
    // catch number of persons for family policy
    $(".number-of-persons").on("click", function () {
      // validate family policy
      if ("pzo-family" === self.shop_type) {
        // validate step
        self.validateStep();
        // display proper number of persons
        self.displayProperNumberOfPersons();
        return;
      }
    });
    // catch travel number of days - year travel period type
    $("#travel-number-of-days").on("change", function () {
      // if value isn't greater than 0 mark as error
      if (parseInt($(this).val()) > 0) {
        // set status to success
        self.statusSuccess("travel_number_of_days");
      } else {
        // set status to error
        self.statusError("travel_number_of_days");
      }
      // validate step
      self.validateStep();
      return;
    });
    // catch travel begin date change
    $("#travel-begin-date").on("blur", function () {
      // validate step
      self.validateStep(this);
    });
    // catch travel begin date change
    $("#travel-begin-date").on("change", function () {
      // validate step
     
      self.validateStep(this);
      self.isCovidAllowanceShown();
      self.calculate();
    });
	
	$("#insurance-death").on("change",function(){
		 self.calculate();
	});
	
	$("#policy_limit").on("change",function(){
		 self.calculate();
	});

	$("[name=allowance_sport_ski]").on('change',function(){
		self.calculate();
	});
	$("[name=allowance_ages]").on('change',function(){
		self.calculate();
	});
	
	$("[name=number_of_persons]").on('change',function(){
		self.calculate();
	});
	
	$("[name=number_of_persons_60]").on('change',function(){
		self.calculate();
	});

	$("[name=number_of_childs]").on('change',function(){
		self.calculate();
	});
	
	$("[name=number_of_persons]").on('keyup',function(){
		self.calculate();
	});
	
	$("[name=number_of_persons_60]").on('keyup',function(){
		self.calculate();
	});

	$("[name=number_of_childs]").on('keyup',function(){
		self.calculate();
	});
	
	$("[name=travel_number_of_days]").on('change',function(){
		self.calculate();
	});
 
	
	$("[name=insurance_death_offer]").on("change",function(){
	  if($(this).val() != '-1' && $(this).val() != ''){
		  self.statusSuccess('insurance_death');
	  }else{
		  self.statusError('insurance_death');
	  }
	  self.calculate();
    });
	
    $("[name=insurance_canceled_travel]").on("change",function(){
      self.calculate();
    });
	
	$("[name=travel_document_file]").on('change',function(){
		if($(this).val() != ''){
		  self.statusSuccess('travel_document');
		}else{
		  self.statusError('travel_document');
		}
	});
	
	$("[name=insurance_upload]").on('change',function(){
		if($(this).val() != ''){
		  self.statusSuccess('insurance_upload');
		}else{
		  self.statusError('insurance_upload');
		}
	});
	self.statusSuccess('contractor_type');
	
	$("[name=allowance_covid]").on("change",function(){
		// get value
		self.calculate();
	});
	
	$("#travel_value").on('keydown',function(e){
		 if((e.keyCode>=48 && e.keyCode<=57) || (e.keyCode>95 && e.keyCode<106) || e.keyCode == 190 || e.keyCode == 188 || e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 8){
			 return true;
		 }else{1
			 e.preventDefault();
		 }
	});
	
    $("#travel_value").on('propertychange input',function(){
		self.calculate();
		let travelValue = $(this).val(); 
		travelValue = travelValue.replace(".","");
		travelValue = travelValue.replace(",",".");
		if(travelValue!='' && Number(travelValue) <= 5000){
			self.statusSuccess('travel_value');
			$("#travel_value_info").remove();
		}else{
			self.statusError('travel_value');
			$("#travel_value_info").remove();
			$("#travel_value").after('<p class="text-danger" style="font-size:12px;" id="travel_value_info">Osiguravajuće pokriće moguće je ugovoriti za vrijednost putovanja do 5.000,00 KM</p>')
		 }
    });
    // catch travel end date change
    $("#travel-end-date").on("blur", function () {
      // validate step
      self.validateStep(this);
    });
    // catch travel end date change
    $("#travel-end-date").on("change", function () {
      // validate step
      self.validateStep(this);
      self.isCovidAllowanceShown();
      self.calculate();
    });
    $("[name=insurance_lost_luggage]").on("change",function(){
      self.validateStep(this);
	   self.calculate();
    });
    $("[name=travel_value]").on("change",function(){
      self.validateStep(this);
    });
    $("[name=travel_destination]").on("change", function () {
		$("#amounts_additional_options").addClass('hidden');
      self.isCovidAllowanceShown();
      self.calculate();
    });
    // catch policy limit change
    $("#policy-limit").on("change", function () {
      // check if have disabled class
      if ($(this).hasClass("disabled")) {
        return;
      }
      // if value isn't greater than 0 mark as error
      if (parseInt($(this).val()) > 0) {
        // set status to success
        self.statusSuccess("policy_limit");
      } else {
        // set status to neutral
        self.statusNeutral("policy_limit");
      }
      // validate step
      self.validateStep();
      self.calculate();
      return;
    });
    // catch policy intent change
    $("[name=policy_intent]").on("change", function () {
   
      // set status to success
      self.statusSuccess("policy_intent");
      // validate step
      self.validateStep();
      self.calculate();
      return;
    });
    // catch allowance sport ski change
    $(".allowance-sport-ski").on("click", function () {
      // check if have disabled class
      if ($(this).hasClass("disabled")) {
        return;
      }
      // get value
      if ($("input[name=allowance_sport_ski]").is(":checked")) {
        // set status to success
        self.statusSuccess("allowance_sport_ski");
      } else {
        // set status to neutral
        self.statusNeutral("allowance_sport_ski");
      }
      // validate step
      self.validateStep();
      return;
    });
  
    self.isCovidAllowanceShown();
   
    // catch insurance death offer change
    $(".insurance-death-offer").on("click", function () {
      // check if have disabled class
      if ($(this).hasClass("disabled")) {
        return;
      }
      // get value
      if ("no" == $("input[name=insurance_death_offer]:checked").val()) {
        $("#insurance-death-container").slideUp();
        $("#review-death-terms-pdf-container").hide();
      } else {
        $("#checklist-insurance-death-container").show();
        $("#insurance-death-container").slideDown();
        $("#review-death-terms-pdf-container").show();
      }
      // validate step
      self.validateStep();
      return;
    });
    
  };

  // animate is main method for actual animations
  this.animate = function () {
    // prepare steps
    self.prepareStep();
    // check current step
    if (2 === self.step) {
      // change indicator
      $(".step-indicator").removeClass("active");
      $("#step-indicator-" + self.step).addClass("active");
      // open doors
      setTimeout(function () {
        $("#pzo-plane-opened").fadeIn(1300);
      }, 1800);
      // boarding bus animation
      $(".bus-animate").animate({ right: "58%" }, 2400, function () {
        // remove passengers from bus
        $(".bus-passenger").fadeOut(300, function () {
          // show plane passengers
          $(".plane-passengers").show();
          $("#pzo-plane-dad").fadeIn();
          $("#pzo-plane-mom").fadeIn();
          $("#pzo-plane-child-1").fadeIn();
          $("#pzo-plane-child-2").fadeIn();
          $("#pzo-plane-child-3").fadeIn();
          $("#pzo-plane-child-4").fadeIn();
          // continue bus ride
          var busAnimateTimer = setTimeout(function () {
            $(".bus-animate").animate({ right: "130%" }, 1200);
            self.showForm(1200);
          }, 800);
        });
      });
    }
    // check current step
    if (3 === self.step) {
      // change indicator
      $(".step-indicator").removeClass("active");
      $("#step-indicator-" + self.step).addClass("active");
      // open doors
      setTimeout(function () {
        $("#pzo-plane-closed").show();
        $("#pzo-plane-opened").fadeOut(600, function () {
          // roll plane
          $(".plane-animate").animate({ left: "110%" }, 1800);
          setTimeout(function () {
            // remove ground objects
            $(".bkg-tarmac").fadeOut(600, function () {
              // show flying plane
              $("#flying-plane").animate({ right: "60%" }, 2400);
              // show clouds
              $(".pzo-clouds").fadeIn(700, function () {
                // move clouds
                $("#cloud-1").animate({ left: "-5%" }, 2100);
                $("#cloud-2").animate({ right: "8%" }, 2100);
                $("#cloud-3").animate({ left: "13%" }, 2100);
                $("#cloud-4").animate({ right: "12.5%" }, 2100);
              });
              // show form
              setTimeout(function () {
                self.showForm();
              }, 1500);
            });
            $(".pzo-ground-items").fadeOut(300);
          }, 2000);
        });
      }, 800);
    }
    // check current step
    if (4 === self.step) {
      // change indicator
      $(".step-indicator").removeClass("active");
      $("#step-indicator-" + self.step).addClass("active");
      // hide clouds
      $(".pzo-clouds").hide();
      // hide previous form waiting
      setTimeout(function () {
        // hide flying plane
        $("#flying-plane").fadeOut(600);
        // show proper continent background image
        $(
          "#" + self.getContinentByTravelDestination() + "-continent-background"
        ).fadeIn(600);
        $(".bkg-tarmac").fadeIn(500);
        // roll plane
        setTimeout(function () {
          $(".plane-animate").animate({ left: "-60%" }, 1600);
          // open plane
          setTimeout(function () {
            $("#pzo-plane-opened").fadeIn();
            // unboard bus animation
            $(".bus-animate").animate({ right: "60%" }, 2400, function () {
              // hide sitting people
              $(".plane-passengers").fadeOut(300, function () {
                // show standing people
                $(".passenger-standing").fadeIn(300, function () {
                  // go to bus
                  $("#dad-standing").animate(
                    { bottom: "30%" },
                    600,
                    function () {
                      $(".passenger-standing").hide();
                      $("#pzo-bus-dad").fadeIn();
                    }
                  );
                  $("#mom-standing").animate(
                    { bottom: "30%" },
                    500,
                    function () {
                      $("#pzo-bus-mom").fadeIn();
                    }
                  );
                  $("#child-1-standing").animate(
                    { bottom: "30%" },
                    550,
                    function () {
                      $("#pzo-bus-child-1").fadeIn();
                    }
                  );
                  $("#child-2-standing").animate(
                    { bottom: "30%" },
                    700,
                    function () {
                      $("#pzo-bus-child-2").fadeIn();
                    }
                  );
                  $("#child-3-standing").animate(
                    { bottom: "30%" },
                    700,
                    function () {
                      $("#pzo-bus-child-3").fadeIn();
                    }
                  );
                  $("#child-4-standing").animate(
                    { bottom: "30%" },
                    750,
                    function () {
                      $("#pzo-bus-child-4").fadeIn();
                    }
                  );
                });
                // hide standing passengers
                setTimeout(function () {
                  $(".passenger-standing").hide();
                }, 1000);
              });
            });
            // show form
            setTimeout(function () {
              // bus leave
              $(".bus-animate").animate({ right: "-30%" }, 2400, function () {
                // show form
                self.showForm();
              });
            }, 5000);
          }, 2200);
        }, 1000);
      }, 900);
    }
  };

  // prepare steps
  this.prepareStep = function () {
    // step 1
    if (1 === self.step) {
      $(".bus-animate").css("right", "-30%");
    }
    // step 2
    if (2 === self.step) {
      // set begin date
      $("#travel-begin-date").val(moment().add(0, "day").format("DD.MM.YYYY"));
      // set proper images
      $("#pzo-bus-counterclock").hide();
      $(".bus-passenger").removeClass("counterclock");
      $("#pzo-bus").show();
      $(".passenger-standing").hide();
      $(".bus-animate").css("right", "-30%");
      $(".bus-animate").show();
      $(".bus-passenger").show();
    }
    // step 3
    if (3 === self.step) {
      $("#flying-plane").css("right", "110%");
      $("#flying-plane").show();
    }
    // step 4
    if (4 === self.step) {
      // remove animate class from previous step
      $(".select2-container").removeClass("animated");
      $(".select2-container").removeClass("shake");
      // move tarmac plane to left
      $(".plane-animate").css("left", "-150%");
      $(".plane-animate").show();
      // counterclock bus and passengers
      $("#pzo-bus").hide();
      $("#pzo-bus-counterclock").show();
      $("#pzo-plane-closed").show();
      $(".bus-animate").css("right", "130%");
      $(".bus-passenger").addClass("counterclock");
      $(".bus-animate").show();
      $(".bus-passenger").hide();
      $(".plane-passenger").show();
      $(".plane-passengers").show();
      $(".passenger-standing").css("bottom", "80%");
    }
  };

  // switch step. this is triggered by step indicator click
  // or by returned validation error
  this.switchStep = function (step) {
    // check if step swithing allowed
    if (step > self.max_reached_step) {
      return;
    }
    // hide forms
    $(".form-box").fadeOut();
    $(".form-box").removeClass("animated flipOutX flipInX");
    // set step
    self.step = step;
    // change indicator
    $(".step-indicator").removeClass("active");
    $("#step-indicator-" + self.step).addClass("active");
    // show step
    if (1 == self.step) {
      $(".plane-animate").css("left", "-60%");
      $("#flying-plane").fadeOut();
      $(".pzo-clouds").fadeOut();
      $(".continent-background").fadeOut();
      $(".bkg-tarmac").fadeIn();
      $(".plane-passengers").fadeOut();
      $(".plane-passenger").fadeOut();
      $("#pzo-bus").fadeIn();
      $("#pzo-plane-opened").fadeIn();
      $(".plane-animate").fadeIn();
      $(".pzo-ground-items").fadeIn();
      $(".bus-animate").css("right", "-30%");
    }
    // show step
    if (2 == self.step) {
      $(".plane-animate").css("left", "-60%");
      $("#flying-plane").fadeOut();
      $(".pzo-clouds").fadeOut();
      $(".continent-background").fadeOut();
      $(".bkg-tarmac").fadeIn();
      $("#pzo-plane-opened").fadeIn();
      $(".plane-animate").fadeIn();
      $(".pzo-ground-items").fadeIn();
      $(".plane-passengers").fadeIn();
      $(".plane-passenger").fadeIn();
    }
    // show step
    if (3 == self.step) {
      $(".bkg-tarmac img, .bkg-tarmac div").fadeOut();
      $(".pzo-ground-items").fadeOut();
      $(".continent-background").fadeOut();
      $(".bkg-tarmac").fadeOut();
      $("#flying-plane").fadeIn();
      $(".pzo-clouds").fadeIn();
    }
    // show step
    if (4 == self.step) {
      $(".plane-animate").css("left", "-60%");
      $("#flying-plane").fadeOut();
      $(".pzo-clouds").fadeOut();
      $(".pzo-ground-items").fadeOut();
      $(".plane-passengers").fadeOut();
      $(".bkg-tarmac").fadeIn();
      $(".plane-animate").fadeIn();
      $("#pzo-plane-opened").fadeIn();
      $(
        "#" + self.getContinentByTravelDestination() + "-continent-background"
      ).fadeIn();
    }
    // show form
    self.showForm(1000);
  };

  // validate steps
  this.validateStep = function (ref) {
	
    // step 1
	if(!self.slideDownDone){
		self.chooseInsurances();
	}
    if($("#checklist-total").val() != '' || $("#checklist-total").html() != ''){
        $("#buy_insurance").prop('disabled','false');
    }
	
    if (1 === self.step) {
      // validate family policy
      if ("pzo-family" === self.shop_type) {
        // lock next
        self.lockNext();
        var number_of_persons = $(
          "input[name=number_of_persons]:checked"
        ).val();
        var number_of_persons_60 = $("#number-of-persons-60").val();
        var number_of_childs = $("#number-of-childs").val();
        // check number of persons
        if (
          (Math.floor(number_of_persons) !== number_of_persons &&
            !$.isNumeric(number_of_persons)) ||
          number_of_persons < 1 ||
          number_of_persons > 2
        ) {
          // set status to error
          self.statusError("number_of_persons");
          return false;
        }
        // set status to success
        self.statusSuccess("number_of_persons");
        // update type for 2nd person
        if (2 == number_of_persons) {
          // set proper #2 form type
          $('input[name="insured_person_type_2"]').val("insured-parent");
          // set proper max value for persons 60
          $("#number-of-persons-60").attr({
            max: 2,
          });
          // set proper max value for children
          $("#number-of-childs").attr({
            max: 8,
          });
        } else {
          // set proper #2 form type
          $('input[name="insured_person_type_2"]').val("insured-child");
          // set proper max value for persons 60
          $("#number-of-persons-60").attr({
            max: 1,
          });
          // set proper max value for children
          $("#number-of-childs").attr({
            max: 9,
          });
        }
        // check number of persons 60
        if (
          (Math.floor(number_of_persons_60) !== number_of_persons_60 &&
            !$.isNumeric(number_of_persons_60)) ||
          number_of_persons_60 < 0 ||
          number_of_persons_60 > number_of_persons
        ) {
          // set status to error
          self.statusError("number_of_persons_60");
          return false;
        }
        // set status to success
        self.statusSuccess("number_of_persons_60");
        // check number of childs
        if (
          (Math.floor(number_of_childs) !== number_of_childs &&
            !$.isNumeric(number_of_childs)) ||
          number_of_childs < 0 ||
          number_of_childs > 10 - number_of_persons
        ) {
          // set status to error
          self.statusError("number_of_childs");
          return false;
        }
        // validate possible odds
        if (number_of_persons == 1 && number_of_childs < 1) {
          // set status to error
          self.statusError("number_of_childs");
          return false;
        }
        // set status to success
        self.statusSuccess("number_of_childs");
        // unclock next button
        self.unlockNext();
      }
      // validate group policy
      if ("pzo-group" === self.shop_type) {
        // lock next
        self.lockNext();
        var number_of_persons = $("#number-of-persons").val();
        if (
          (Math.floor(number_of_persons) !== number_of_persons &&
            !$.isNumeric(number_of_persons)) ||
          number_of_persons < 3 ||
          number_of_persons > 99
        ) {
          // set status to error
          self.statusError("number_of_persons");
          return false;
        }
        // if family max number of persons is 10
        if ("pzo-family" === self.shop_type && number_of_persons > 10) {
          // set status to error
          self.statusError("number_of_persons");
          return false;
        }
        // set status to success
        self.statusSuccess("number_of_persons");
        // check for groups
        if ("pzo-group" === self.shop_type) {
          var number_of_persons_60 = $("#number-of-persons-60").val();
          if (
            (Math.floor(number_of_persons_60) !== number_of_persons_60 &&
              !$.isNumeric(number_of_persons_60)) ||
            parseInt(number_of_persons_60) > parseInt(number_of_persons) ||
            parseInt(number_of_persons_60) < 0
          ) {
            // set status to error
            self.statusError("number_of_persons_60");
            return false;
          }
          // set status to success
          self.statusSuccess("number_of_persons_60");
        }
        // set number of persons
        self.number_of_persons = parseInt(number_of_persons);
      }
      //step2
      // get current begin date
      var begin_date = $("#travel-begin-date").val();
      var end_date = $("#travel-end-date").val();
      // check if valid
      // begin date
      if (!moment(begin_date, "DD.MM.YYYY").isValid()) {
        // set status to error
        self.statusError("travel_begin_date");
        return false;
      } else {
        // set status to success
        self.statusSuccess("travel_begin_date");
        // check travel period type and if is 'year'
        // then set end date = begin date + 1 year
        if ("year" === self.travel_period_type) {
          // set end date +1 year
          $("#travel-end-date").val(
            moment(begin_date, "DD.MM.YYYY").add(1, "year").format("DD.MM.YYYY")
          );
          // validate number of days
          // if value isn't greater than 0 mark as error
          if (parseInt($("#travel-number-of-days").val()) < 1) {
            // set status to error
            self.statusError("travel_number_of_days");
            return false;
          }
        }
      }
      // check if valid
      // end date
      if (!moment(end_date, "DD.MM.YYYY").isValid()) {
        // set status to error
        self.statusError("travel_end_date");
        return false;
      } else {
        // check if end date is after begin date
        // formated
        var begin_date_formated = moment(begin_date, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        );
        var end_date_formated = moment(end_date, "DD.MM.YYYY").format(
          "YYYY-MM-DD"
        );
        // check if after begin date
        if (
          !moment(end_date_formated).isAfter(begin_date_formated) &&
          end_date_formated != begin_date_formated
        ) {
          // set status to rrror
          self.statusError("travel_end_date");
          return false;
        } else {
          // check if max period 365 days
          var max_end_date_formated = moment(begin_date, "DD.MM.YYYY")
            .add(1, "years")
            .format("YYYY-MM-DD");
          // check if end date less than max end date
          if (moment(end_date_formated).isAfter(max_end_date_formated)) {
            // set status to rrror
            self.statusError("travel_end_date");
            return false;
          } else {
            // set status to success
            self.statusSuccess("travel_end_date");
          }
        }

      
        //step3
        // get vals
        var travel_destination = $(
          "input[name=travel_destination]:checked"
        ).val();
       
        var policy_limit = $("#policy-limit").val();
        // check if country selected
        if ("europe" === travel_destination || "world" === travel_destination) {
          // set status to success
          self.statusSuccess("travel-destination");
        } else {
          self.statusError("travel-destination");
          return false;
        }
        
        self.isCovidAllowanceShown();
        // check policy limit
        // get clicked radio button value
        if (
          typeof policy_limit == "undefined" ||
          policy_limit == "NaN" ||
          parseInt(policy_limit) <= 0
        ) {
          self.statusError("policy_limit");
          return false;
        }
        // set status to success
        self.statusSuccess("policy_limit");
        
        
        // get vals
        var insurance_death_offer = $(
          "input[name=insurance_death_offer]:checked"
        ).val();
        // check insurance death offer
        // get clicked radio button value
        if (
          typeof insurance_death_offer == "undefined" ||
          insurance_death_offer == "NaN" ||
          parseInt(insurance_death_offer) <= 0
        ) {
          self.statusError("insurance_death_offer");
          return false;
        }
        // unclock next button
       // self.unlockNext();
      }
    }
   
  };

  // show current step form
  this.showForm = function (delay_seconds, step) {
    // check if explicit step selected
    if (typeof step === "undefined") {
      // use current step
      step = self.step;
    }
    // show current step form
    var showFormTimer = setTimeout(function () {
      $("#form-box-" + step)
        .show()
        .addClass("animated flipInX");
      // lock next button because form changed
      self.lockNext();
    }, delay_seconds);
    // validate step if we already have been there
    if (self.step < self.max_reached_step) {
      // validate step
      setTimeout(function () {
        self.validateStep();
      }, 1500 + delay_seconds);
    }
  };

  // hide form
  this.hideForm = function () {
    // hide current step form
    $("#form-box-" + self.step).addClass("animated flipOutX");
  };

  // status success
  this.statusSuccess = function (id) {
    // set status to success for passed id
	$("[name="+id+"]").addClass('has-success');
    $("#status-" + id).removeClass("error");
    $("#status-" + id).addClass("ok");
	$('label[for="'+id+'"]').css('color','#666');
  };
  // status error
   // status error
   this.statusError = function (id, message='') {
        // set status to error for passed id
        $('#status-' + id).removeClass('ok');
        $('#status-' + id).addClass('error');
		key = id.replaceAll('_','-');
	
		$("[name="+id+"]").addClass('has-error');
		if(self.buttonBuy == true && (document.getElementById(key) != null)){
		
			document.getElementById(key).scrollIntoView({ behavior: "smooth" });
			$('label[for="'+id+'"]').css('color','red');
		}
		self.buttonBuy=false;
        // set error message
        $('#errors-' + id).html(message);
    }
  // status neutral
  this.statusNeutral = function (id) {
    // set status to neutral for passed id
    $("#status-" + id).removeClass("ok");
    $("#status-" + id).removeClass("error");
  };

  // get data common name
  this.commonName = function (ref) {
    return $(ref).data("common-name");
  };

  // unlock next step button
  this.unlockNext = function () {
    $(".pzo-next").removeClass("disabled");
  };
  // lock next step button
  this.lockNext = function () {
    $(".pzo-next").addClass("disabled");
  };

  // get policy types structure by current shop id
  // policy types are daily or yearly and types like tourist or business travel
  this.policyTypesByShopID = function () {
    // remove all insurance types and policy types controls and values
    $("#travel-intent-container").slideUp();
    // $('#travel-period-container').slideUp();
    $("#travel-number-of-days-container").slideUp();
    $(".insurance_type_item_container").hide();
    // unselect all radio buttons and select values
    $(".travel-period-type").removeClass("checked");
    $("input:radio[name=travel_period_type]").prop("checked", false);
    self.travel_period_type = null;
    // if family or group then there is only 1 choice
    // so select it by default
    if ("pzo-family" === self.shop_type || "pzo-group" === self.shop_type) {
      $(".travel-period-type").addClass("checked");
      $("input:radio[name=travel_period_type][value=day]").prop(
        "checked",
        true
      );
      self.travel_period_type = "day";
      $("#travel-end-date").prop("disabled", false);
      $("#travel-number-of-days").empty();
    }
    // set ajax url and make ajax call
	var ajaxURL = URL + "/ajax/pzo/policy-types";
    var $_token = $("input[name=_token]").val();
    $.ajax({
      type: "POST",
      data: {
        shop_id: self.shop_id,
        _token: $("input[name=_token]").val(),
      },
      dataType: "json",
      url: ajaxURL,
      encode: true,
      headers: {
        "X-XSRF-TOKEN": $_token,
      },
      success: function (data) {
        // save returned values
        self.policy_types = data.policy_types;
        // display updated fields
        $("#travel-period-container").slideDown();
        // update fields
        // check if day radio button exists
        if (typeof data.policy_types.day !== "undefined") {
          $("#travel-period-type-day").show();
        }
        // check if year radio button exists
        if (typeof data.policy_types.year !== "undefined") {
          $("#travel-period-type-year").show();
        }
        // check if half-year radio button exists
        if (typeof data.policy_types.half_year !== "undefined") {
          $("#travel-period-type-half-year")
            .show();
        }
      },
    });
  };

  // fill travel intent select values for choosen travel period type
  this.policyTravelIntent = function () {
    // empty select
    $("#travel-intent").empty();
    $("#travel-number-of-days").empty();
    $("#travel-number-of-days-container").slideUp();
    // check choosen policy travel period type exists
    if (typeof self.policy_types[self.travel_period_type] !== "undefined") {
      // loop all possible types and fill travel intent select2 box
      $.each(self.policy_types[self.travel_period_type], function (key, value) {
        $("#travel-intent").select2({
          data: [
            {
              id: value.id,
              text: value.name,
            },
          ],
          minimumResultsForSearch: -1,
        });
      });
      // display select
      $("#travel-intent-container").slideDown().addClass("animated jello");
      // check annual number of days select
      if (
        self.travel_period_type === "year" &&
        typeof self.policy_types.annual_duration_types !== "undefined"
      ) {
        // initial value
        $("#travel-number-of-days").select2({
          data: [
            {
              id: 0,
              text: self.labels.select,
            },
          ],
          minimumResultsForSearch: -1,
        });
        // loop all possible types and fill travel intent select2 box
        $.each(self.policy_types.annual_duration_types, function (key, value) {
          $("#travel-number-of-days").select2({
            data: [
              {
                id: value.id,
                text: value.name,
              },
            ],
            minimumResultsForSearch: -1,
          });
        });
        // display select
        $("#travel-number-of-days-container")
          .slideDown()
          .addClass("animated flipInX");
        // lock end date input timepicker because it's fixed when year period is set
        $("#travel-end-date").prop("disabled", true);
      }
    }
    // unlock end date input timepicker if travel_period_type is day
    if ("day" === self.travel_period_type) {
      $("#travel-end-date").prop("disabled", false);
    }
  };
  // catch travel destination changed
  $(".travel-destination").on("click", function () {
    // get common name and set status to success
    self.statusSuccess(self.commonName(this));
    // get clicked radio button value
    var id = $("input[name=travel_destination]:checked").val();
    // check if radio value changed
    if (id !== self.travel_destination) {
      self.travel_destination = id;
      $("#status-policy_limit").removeClass("ok");
      // lock select box
      $("#policy-limit").prop("disabled", true);
      // get policy limits
      self.policyLimits();
      // animate
      /*setTimeout(function () {
        $(".select2-container").addClass("animated shake");
      }, 100);*/
    }
  });

  // get policy limits
  this.policyLimits = function () {
    // empty select
    $("#policy-limit").empty();
    // set ajax url and make ajax call
    var ajaxURL = URL + "/ajax/pzo/policy-limits";
    var $_token = $("input[name=_token]").val();
    $.ajax({
      type: "POST",
      data: {
        shop_id: self.shop_id,
        continent: self.travel_destination,
        _token: $("input[name=_token]").val(),
      },
      dataType: "json",
      url: ajaxURL,
      encode: true,
      headers: {
        "X-XSRF-TOKEN": $_token,
      },
      success: function (data) {
        // loop all possible limits and fill travel intent select2 box
        $.each(data.policy_limits, function (key, value) {
          $("#policy-limit").select2({
            data: [
              {
                id: value.id,
                text: value.name,
              },
            ],
            minimumResultsForSearch: 10,
          });
        });
        // unlock select box
        $("#policy-limit").prop("disabled", false);
        // validate step
        self.validateStep();
      },
    });
  };

  // display proper number of animated persons
  this.displayProperNumberOfPersons = function () {
    // check if individual
    if ("pzo-individual" === self.shop_type) {
      $("#pzo-bus-mom").addClass("hide");
      $("#pzo-bus-child-1").addClass("hide");
      $("#pzo-bus-child-2").addClass("hide");
      $("#pzo-bus-child-3").addClass("hide");
      $("#pzo-bus-child-4").addClass("hide");
      $("#pzo-plane-mom").addClass("hide");
      $("#pzo-plane-child-1").addClass("hide");
      $("#pzo-plane-child-2").addClass("hide");
      $("#pzo-plane-child-3").addClass("hide");
      $("#pzo-plane-child-4").addClass("hide");
      $("#mom-standing").addClass("hide");
      $("#child-1-standing").addClass("hide");
      $("#child-2-standing").addClass("hide");
      $("#child-3-standing").addClass("hide");
      $("#child-4-standing").addClass("hide");
    } else {
      // disable family persons
      if ("pzo-family" === self.shop_type) {
        // reset
        $("#pzo-bus-mom").addClass("hide");
        $("#pzo-bus-child-1").addClass("hide");
        $("#pzo-bus-child-2").addClass("hide");
        $("#pzo-bus-child-3").addClass("hide");
        $("#pzo-bus-child-4").addClass("hide");
        $("#pzo-plane-mom").addClass("hide");
        $("#pzo-plane-child-1").addClass("hide");
        $("#pzo-plane-child-2").addClass("hide");
        $("#pzo-plane-child-3").addClass("hide");
        $("#pzo-plane-child-4").addClass("hide");
        $("#mom-standing").addClass("hide");
        $("#child-1-standing").addClass("hide");
        $("#child-2-standing").addClass("hide");
        $("#child-3-standing").addClass("hide");
        $("#child-4-standing").addClass("hide");
        // if number of adults 2 then show mom
        if (2 == $("input[name=number_of_persons]:checked").val()) {
          $("#pzo-bus-mom").removeClass("hide");
          $("#pzo-plane-mom").removeClass("hide");
          $("#mom-standing").removeClass("hide");
        }
        // loop childs
        for (var i = 0; i < $("#number-of-childs").val(); i++) {
          $("#pzo-bus-child-" + (i + 1)).removeClass("hide");
          $("#pzo-plane-child-" + (i + 1)).removeClass("hide");
          $("#child-" + (i + 1) + "-standing").removeClass("hide");
        }
      }
      // disable group persons
      if ("pzo-group" === self.shop_type) {
        // reset
        $("#pzo-bus-child-2").removeClass("hide");
        $("#pzo-bus-child-3").removeClass("hide");
        $("#pzo-bus-child-4").removeClass("hide");
        $("#pzo-plane-child-2").removeClass("hide");
        $("#pzo-plane-child-3").removeClass("hide");
        $("#pzo-plane-child-4").removeClass("hide");
        $("#child-2-standing").removeClass("hide");
        $("#child-3-standing").removeClass("hide");
        $("#child-4-standing").removeClass("hide");
        // loop
        for (var i = 6; i > self.number_of_persons; i--) {
          if (i > 3) {
            $("#pzo-bus-child-" + (i - 2)).addClass("hide");
            $("#pzo-plane-child-" + (i - 2)).addClass("hide");
            $("#child-" + (i - 2) + "-standing").addClass("hide");
          }
        }
      }
    }
  };

  // calculate animated div height
  this.calculateCanvasHeight = function () {
    // header height offset
    var headerHeight = 102;
    // get current document height and subtract header height
    var newHeight = $(window).height() - headerHeight;
    // phone corrections
    if ($(document).width() < 900) {
      newHeight += 90;
    }
    // subtract 10% from document size for bottom offset
    newHeight = newHeight - (12 * newHeight) / 100;
    // set new height
    $(".pzo-animated").css("height", newHeight + "px");
    $("#pzo-animated-container").css("height", newHeight + "px");
  };

  // return continent by travel destination (world is random)
  this.getContinentByTravelDestination = function () {
    if ("europe" === self.travel_destination) {
      return "europe";
    } else {
      var world = [
        "northamerica",
        "southamerica",
        "asia",
        "africa",
        "australia",
      ];
      return world[Math.floor(Math.random() * world.length)];
    }
  };

  this.isCovidAllowanceShown = function () {
	
    let travel_destination = $("input[name=travel_destination]:checked").val();
    let begin_date = moment($("#travel-begin-date").val(), "D/M/YYYY");
    let end_date = moment($("#travel-end-date").val(), "D/M/YYYY");
    const diffDays = end_date.diff(begin_date, "days");
    let clicked_value = $("input[name=travel_period_type]:checked").val();
    if (
      travel_destination !== "europe" ||
      diffDays > 30 ||
      clicked_value == "year"
    ) {
      $(".allowance-covid-form").css("display", "none");
      $("#allowance_covid_no").prop("checked", true);
    } else {
	
      $(".allowance-covid-form").css("display", "block");
	  if(self.covidShown == false){
		$("#allowance_covid_yes").prop("checked", true);
		self.covidShown = true;
	  }
      
    }
  };

    this.chooseInsurances = function () {
   
    if(
      ($("[name=allowance_ages]").is(":checked") || ($("[name=number_of_persons]").is(":checked")
      && $("[name=number_of_persons_60]").val() != '' && $("[name=number_of_childs]").val() != '') 
      || $("[name=number_of_persons]").val() != '') &&
      $("[name=travel_period_type]").is(":checked") &&
      $("#travel-begin-date").val() != "" &&
      $("#travel-end-date").val() != "" &&
	  $("[name=allowance_sport_ski]").is(":checked") && 
      $("[name=travel_destination]").val() != '' &&
      $("#policy-limit").val() != "" && $("#policy-limit").val() != "-1" &&
      $("[name=policy_intent]").val() != ""
    ) {
     
      $("#invoice_submit_button").show();
       $("#form-box-2").slideDown("slow", function () {
        // scroll to the shown form
        smoothScroll.animateScroll(null, "#form-box-2", { offset: 50 });
       
      });
		self.slideDownDone = true;
    }
	
  };  

  $("#buy_insurance").on("click", function () {
	$('#pzo-active-view').val('view-checklist');
	self.buttonBuy=true;
	self.calculate('view-checklist');
    
  });
  
  $("#finish_order").on("click", function () {
	$('#pzo-active-view').val('view-review');
	self.calculate('view-review');
    
  });

  this.fillForm = function(data){

    $('#review-policy-type').html(data.review.policy_type);
    $('#review-name').html(data.review.insured.first_name + ' ' + data.review.insured.last_name);
    $('#review-birthdate').html(data.review.insured.birthdate);
    $('#review-jmbg').html(data.review.insured.jmbg);
    $('#review-passport').html(data.review.insured.passport);
    $('#review-street').html(data.review.insured.street + ' ' + data.review.insured.street_number);
    $('#review-city').html(data.review.insured.city);
    $('#review-district').html(data.review.insured.district);
    $('#review-phone').html(data.review.insured.phone);
    $('#review-number-of-persons').html(data.review.number_of_persons);
    $('#review-policy-limit').html(data.review.policy_limit);
    $('#review-travel-begin').html(data.review.begin_date);
    $('#review-travel-end').html(data.review.end_date);
    $('#review-days').html(data.review.days);
	$('#review-luggage-person').html(data.review.number_of_persons);
	$('#review-luggage-price').html(data.review.luggage_price);
	$('#review-luggage-total').html(data.review.luggage_total);
	
	//canceled-travel
	$('#review-canceled-travel-person').html(data.review.number_of_persons);
	$('#review-canceled-travel-price').html(data.review.canceled_travel_price);
	$('#review-canceled-travel-total').html(data.review.canceled_travel_total);
    // total
    $('#review-total').html(data.review.total);
    // premium per day/total
    $('#review-premium-per-price').html(data.review.base_premium.price);
    $('#review-premium-per-person').html(data.review.base_premium.persons);
    $('#review-premium-per-total').html(data.review.base_premium.total);
    // allowance age
    $('#review-age-price').html(data.review.allowances['pzo-ages-70'].price);
    $('#review-age-person').html(data.review.allowances['pzo-ages-70'].persons);
    $('#review-age-total').html(data.review.allowances['pzo-ages-70'].total);
    if ('-' == data.review.allowances['pzo-ages-70'].total) {
        $('#review-pzo-ages').hide();
    }
    // allowance sport ski
    $('#review-sport-price').html(data.review.allowances['pzo-sport-ski'].price);
    $('#review-sport-person').html(data.review.allowances['pzo-sport-ski'].persons);
    $('#review-sport-total').html(data.review.allowances['pzo-sport-ski'].total);
    if ('-' == data.review.allowances['pzo-sport-ski'].total) {
        $('#review-pzo-sport-ski').hide();
    }
    // allowance covid
    $('#review-covid-price').html(data.review.allowances['pzo-covid'].price);
    $('#review-covid-person').html(data.review.allowances['pzo-covid'].persons);
    $('#review-covid-total').html(data.review.allowances['pzo-covid'].total);
     if ('-' == data.review.allowances['pzo-covid'].total) {
        $('#review-pzo-covid').hide();
    } 
    // allowance job
    $('#review-job-price').html(data.review.allowances['pzo-job'].price);
    $('#review-job-person').html(data.review.allowances['pzo-job'].persons);
    $('#review-job-total').html(data.review.allowances['pzo-job'].total);
    if ('-' == data.review.allowances['pzo-job'].total) {
        $('#review-pzo-job').hide();
    }
    // insurance death
    $('#review-death-price').html('-');
    $('#review-death-person').html(data.review.insurances['pzo-death-insurance'].persons);
    $('#review-death-total').html(data.review.insurances['pzo-death-insurance'].total);
    if ('-' == data.review.insurances['pzo-death-insurance'].total) {
        $('#review-pzo-insurance-death').hide();
    }
    // discount online purchase
    $('#review-online-purchase-price').html(data.review.discounts['online-purchase'].price);
    $('#review-online-purchase-person').html(data.review.discounts['online-purchase'].persons);
    $('#review-online-purchase-total').html(data.review.discounts['online-purchase'].total);
    // discount loyalty
    $('#review-loyalty-price').html(data.review.discounts['loyalty'].price);
    $('#review-loyalty-person').html(data.review.discounts['loyalty'].persons);
    $('#review-loyalty-total').html(data.review.discounts['loyalty'].total);
    if ('-' == data.review.discounts['loyalty'].total) {
        $('#review-pzo-loyalty').hide();
    }
    // check family group discount
    if ('pzo-family' == self.shop) {
        // set proper discount name
        $('#label-review-pzo-group').html(data.review.discounts[data.review.pzo_family_group_discount_key].name);
        // display review
        $('#review-pzo-group').removeClass('hide');
        // discount group
        $('#review-group-price').html(data.review.discounts[data.review.pzo_family_group_discount_key].price);
        $('#review-group-person').html(data.review.discounts[data.review.pzo_family_group_discount_key].persons);
        $('#review-group-total').html(data.review.discounts[data.review.pzo_family_group_discount_key].total);
    }
    // check group discount
    if ('pzo-group' == self.shop) {
        // display review
        $('#review-pzo-group').removeClass('hide');
        // discount group
        $('#review-group-price').html(data.review.discounts[data.review.pzo_group_discount_key].price);
        $('#review-group-person').html(data.review.discounts[data.review.pzo_group_discount_key].persons);
        $('#review-group-total').html(data.review.discounts[data.review.pzo_group_discount_key].total);
    }
    // hide form view
    $('#pzo-form-persons-container').fadeOut(600);
    setTimeout(function () {
        $('#pzo-form-review-container').show().addClass('animated slideInLeft');
    }, 800);
    // set active view to review
    $('#pzo-active-view').val('view-review');
    // check if set redirect url
    if (null != data.redirect_url) {
        // lock next
       // self.lockNext();
        // redirect
       // window.location.href = data.redirect_url;
    }
  } 

  $("[name=insurance_canceled_travel]").on('change',function(){
      if($("[name=insurance_canceled_travel]").prop('checked') == true){
          $("#canceled-travel-yes").css('display','block');
      }else{
        $("#canceled-travel-yes").css('display','none');
      }

  });

  $("[name=travel_period_type]").on('change',function(){
	
    let travelType = $("input[name=travel_period_type]:checked").val();
    if(travelType == 'year'){
        $("#canceled-item").css('display','none');
		$("#canceled_yes").prop('checked',false);
		$("#radio-travel-yes").removeClass('checked');
    }else{
        $("#canceled-item").css('display','block');
		$("#canceled-travel-yes").css('display','none');
		$("#canceled_no").prop('checked',true);
		$("#radio-travel-no").addClass('checked');
    }
	self.calculate();
  });

  $("[name=insurance_lost_luggage]").on('change',function(){
      let luggageOption = $("[name=insurance_lost_luggage]").val();
      if(luggageOption != '0' && luggageOption != '-1'){
		$("#additional_options").removeAttr('hidden');
	  }else{
		$("#additional_options").attr('hidden',true);
	  }
  });
  
  // submit form data
    this.submitForm = function (paymentSlug) {
        // remove unexpected error message
        $('#pzo-form-errors').html('');
        $('#pzo-review-errors').html('');
        // lock next
        self.lockNext();
        var formData = $('#pzo-form').serialize() + '&g_recaptcha_token=' + TCApp.InvisibleRecaptcha.token + '&payment_slug=' + paymentSlug;
        // set ajax url and make ajax call
        if (window.location.href.indexOf("putno-osiguranje-agencije") > -1) {
            var ajaxURL = URL + "/ajax/pzo-tourist/processing";
        } else {
            var ajaxURL = URL + "/ajax/pzo/processing";
        }
        var $_token = $("input[name=_token]").val();
        $.ajax({
            type: "POST",
            data: formData,
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            headers: {'X-XSRF-TOKEN': $_token},
            success: function (data) {
                // unlock next
               // self.unlockNext();
                // deactivate overlay screen
                if (data.success == true) {
                    // check if foreigner then set jmbg to dash
                    if (0 == data.review.insured.jmbg) {
                        data.review.insured.jmbg = '-';
                    }
                    // everything ok display review page
                    $('#review-policy-type').html(data.review.policy_type);
                    $('#review-name').html(data.review.insured.first_name + ' ' + data.review.insured.last_name);
                    $('#review-birthdate').html(data.review.insured.birthdate);
                    $('#review-jmbg').html(data.review.insured.jmbg);
                    $('#review-passport').html(data.review.insured.passport);
                    $('#review-street').html(data.review.insured.street + ' ' + data.review.insured.street_number);
                    $('#review-city').html(data.review.insured.city);
                    $('#review-district').html(data.review.insured.district);
                    $('#review-phone').html(data.review.insured.phone);
                    $('#review-number-of-persons').html(data.review.number_of_persons);
                    $('#review-policy-limit').html(data.review.policy_limit);
                    $('#review-travel-begin').html(data.review.begin_date);
                    $('#review-travel-end').html(data.review.end_date);
                    $('#review-days').html(data.review.days);
                    // total
                    $('#review-total').html(data.review.total);
                    // premium per day/total
                    $('#review-premium-per-price').html(data.review.base_premium.price);
                    $('#review-premium-per-person').html(data.review.base_premium.persons);
                    $('#review-premium-per-total').html(data.review.base_premium.total);
                    // allowance age
                    $('#review-age-price').html(data.review.allowances['pzo-ages-70'].price);
                    $('#review-age-person').html(data.review.allowances['pzo-ages-70'].persons);
                    $('#review-age-total').html(data.review.allowances['pzo-ages-70'].total);
                    if ('-' == data.review.allowances['pzo-ages-70'].total) {
                        $('#review-pzo-ages').hide();
                    }
                    // allowance sport ski
                    $('#review-sport-price').html(data.review.allowances['pzo-sport-ski'].price);
                    $('#review-sport-person').html(data.review.allowances['pzo-sport-ski'].persons);
                    $('#review-sport-total').html(data.review.allowances['pzo-sport-ski'].total);
                    if ('-' == data.review.allowances['pzo-sport-ski'].total) {
                        $('#review-pzo-sport-ski').hide();
                    }
                     // allowance covid
                    $('#review-covid-price').html(data.review.allowances['pzo-covid'].price);
                    $('#review-covid-person').html(data.review.allowances['pzo-covid'].persons);
                    $('#review-covid-total').html(data.review.allowances['pzo-covid'].total);
                   
                    if ('-' == data.review.allowances['pzo-covid'].total) {
                        $('#review-pzo-covid').hide();
                    }  
                    // allowance job
                    $('#review-job-price').html(data.review.allowances['pzo-job'].price);
                    $('#review-job-person').html(data.review.allowances['pzo-job'].persons);
                    $('#review-job-total').html(data.review.allowances['pzo-job'].total);
                    if ('-' == data.review.allowances['pzo-job'].total) {
                        $('#review-pzo-job').hide();
                    }
                    // insurance death
                    $('#review-death-price').html('-');
                    $('#review-death-person').html(data.review.insurances['pzo-death-insurance'].persons);
                    $('#review-death-total').html(data.review.insurances['pzo-death-insurance'].total);
                    if ('-' == data.review.insurances['pzo-death-insurance'].total) {
                        $('#review-pzo-insurance-death').hide();
                    }
					
					if ('-' == data.review.insurances[data.review.luggage_price]) {
                        $('#review-pzo-luggage').hide();
                    }else{
						$('#review-luggage-person').html(data.review.number_of_persons);
						$('#review-luggage-price').html(data.review.luggage_price);
						$('#review-luggage-total').html(data.review.luggage_total);
					}
					//canceled-travel
					if ('-' == data.review.insurances[data.review.canceled_travel_price]) {
                        $('#review-pzo-canceled-travel').hide();
                    }else{
						$('#review-canceled-travel-person').html(data.review.number_of_persons);
						$('#review-canceled-travel-price').html(data.review.canceled_travel_price);
						$('#review-canceled-travel-total').html(data.review.canceled_travel_total);
					}
					
					
                    // discount online purchase
                    $('#review-online-purchase-price').html(data.review.discounts['online-purchase'].price);
                    $('#review-online-purchase-person').html(data.review.discounts['online-purchase'].persons);
                    $('#review-online-purchase-total').html(data.review.discounts['online-purchase'].total);
                    // discount loyalty
                    $('#review-loyalty-price').html(data.review.discounts['loyalty'].price);
                    $('#review-loyalty-person').html(data.review.discounts['loyalty'].persons);
                    $('#review-loyalty-total').html(data.review.discounts['loyalty'].total);
                    if ('-' == data.review.discounts['loyalty'].total) {
                        $('#review-pzo-loyalty').hide();
                    }
                    // check family group discount
                    if ('pzo-family' == self.shop) {
                        // set proper discount name
                        $('#label-review-pzo-group').html(data.review.discounts[data.review.pzo_family_group_discount_key].name);
                        // display review
                        $('#review-pzo-group').removeClass('hide');
                        // discount group
                        $('#review-group-price').html(data.review.discounts[data.review.pzo_family_group_discount_key].price);
                        $('#review-group-person').html(data.review.discounts[data.review.pzo_family_group_discount_key].persons);
                        $('#review-group-total').html(data.review.discounts[data.review.pzo_family_group_discount_key].total);
                    }
                    // check group discount
                    if ('pzo-group' == self.shop) {
                        // display review
                        $('#review-pzo-group').removeClass('hide');
                        // discount group
                        $('#review-group-price').html(data.review.discounts[data.review.pzo_group_discount_key].price);
                        $('#review-group-person').html(data.review.discounts[data.review.pzo_group_discount_key].persons);
                        $('#review-group-total').html(data.review.discounts[data.review.pzo_group_discount_key].total);
                    }
                    // hide form view
                    $('#pzo-form-persons-container').fadeOut(600);
                    setTimeout(function () {
                        $('#pzo-form-review-container').show().addClass('animated slideInLeft');
                    }, 800);
                    // set active view to review
                    $('#pzo-active-view').val('view-payment');
                    // check if set redirect url
                    if (null != data.redirect_url) {
                        // lock next
                        self.lockNext();
                        // redirect
                        // console.log('redirect'+data.redirect_url);
                        window.location.href = data.redirect_url;
                    }
                } else {
                    if (null != data.redirect_url) {
                        // lock next
                        self.lockNext();
                        // redirect
                        // console.log('redirect'+data.redirect_url);
                        window.location.href = data.redirect_url;
                    }
                    // console.log(data);
                    // check if unexpected error
                    if (true === data.unexpected_error) {
                         let consentCheckbox = document.getElementById(
                           "terms-conditions"
                         );
                         consentCheckbox.classList.add(
                           "cb-prihvatite-uslove-up"
                         );
                         document.getElementById(
                           "pzo-terms-conditions"
                         ).style.color = "#005ca9";

                         document.getElementById(
                           "pzo-terms-conditions"
                         ).style.fontWeight = "bold";

                         setTimeout(function () {
                           consentCheckbox.classList.remove(
                             "cb-prihvatite-uslove-up"
                           );
                           consentCheckbox.classList.add(
                             "cb-prihvatite-uslove-down"
                           );
                           setTimeout(function () {
                             consentCheckbox.classList.remove(
                               "cb-prihvatite-uslove-down"
                             );
                             document.getElementById(
                               "pzo-terms-conditions"
                             ).style.color = "#666";

                             document.getElementById(
                               "pzo-terms-conditions"
                             ).style.fontWeight = "bold";
                           }, 200);
                         }, 500);
                        $('#pzo-form-errors').html(data.errors);
                        $('#pzo-review-errors').html(data.errors);
                        return false;
                    }
                    // loop errors
                    data.errors = $.parseJSON(data.errors);
                    if ('home_quadrature' in data.errors) {
                        $('#home_quadrature_sprite').removeClass('ok').addClass('error');
                        $('#home_quadrature_box .errors').html(data.errors['home_quadrature']);
                    }
                    else {
                        $('#home_quadrature_box .errors').removeClass('error').addClass('ok');
                    }
                    
                    $.each(data.errors, function (key, value) {
                        // prepare message
                        var key_replace = key.replace('_', ' ');
                        value[0] = value[0].replace(key_replace, '');
                        // set error status to returned error fields
                        self.statusError('insured_' + key + '_' + data.iteration_number, value[0]);
                    });
                    // smooth scroll to error part
                   /*  setTimeout(function () {
                        smoothScroll.animateScroll(null, '#pzo-form-insured-' + data.iteration_number, {"offset": 0});
                    }, 400); */
                }
            }
        });
    }
    
	$(".pzo-form-review-prev").on('click',function(){
		$("#finish_order").removeClass('disabled');
		$('#pzo-form-review-container').slideUp();
		$("#pzo-form-insurance-container").slideDown();
	});
	
	$(".pzo-form-insurance-data-prev").on('click',function(){
		$("#finish_order").removeClass('disabled');
		$("#pzo-form-insurance-container").slideUp();
		$("#pzo-animated-forms-container").slideDown();
	});
	
	$("[name=policy_limit]").on('change',function(){
		if($(this).val() != '-1'){
			$("#amounts_additional_options").removeClass('hidden');
			let destination = $("[name=travel_destination]:checked").val();
			if(destination == "europe"){
				if($(this).val() == "1500000"){
					$("#lost_documents").html('100,00');
					$("#law_help").html('200,00');
					$("#money_transfer").html('500,00');
				}else if($(this).val() == "3000000"){
					$("#lost_documents").html('150,00');
					$("#law_help").html('300,00');
					$("#money_transfer").html('1000,00');
				}else{
					$("#lost_documents").html('200,00');
					$("#law_help").html('500,00');
					$("#money_transfer").html('2000,00');
				}
			}else{
				if($(this).val() == "3000000"){
					$("#lost_documents").html('200,00');
					$("#law_help").html('500,00');
					$("#money_transfer").html('2000,00');
				}
			}
		}else{
			$("#amounts_additional_options").addClass('hidden');
		}
		
	});
 

}
