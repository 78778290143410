function PZO() {
    // declarations
    var self = this;
    this.shop = 'pzo-individual';
    this.shop_id = 2;
    this.travel_period_type = null;
    this.shop_types = [];
    this.policy_types = [];
    this.labels = [];

    // listeners
    this.listeners = function () {
        console.log('listeners');
        self.submitFormListeners('');
        
        // initial calls
        $(window).load(function () {
            // submit form listeners
            self.submitFormListeners();
            // next form data
            self.nextForm();
            // prev form data
            self.prevForm();
            // ajax single field validation
            $('.ajax-validate').on('blur', function () {
                // check if disabled
                if ($(this).hasClass('disabled')) {
                    return false;
                }
                // console.log(this)
                // because of datepicker
                // we haven't imediatelly new date value
                // so datepicker validation isn't corrent but after we add
                // timeout that should work ok
                if ($(this).hasClass('datepicker') || $(this).hasClass('datepicker-birthdate')) {
                    // console.log('ima klasu')
                    var ref = this;
                    setTimeout(function () {
                        self.validateField(ref, 'text');
                    }, 600);
                } else {
                    // otherwise validate simple text input
                    self.validateField(this, 'text');
                }
                // lock date of birth if domestic user and jmbg set
                self.lockBirthdate(this);
            });
            // ajax single field validation
            $('select.ajax-validate').on('change', function () {
                // check if disabled
                if ($(this).hasClass('disabled')) {
                    return false;
                }
                // validate
                self.validateField(this, 'dropdown');
            });
            // ajax single field validation
            $('.prettyradio .ajax-validate').on('click', function () {
                // check if disabled
                if ($(this).hasClass('disabled')) {
                    return false;
                }
                // validate
                self.validateField(this, 'radio');
                // lock date of birth if domestic user and jmbg set
                self.lockBirthdate(this);
            });
            // toggle family and group insured residence block
            self.residenceOverrideToggle();
            // hide jmbg if foreign person
            self.hideJMBG();
            // hide insured block if contractor == insured person
            self.hideContractorInsured();
        });
    }

    // submit form listeners
    this.submitFormListeners = function () {
        // catch next button clicked
        $('#pzo-form-next, .form-payment').on('click', function () {
            // check if last step
            if ('form-payment' === $(this).attr('id')) {
                // check if already submited
                if ($(this).hasClass('disabled')) {
                    return;
                }
                // check recaptcha
                if (!TCApp.InvisibleRecaptcha.verify(self.submitForm($(this).attr('data-payment-slug')))) {
                  
                    // enable submits
                    $(this).prop('disabled', false);
                    // trigger validation
                    window.grecaptcha.execute(TCApp.InvisibleRecaptcha.recaptchaID);
                    return;
                } else {
                    // ajax call
                    self.submitLead();
                }
            } else {
                // check if next available
                if ($(this).hasClass('disabled')) {
                    return false;
                }
                // submit form
                self.submitForm($(this).attr('data-payment-slug'));
            }
        });
    }

    // submit form data
    this.submitForm = function (paymentSlug) {
        // remove unexpected error message
        $('#pzo-form-errors').html('');
        $('#pzo-review-errors').html('');
        // lock next
        self.lockNext();
        var formData = $('#pzo-form').serialize() + '&g_recaptcha_token=' + TCApp.InvisibleRecaptcha.token + '&payment_slug=' + paymentSlug;
        // set ajax url and make ajax call
        if (window.location.href.indexOf("putno-osiguranje-agencije") > -1) {
            var ajaxURL = URL + "/ajax/pzo-tourist/processing";
        } else {
            var ajaxURL = URL + "/ajax/pzo/processing";
        }
        var $_token = $("input[name=_token]").val();
        $.ajax({
            type: "POST",
            data: formData,
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            headers: {'X-XSRF-TOKEN': $_token},
            success: function (data) {
               
                // unlock next
               // self.unlockNext();
                // deactivate overlay screen
                if (data.success == true) {

                    // console.log(data)
                    // check if foreigner then set jmbg to dash
                    if (0 == data.review.insured.jmbg) {
                        data.review.insured.jmbg = '-';
                    }
                    // everything ok display review page
                    $('#review-policy-type').html(data.review.policy_type);
                    $('#review-name').html(data.review.insured.first_name + ' ' + data.review.insured.last_name);
                    $('#review-birthdate').html(data.review.insured.birthdate);
                    $('#review-jmbg').html(data.review.insured.jmbg);
                    $('#review-passport').html(data.review.insured.passport);
                    $('#review-street').html(data.review.insured.street + ' ' + data.review.insured.street_number);
                    $('#review-city').html(data.review.insured.city);
                    $('#review-district').html(data.review.insured.district);
                    $('#review-phone').html(data.review.insured.phone);
                    $('#review-number-of-persons').html(data.review.number_of_persons);
                    $('#review-policy-limit').html(data.review.policy_limit);
                    $('#review-travel-begin').html(data.review.begin_date);
                    $('#review-travel-end').html(data.review.end_date);
                    $('#review-days').html(data.review.days);
                    // total
                    $('#review-total').html(data.review.total);
                    // premium per day/total
                    $('#review-premium-per-price').html(data.review.base_premium.price);
                    $('#review-premium-per-person').html(data.review.base_premium.persons);
                    $('#review-premium-per-total').html(data.review.base_premium.total);
                    // allowance age
                    $('#review-age-price').html(data.review.allowances['pzo-ages-70'].price);
                    $('#review-age-person').html(data.review.allowances['pzo-ages-70'].persons);
                    $('#review-age-total').html(data.review.allowances['pzo-ages-70'].total);
                    if ('-' == data.review.allowances['pzo-ages-70'].total) {
                        $('#review-pzo-ages').hide();
                    }
                    // allowance sport ski
                    $('#review-sport-price').html(data.review.allowances['pzo-sport-ski'].price);
                    $('#review-sport-person').html(data.review.allowances['pzo-sport-ski'].persons);
                    $('#review-sport-total').html(data.review.allowances['pzo-sport-ski'].total);
                    if ('-' == data.review.allowances['pzo-sport-ski'].total) {
                        $('#review-pzo-sport-ski').hide();
                    }
                  /*   // allowance covid
                    $('#review-covid-price').html(data.review.allowances['pzo-covid'].price);
                    $('#review-covid-person').html(data.review.allowances['pzo-covid'].persons);
                    $('#review-covid-total').html(data.review.allowances['pzo-covid'].total);
                    console.log(data.review.allowances);
                    if ('-' == data.review.allowances['pzo-covid'].total) {
                        $('#review-pzo-covid').hide();
                    }  */
                    // allowance job
                    $('#review-job-price').html(data.review.allowances['pzo-job'].price);
                    $('#review-job-person').html(data.review.allowances['pzo-job'].persons);
                    $('#review-job-total').html(data.review.allowances['pzo-job'].total);
                    if ('-' == data.review.allowances['pzo-job'].total) {
                        $('#review-pzo-job').hide();
                    }
                    // insurance death
                    $('#review-death-price').html('-');
                    $('#review-death-person').html(data.review.insurances['pzo-death-insurance'].persons);
                    $('#review-death-total').html(data.review.insurances['pzo-death-insurance'].total);
                    if ('-' == data.review.insurances['pzo-death-insurance'].total) {
                        $('#review-pzo-insurance-death').hide();
                    }
                    // discount online purchase
                    $('#review-online-purchase-price').html(data.review.discounts['online-purchase'].price);
                    $('#review-online-purchase-person').html(data.review.discounts['online-purchase'].persons);
                    $('#review-online-purchase-total').html(data.review.discounts['online-purchase'].total);
                    // discount loyalty
                    $('#review-loyalty-price').html(data.review.discounts['loyalty'].price);
                    $('#review-loyalty-person').html(data.review.discounts['loyalty'].persons);
                    $('#review-loyalty-total').html(data.review.discounts['loyalty'].total);
                    if ('-' == data.review.discounts['loyalty'].total) {
                        $('#review-pzo-loyalty').hide();
                    }
                    // check family group discount
                    if ('pzo-family' == self.shop) {
                        // set proper discount name
                        $('#label-review-pzo-group').html(data.review.discounts[data.review.pzo_family_group_discount_key].name);
                        // display review
                        $('#review-pzo-group').removeClass('hide');
                        // discount group
                        $('#review-group-price').html(data.review.discounts[data.review.pzo_family_group_discount_key].price);
                        $('#review-group-person').html(data.review.discounts[data.review.pzo_family_group_discount_key].persons);
                        $('#review-group-total').html(data.review.discounts[data.review.pzo_family_group_discount_key].total);
                    }
                    // check group discount
                    if ('pzo-group' == self.shop) {
                        // display review
                        $('#review-pzo-group').removeClass('hide');
                        // discount group
                        $('#review-group-price').html(data.review.discounts[data.review.pzo_group_discount_key].price);
                        $('#review-group-person').html(data.review.discounts[data.review.pzo_group_discount_key].persons);
                        $('#review-group-total').html(data.review.discounts[data.review.pzo_group_discount_key].total);
                    }
                    // hide form view
                    $('#pzo-form-persons-container').fadeOut(600);
                    setTimeout(function () {
                        $('#pzo-form-review-container').show().addClass('animated slideInLeft');
                    }, 800);
                    // set active view to review
                    $('#pzo-active-view').val('view-payment');
                    // check if set redirect url
                    if (null != data.redirect_url) {
                        // lock next
                        self.lockNext();
                        // redirect
                        // console.log('redirect'+data.redirect_url);
                        window.location.href = data.redirect_url;
                    }
                } else {
                   
                    if (null != data.redirect_url) {
                        // lock next
                        self.lockNext();
                        // redirect
                        // console.log('redirect'+data.redirect_url);
                        window.location.href = data.redirect_url;
                    }
                    // console.log(data);
                    // check if unexpected error
                    if (true === data.unexpected_error) {
                         let consentCheckbox = document.getElementById(
                           "terms-conditions"
                         );
                         consentCheckbox.classList.add(
                           "cb-prihvatite-uslove-up"
                         );
                         document.getElementById(
                           "pzo-terms-conditions"
                         ).style.color = "#005ca9";

                         document.getElementById(
                           "pzo-terms-conditions"
                         ).style.fontWeight = "bold";

                         setTimeout(function () {
                           consentCheckbox.classList.remove(
                             "cb-prihvatite-uslove-up"
                           );
                           consentCheckbox.classList.add(
                             "cb-prihvatite-uslove-down"
                           );
                           setTimeout(function () {
                             consentCheckbox.classList.remove(
                               "cb-prihvatite-uslove-down"
                             );
                             document.getElementById(
                               "pzo-terms-conditions"
                             ).style.color = "#666";

                             document.getElementById(
                               "pzo-terms-conditions"
                             ).style.fontWeight = "bold";
                           }, 200);
                         }, 500);
                        $('#pzo-form-errors').html(data.errors);
                        $('#pzo-review-errors').html(data.errors);
                        return false;
                    }
                    // loop errors
                    data.errors = $.parseJSON(data.errors);
                    if ('home_quadrature' in data.errors) {
                        $('#home_quadrature_sprite').removeClass('ok').addClass('error');
                        $('#home_quadrature_box .errors').html(data.errors['home_quadrature']);
                    }
                    else {
                        $('#home_quadrature_box .errors').removeClass('error').addClass('ok');
                    }
                    
                    $.each(data.errors, function (key, value) {
                        // prepare message
                        var key_replace = key.replace('_', ' ');
                        value[0] = value[0].replace(key_replace, '');
                        // set error status to returned error fields
                        self.statusError('insured_' + key + '_' + data.iteration_number, value[0]);
                    });
                    // smooth scroll to error part
                   /*  setTimeout(function () {
                        smoothScroll.animateScroll(null, '#pzo-form-insured-' + data.iteration_number, {"offset": 0});
                    }, 400); */
                }
            }
        });
    }
    

    $("#free_home_policy").change(function() {
        if(!$("#home_quadrature_box").is(":visible")) {
            //$(this).val('yes');
            $("#home_quadrature_box").slideDown();
        } else {
           // $(this).val('no');
            $("#home_quadrature_box").slideUp();
        }
    }
    );

    // next form data
    // next button clicked
    this.nextForm = function () {
        // on click
        $('.pzo-form-next').on('click', function () {
            // get current view
            var view = $('#pzo-active-view').val();
            // if checklist
            if ('view-checklist' === view) {
                // check geo ip restriction
                // set ajax url and make ajax call
                var ajaxURL = URL + "/ajax/allowed-ip";
                var $_token = $("input[name=_token]").val();
                $.ajax({
                    type: "POST",
                    dataType: 'json',
                    url: ajaxURL,
                    encode: true,
                    headers: {'X-XSRF-TOKEN': $_token},
                    success: function (data) {
                        // deactivate overlay screen
                        if (data.success == false) {
                            // redirect to error page
                            window.location.href = data.redirect_url;
                            return false;
                        }
                    }
                });
                if('view-review' === view){
                    self.submitForm();
                }
                // hide form view
                $('#pzo-form-checklist-container').fadeOut(600);
                setTimeout(function () {
                    $('#pzo-form-persons-container').show().addClass('animated slideInLeft');
                }, 800);
                // set active view to review
                $('#pzo-active-view').val('view-form');
                $('.form-payment').removeClass('hide');
                $('.payment-message').removeClass('hide');
                $('.accepted-payment-img').show();
            }
        });
    }

    // prev form data
    // back button on review page to user input data page
    this.prevForm = function () {
        // on click
        $('.pzo-form-prev').on('click', function () {
            // get current view
            var view = $('#pzo-active-view').val();
            // remove error message
            $('#pzo-form-errors').html('');
            $('#pzo-review-errors').html('');
            // if review page
            if ('view-review' === view) {
                // hide review view
                $('#pzo-form-review-container').fadeOut(600);
                setTimeout(function () {
                    $('#pzo-form-persons-container').show().addClass('animated slideInLeft');
                }, 800);
                // set active view to review
                $('#pzo-active-view').val('view-form');
                // stop further execution
                return;
            }
            // if form page
            if ('view-form' === view) {
                // hide review view
                $('#pzo-form-persons-container').fadeOut(600);
                setTimeout(function () {
                    $('#pzo-form-checklist-container').show().addClass('animated slideInLeft');
                }, 800);
                // set active view to review
                $('#pzo-active-view').val('view-checklist');
                // stop further execution
                return;
            }
            // if checklist page
            // load animated page
            if ('view-checklist' === view) {
                // show form page
                $('#pzo-form-container').removeClass('animated slideInLeft').fadeOut();
                // prepare animated
                $('#page').addClass('pzo-animated relative');
                // calculate canvas size
                self.calculateCanvasHeight();
                $('#page > .inner-section .content').css('paddingTop', '20px');
                // hide review view
                $('#pzo-form-container').fadeOut(600);
                setTimeout(function () {
                    // show animated container
                    $('#pzo-animated-container').show().addClass('animated slideInLeft');
                    // show step 4 form
                    $('#form-box-4').show().addClass('animated flipInX');
                    // show step progress
                    $('.step-progress').show().addClass('animated flipInX');
                    $('#pzo-animated-forms-container').show();
                }, 1200);
                // set active view to review
                $('#pzo-active-view').val('view-animated');
                // stop further execution
                return;
            }
        });
    }

    // find insured person type
    this.findInsuredPersonType = function (ref) {
        // find insured person type input
        var $insured_person_type = $(ref).parent().parent().parent().parent().parent().find('.insured-person-type');
        // return
        return $insured_person_type;
    }

    // residence override toggle
    this.residenceOverrideToggle = function () {
        // catch checkbox changed
        $('.insured-override-residence').on('click', function () {
            // get name and value
            var $name = $(this).data('common-name');
            var $value = $('input:checkbox[name=' + $name + ']:checked').val();
            // if active
            if ('on' == $value) {
                $('#' + $name + '_container').removeClass('hide');
            } else {
                $('#' + $name + '_container').addClass('hide');
            }
        });
    }

    // hide insured if contractor == insured person
    this.hideContractorInsured = function () {
        // catch contractor type change
        $('.select-contractor-type').on('click', function () {
            // get name and value
            var $name = $(this).data('common-name');
            var $value = $('input:radio[name=' + $name + ']:checked').val();
            // show/hide
            if ('contractor' == $value) {
                // show
                $('#pzo-form-insured-2').slideDown();
                // change insured person type
                $(self.findInsuredPersonType(this)).val('contractor');
            } else {
                // hide
                $('#pzo-form-insured-2').slideUp();
                // change insured person type
                $(self.findInsuredPersonType(this)).val('contractor-insured');
            }
        });
    }

    // hide jmbg if foreign user
    this.hideJMBG = function () {
        // catch citizenship change
        $('.select-citizenship').on('click', function () {
            // get name and value
            var $name = $(this).data('common-name');
            var $value = $('input:radio[name=' + $name + ']:checked').val();
            // find parent jmbg
            var $jmbg_container = $(this).parent().parent().parent().parent().parent().find('.jmbg-container');
            // show/hide
            if ('domestic' == $value) {
                // show
                $($jmbg_container).slideDown();
                // hide birthdate and gender
                $(this).parent().parent().parent().parent().parent().parent().find('.foreign-show').slideUp();
            } else {
                // hide
                $($jmbg_container).slideUp();
				$($jmbg_container).find('input').val('');
                // show birthdate and gender
                $(this).parent().parent().parent().parent().parent().parent().find('.foreign-show').slideDown();
            }
        });
    }

    // lock birthdate if domestic user and jmbg set
    this.lockBirthdate = function (ref) {
        // get handles
        var $citizenship = $(ref).parent().parent().parent().parent().parent().find('.field-citizenship input[type=radio]:checked').val();
        var $gender = $(ref).parent().parent().parent().parent().parent().find('.field-gender *');
        var $jmbg = $(ref).parent().parent().parent().parent().parent().find('.field-jmbg');
        var $birthdate = $(ref).parent().parent().parent().parent().parent().find('.field-birthdate');
        // change form
        if ('foreign' === $citizenship) {
            $birthdate.removeClass('disabled');
            $birthdate.prop('readonly', false);
            $gender.removeClass('disabled');
        } else {
            if ('domestic' === $citizenship && $jmbg.val().length === 13) {
                $birthdate.addClass('disabled');
                $birthdate.prop('readonly', true);
                $gender.addClass('disabled');
            } else {
                $birthdate.removeClass('disabled');
                $birthdate.prop('readonly', false);
                $gender.removeClass('disabled');
            }
        }
    }

    // validate single field
    this.validateField = function (ref, type) {
        // inits
        var name = null;
        var value = null;
        var insured_person_type = $(self.findInsuredPersonType(ref)).val();
        // determine type and get name and value
        if ('text' == type || 'dropdown' == type) {
            // get values
            name = $(ref).attr('name');
            value = $(ref).val();
        }
        if ('radio' == type) {
            // get values
            name = $(ref).data('common-name');
            value = $('input[name=' + name + ']:checked').val();
        }
        // call ajax method
        // set ajax url and make ajax call
		if (window.location.href.indexOf("putno-osiguranje-agencije") > -1) {
            var ajaxURL = URL + "/ajax/pzo-tourist/validate-field";
        } else {
            var ajaxURL = URL + "/ajax/pzo/validate-field";
        }
      
        var $_token = $("input[name=_token]").val();
        $.ajax({
            type: "POST",
            data: {
                'insured_person_type': insured_person_type,
                'name': name,
                'value': value,
                '_token': $_token
            },
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            headers: {'X-XSRF-TOKEN': $_token},
            success: function (data) {
                // if success
                if (data.success === true) {
                    // console.log(data)
                    // correction for street_number
					if(data.field_name == 'insured_street_number_1'){
						$("[name=insured_street_number_1]").css('border','1px solid #e1e1e1');
					}
                    data.field_name = data.field_name.replace('street_number', 'street');
					data.field_name = data.field_name.replace('insured_street_number_1', 'street');
                    
					// show success
                    self.statusSuccess(data.field_name);
                    // check helper
                    if (null != data.helper) {
                        // console.log(data.helper)
                        $.each(data.helper, function (k, v) {
                            // check helper type
                            if ('date' === v.type) {
                                $('input[name=' + v.key + ']').datepicker("setDate", v.value).trigger('blur');
                            } else if ('radio' === v.type) {
                                // set radio value
                                $('input[name=' + v.key + '][value=' + v.value + ']').prop('checked', true);
                                // href radio check
                                // first remove checked from all radio options
                                var $radio_href = $('input[name=' + v.key + '][value=' + v.value + ']').parent().parent().find('a');
                                $radio_href.removeClass('checked');
                                // find checked option and do check
                                var $radio_href = $('input[name=' + v.key + '][value=' + v.value + ']').parent().find('a');
                                $radio_href.addClass('checked');
                            } else {
                                $('input[name=' + v.key + ']').val(v.value).trigger('blur');
                            }
                        });
                    }
                } else {
                    // console.log(data)
                    // console.log(data.error)
                    // set error sign and message
                    // correction for street_number
                    data.field_name = data.field_name.replace('street_number', 'street');
                    // show error
                    self.statusError(data.field_name, data.error);
                }
            }
        });
    }

    // status success
    this.statusSuccess = function (id) {
        // set status to success for passed id
        $('#status-' + id).removeClass('error');
        $('#status-' + id).addClass('ok');
        // remove error message
        $('#errors-' + id).html('');
    }
    // status error
    this.statusError = function (id, message) {
        // set status to error for passed id
        $('#status-' + id).removeClass('ok');
        $('#status-' + id).addClass('error');
        // set error message
        $('#errors-' + id).html(message);
    }
    // status neutral
    this.statusNeutral = function (id) {
        // set status to neutral for passed id
        $('#status-' + id).removeClass('ok');
        $('#status-' + id).removeClass('error');
        // remove error message
        $('#errors-' + id).html('');
    }

    // unlock next step button
    this.unlockNext = function () {
        // console.log('unlock')
        $('#pzo-form-next').removeClass('disabled');
        $('.form-payment').removeClass('disabled');
        $('.accepted-payment-img').show();
        $('.payment-message').removeClass('hide');
        $('.form-next-loader').addClass('hide');
    }
    // lock next step button
    this.lockNext = function () {
        $('#pzo-form-next').addClass('disabled');
        $('.form-payment').addClass('disabled');
        $('.payment-message').addClass('hide');
        $('.form-next-loader').removeClass('hide');
    }

    // calculate animated div height
    this.calculateCanvasHeight = function () {
        // header height offset
        var headerHeight = 102;
        // get current document height and subtract header height
        var newHeight = $(window).height() - headerHeight;
        // phone corrections
        if ($(document).width() < 900) {
            newHeight += 90;
        }
        // subtract 10% from document size for bottom offset
        newHeight = newHeight - ((12 * newHeight) / 100);
        // set new height
        $('.pzo-animated').css('height', newHeight + 'px');
        $('#pzo-animated-container').css('height', newHeight + 'px');
    }

   /* $("#finish_order").on("click",function(){
         console.log(self.submitForm(''));
        console.log('finish-order');
        $('#pzo-form-container').show().addClass('animated slideInLeft');
        $("#pzo-animated-forms-container").slideUp();
        $('#pzo-active-view').val('view-review'); 
       
       // $('#pzo-form-review-container').show().addClass('animated slideInLeft');
         // set active view to review
       
    });*/
}
