function GIVEAWAY() {
    // declarations
    var self = this;
    var step = 1;

    // listeners
    this.listeners = function () {

        $(window).load(function () {
            self.negativeMargin();
        });
        $(window).resize(function () {
            self.negativeMargin();
        });
        $(document).on('blur', '*[data-validate-field="true"]', function () {
                self.validateField(this, 'text');
        });

        $('.giveaway-button-next').on('click',function(e){
            e.preventDefault();
            self.process();
        })
    }





    this.process = function(){
        var formData = new FormData($('#giveaway_form')[0]);
        // set ajax url and make ajax call
        var ajaxURL = URL + "/ajax/giveaway/process";
        var $_token = $("input[name=_token]").val();
        $('#giveaway-unexpected-error').html('');
        self.lockNext();
        // self.lockNext();
        $.ajax({
            type: "POST",
            data: formData,
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            contentType: false,
            processData: false,
            headers: {
                'X-XSRF-TOKEN': $_token
            },
            success: function (data) {
                self.unlockNext();
                if (true === data.unexpected_error) {
                    $('#giveaway-unexpected-error').html(data.errors.unexpected_error);
                    return false;
                }
                if (data.success == false) {
                    $('*[data-validate=true],*[data-validate-field=true]').each(function (id, selector) {
                        $.each(data.errors, function (index, error) {
                            if(index === 'image'){
                                $('#image-upload-error').html(error);
                            }
                            else {
                                if ($(selector).attr('name') != index) {
                                    $(selector).removeClass('has-error').addClass('has-success');
                                    $(selector).parent().siblings('.errors').html('');
                                }
                                else {
                                    $(selector).parent().siblings('.status').children('i').removeClass('ok').addClass('error');
                                    $(selector).removeClass('has-success').addClass('has-error');
                                    $(selector).parent().siblings('.errors').html(error);
                                    return false;
                                }
                            }
                        });
                    });

                }
                else {
                    $('*[data-validate=true]').each(function (id, selector) {
                        $(this).removeClass('has-error').addClass('has-success');
                        $(this).parent().siblings('.errors').html('');
                    })
                    if (self.step === 2) {
                        $('#giveaway_form').slideUp();
                        $('#giveaway_thanks').slideDown();
                    }
                    else{
                        self.step = 2;
                        $('#form-step').val('2');
                        $('#giveaway-next-button').slideUp();
                        $('.notice-wrapper').slideUp('slow',function(){
                            $('.image-upload-wrapper').slideDown('slow');
                        })


                    }
                }
            }
        });
    }


    // validate single field
    this.validateField = function (ref, type) {
        // inits
        var name = null;
        var value = null;
        // determine type and get name and value
        if ('text' == type || 'dropdown' == type) {
            // get values
            name = $(ref).attr('name');
            value = $(ref).val();
        }
        // call ajax method
        // set ajax url and make ajax call
        var ajaxURL = URL + "/ajax/giveaway/validate-field";
        var $_token = $("input[name=_token]").val();
        var data = {
            'name': name,
            'value': value,
            '_token': $_token
        }

        $.ajax({
            type: "POST",
            data: data,
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            headers: {'X-XSRF-TOKEN': $_token},
            success: function (data) {
                if (true === data.unexpected_error) {
                    $('#giveaway-unexpected-error').html(data.errors.unexpected_error);
                    return false;
                }
                // if success
                if (data.success === true) {
                    // console.log(data)
                    // show success
                    self.statusSuccess(data.field_name);
                    // check helper
                    if (null != data.helper) {
                        // check helper type
                        if ('date' === data.helper.type) {
                            $('input[name=' + data.helper.key + ']').datepicker("setDate", data.helper.value).trigger('blur');

                        } else {
                            $('input[name=' + data.helper.key + ']').val(data.helper.value).trigger('blur');
                        }
                    }
                } else {
                    self.statusError(data.field_name, data.error);
                }
            }
        });
    }


    // status success
    this.statusSuccess = function (id) {
        // set status to success for passed id
        $('*[name=' + id + ']').removeClass('has-error').addClass('has-success');
        $('*[name=' + id + ']').parent().siblings('.errors').html('');
    }
    // status error
    this.statusError = function (id, message) {
        $('*[name=' + id + ']').removeClass('has-success').addClass('has-error');
        $('*[name=' + id + ']').parent().siblings('.errors').html(message);
    }

    // unlock next step button
    this.unlockNext = function () {
        $('.giveaway-button-next').removeClass('disabled').prop('disabled',false);
    }
    // lock next step button
    this.lockNext = function () {
        $('.giveaway-button-next').addClass('disabled').prop('disabled',true);
    }

    // set animation wrapper min height and position
    this.negativeMargin = function () {
            if ($(document).width() < 490) {
                $('.negative-margin').css('margin-top','-20px', 'important');
             }
            else if ($(document).width() <= 1024) {
                $('.negative-margin').css('margin-top','-40px', 'important');
            }
            else if ($(document).width() > 1024) {
                $('.negative-margin').css('margin-top','-70px', 'important');
            }

    }

    // like process
    this.processLike = function(data){
        // set ajax url and make ajax call
        var ajaxURL = URL + "/ajax/giveaway/processLike";
        var $_token = $("input[name=_token]").val();
        $.ajax({
            type: "POST",
            data: data,
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            headers: { 'X-XSRF-TOKEN' : $_token },
            success: function (data) {
                if(data.success == true){
                    $('.like-button').addClass('liked');
                    $('.like-count').html(parseInt($('.like-count').html())+1);
                }
                else if(data.exists == true){
                    $('.like-button').addClass('liked');
                }
            }
        });
    }





}