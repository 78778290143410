function LEAD() {
    // declarations
    var self = this;
    this.lead_page = 1;
    this.lead_current_tab = 'lead';

    // listeners
    this.listeners = function()
    {
        // change lead box tab
        $('.lead-box-href').on('click', function(e) {
            e.preventDefault();
            var $box = $('#'+$(this).data('box'));
            if (self.lead_current_tab == $(this).data('box')) {
                return true;
            }
            $('.lead-box-href').removeClass('selected');
            $(this).addClass('selected');
            self.lead_current_tab = $(this).data('box');
            $('.lead-container .box').hide();
            $box.show();
        });

        // next page or final submit button clicked
        $('#lead-next-page, #lead-submit').on('click', function(e) {
            // prevent default
            e.preventDefault();
            // check if last step
            if ('lead-submit' === $(this).attr('id')) {
                // check recaptcha
                $(this).prop('disabled', true);
                setTimeout(function(){
                     // check recaptcha
                    if (!TCApp.InvisibleRecaptcha.verify(self.submitLead)) {
                        // enable submits
                        $(this).prop('disabled', false);
                        // trigger validation
                        window.grecaptcha.execute(TCApp.InvisibleRecaptcha.recaptchaID);
                        return;
                    } else {
                        // lock submits
                        $(this).prop('disabled', true);
                        // ajax call
                        self.submitLead();
                    }
                },200);

            } else {
                // lock submits
                $(this).prop('disabled', true);
                // ajax call
                self.submitLead();
            }
        });
    }

    // submit lead ajax call
    this.submitLead = function()
    {
        // declarations
        var success = true;
        var errors = [];
        var formData = {
            'page': self.lead_page,
            'first_name': $('#lead-first-name').val(),
            'last_name': $('#lead-last-name').val(),
            'city': $('#lead-city').val(),
            'email': $('#lead-email').val(),
            'phone': $('#lead-phone').val(),
            'lead_url': $("input[name=lead_url]").val(),
            'g_recaptcha_token': TCApp.InvisibleRecaptcha.token,
            '_token': $("input[name=_token]").val(),
            'lead_comment': $('input[name="lead_comment"]').val(),
            'brand': $('#brand').val(),
            'car_year': $('#carYear').val(),
            'engine_volume': $('#engineVolume').val(),
            'engine_power': $('#enginePower').val(),
            'squares': $("#squares").val(),
            'yearBirth':$("#yearBirth").val(),
            'premium':$("#lead-premium").val(),
            'duration':$("#duration").val(),
        }
        // set ajax url and make ajax call
        var ajaxURL = URL + "/ajax/submit-lead";
        var $_token = $("input[name=_token]").val();
        $.ajax({
            type: "POST",
            data: formData,
            dataType: 'json',
            url: ajaxURL,
            encode: true,
            headers: { 'X-XSRF-TOKEN' : $_token },
            success: function (data) {
                // unlock submits
                $('#lead-next-page, #lead-submit').prop('disabled', false);
                // remove error class
                $('#lead input').removeClass('error');
                // deactivate overlay screen
                if (data.success == true) {
                    // check if page one or two
                    if (self.lead_page === 2) {
                        $('#lead-content').html("<p>"+data.message+"</p>");
                        $('.lead-title').hide();
                    } else {
                        // increment page number
                        self.lead_page = 2;
                        // hide page 1 and display page 2
                        $('#lead-slide-1').fadeOut('slow', function() {
                            $('#lead-slide-2').removeClass('hidden');
                            $('#lead-slide-2').fadeIn();
                        });
                    }
                } else {
                    data.errors = $.parseJSON(data.errors);
                    $.each(data.errors, function(key, value) {
                        if(key != 'year_birth'){
                            $('#lead-'+key.replace('_', '-')).addClass('error');
                        }else{
                            $('#lead-yearBirth').addClass('error');
                        }
                        if(key == 'premium' && value == 'Polje premium mora biti najmanje 50.'){
                            var message = document.createElement('p');
                            if($('#lead-slide-2').find('p').length == 0){
                                $('#lead-slide-2').append(message);
                                message.append('Minimalni iznos premije je 50 KM.');
                                $('#lead-slide-2').find('p').css('color','red');
                            }
                        }
                    });
                }
            }
        });
    }
}
